import moment from "moment";
import { Link } from "react-router-dom";
import Table, { Row } from "../../../components/Table";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";

function KontenPetisiTable({ petitionContentData , modalStatusChange}) {
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  return (
    <Table
      currentPage={searchParams.page ?? 1}
      totalPages={petitionContentData?.totalPage}
      headingRow={[
        {
          unsortable: true,
          name: "Pembuat Petisi",
        },
        {
          unsortable: true,
          name: "Judul Petisi",
        },
        {
          unsortable: true,
          name: "Kategori",
        },
        {
          unsortable: true,
          name: "Waktu",
        },
        {
          unsortable: true,
          name: "Aksi",
        },
      ]}
    >
      {petitionContentData?.list?.map((petitionContent, i) => (
        <Row
          key={i}
          rowCells={[
            <UsernameWithAvatar
              username={petitionContent?.user.name}
              avatarPath={petitionContent?.user.avatar}
            />,
            petitionContent?.title ?? "-",
            petitionContent?.contentCategory.name ?? "-",
            moment(petitionContent?.createdAt).format("DD-MM-YYYY, HH:mm"),
            <div className="divide-x-2 divide-gray-400 font-semibold text-custom-primary">
              <div className="px-2 first:pl-0 cursor-pointer hover:underline" onClick={() => modalStatusChange(petitionContent?._id)}>
                      Lihat Detail
                  </div>
            </div>,
          ]}
        />
      ))}
    </Table>
  );
}
export default KontenPetisiTable;
