import useReactiveSearchParams from "../hooks/useReactiveSearchParams";
import Icons from "./Icons";

/**
 * Row of a table
 * @param {Object} props
 * @param {any[]} props.rowCells - Array containing a cells of a row
 * @param {boolean} props.isHighlighted - Boolean whether the a row is highlighted or not
 */
function Row({ rowCells, isHighlighted }) {
  return (
    <tr className={`${isHighlighted ? "bg-custom-highlight border-b" : ""}`}>
      {rowCells.map((cell, i) => (
        <td key={i} className="py-4 px-6 text-sm text-gray-500">
          {cell}
        </td>
      ))}
    </tr>
  );
}

/**
 * Pagination button of a table
 * @param {Object} props
 * @param {number} props.totalPages
 * @param {number} props.currentPage
 */
function TablePagination({ totalPages, currentPage }) {
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  return (
    <div className="mt-4 flex flex-col items-center">
      {/* Help text */}
      <span className="text-sm text-gray-700">
        Page <span className="font-semibold text-gray-900">{currentPage}</span>{" "}
        of <span className="font-semibold text-gray-900">{totalPages}</span>
      </span>
      <div className="inline-flex mt-2 xs:mt-0">
        {/* Buttons */}
        <button
          onClick={() =>
            setSearchParams({ ...searchParams, page: Number(currentPage) - 1 })
          }
          className="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 disabled:bg-gray-200 disabled:text-gray-400 disabled:hover:bg-gray-200"
          disabled={currentPage == 1}
        >
          <svg
            className="mr-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
          Prev
        </button>
        <button
          onClick={() =>
            setSearchParams({ ...searchParams, page: Number(currentPage) + 1 })
          }
          className="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900  disabled:bg-gray-200 disabled:text-gray-400 disabled:hover:bg-gray-200"
          disabled={currentPage == totalPages}
        >
          Next
          <svg
            className="ml-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

/**
 * @typedef {Object} HeadingCell
 * @property {string} name
 * @property {Function} handleSort
 * @property {string} currentSortMethod
 * @property {boolean} unsortable
 */

/**
 * Table component
 * @param {Object} props
 * @param {HeadingCell[]} props.headingRow
 * @param {any} props.children
 * @param {number} props.totalPages
 * @param {number} props.currentPage
 */
function Table({ headingRow, children, totalPages, currentPage }) {
  return (
    <>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border sm:rounded-lg">
              <table className="min-w-full table-auto">
                <thead className="bg-gray-50">
                  <tr>
                    {headingRow.map((headingItem, i) => (
                      <th
                        key={i}
                        scope="col"
                        className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                      >
                        {headingItem.unsortable ? (
                          <span className="inline-flex items-center gap-2">
                            {headingItem.name}
                          </span>
                        ) : (
                          <span className="inline-flex items-center gap-2">
                            <button onClick={headingItem.handleSort}>
                              {headingItem.currentSortMethod === "unsorted" && (
                                <Icons.Sort
                                  className={
                                    "w-4 h-4 stroke-custom-primary-text"
                                  }
                                />
                              )}
                              {headingItem.currentSortMethod === "asc" && (
                                <Icons.ChevronUp
                                  className={"w-4 h-4 stroke-custom-primary"}
                                />
                              )}
                              {headingItem.currentSortMethod === "desc" && (
                                <Icons.ChevronDown
                                  className={"w-4 h-4 stroke-custom-primary"}
                                />
                              )}
                            </button>
                            {headingItem.name}
                          </span>
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>{children}</tbody>
              </table>
            </div>
          </div>
        </div>
        <TablePagination currentPage={currentPage} totalPages={totalPages} />
      </div>
    </>
  );
}

export { Row };
export default Table;
