import axios from "axios";
import { API_ROUTES } from "../constansts";

async function getComment(params) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_COMMENT,
      method: "get",
      auth: {
        username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
        password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
      },
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache",

      },
      params
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function deleteComment({ content_comment_id, statusReason }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.DELETE_COMMENT,
      method: "delete",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      params: {
        content_comment_id,
      },
      data: {
        statusReason: statusReason,
      },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}

export { getComment, deleteComment };
