import { useEffect, useState } from "react";
import useError from "../../../hooks/useError";
import ModalBackdrop from "../../../components/ModalBackdrop";
import Icons from "../../../components/Icons";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import LoadingSpinner from "../../../components/LoadingSpinner";
import Button from "../../../components/Button";
import { getNews } from "../../../services/newsContent";
import MultiLineString from "../../../components/MultiLineString";

function KontenBeritaDetailModal({
  modalStatusChange,
  contentNewsId,
  modalHapus,
  modalEdit,
}) {
  const [isLoading, setIsLoading] = useState();
  const { isError, setError } = useError();
  const [contentNewsData, setContentNewsData] = useState();
  const [isOpened] = useState();

  const toggle = () => {
    modalStatusChange(!isOpened);
  };

  const getContentNewsData = async () => {
    try {
      setIsLoading(true);
      setContentNewsData(await getNews({ content_news_id: contentNewsId }));
    } catch (error) {
      setError({
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getContentNewsData();
  }, []);
  return (
    <ModalBackdrop>
      <article className="w-[650px] p-10 rounded-md bg-white flex flex-col gap-2">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Detail Konten Berita
          </h2>
          {isLoading ? (
            <></>
          ) : (
            <a
              href={`https://dev.intramedia.site/berita/${
                contentNewsData?.list[0].slug ?? ""
              }`}
              target="_blank"
              className="p-2 rounded-md hover:bg-gray-100 inline-flex"
            >
              Buka di Intra Q Media
              <Icons.NewTab className="w-6 h-6 stroke-custom-primary" />
            </a>
          )}
          <Button.Text onClick={toggle} className="ml-auto p-2">
            <span className="sr-only">Tutup</span>
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        <div>
          <div className="flex justify-center">
            <div className="border px-44 rounded-xl bg-slate-200">
              <p className="text-sm w-full">
                {contentNewsData?.list[0].contentCategory.name ?? "-"}
              </p>
            </div>
          </div>
        </div>
        {!isLoading && !isError && (
          <>
            {/* Row 1 */}
            <div className="grid grid-cols-4 gap-1 text-xs">
              <div className="flex flex-col gap-1 col-span-2 ml-5">
                <p className="text-custom-secondary-text">Judul Berita</p>
                <p className="text-custom-primary-text line-clamp-1">
                  {contentNewsData?.list[0].title ?? "-"}
                </p>
              </div>
              <div className="flex flex-col gap-1 col-span-1 ml-5">
                <p className="text-custom-secondary-text">Perusahaan Tertuju</p>
                <p className="text-custom-primary-text">
                  <UsernameWithAvatar
                    username={contentNewsData?.list[0].company?.name ?? "-"}
                  />
                </p>
              </div>
            </div>

            {/* Row 2 */}
            <div className="grid grid-cols-4 gap-2 text-xs">
              <div className="flex flex-col gap-1 col-span-2 ml-5">
                <p className="text-custom-secondary-text">Slug</p>
                <p className="text-custom-primary-text">
                  {contentNewsData?.list[0].slug ?? "-"}
                </p>
              </div>
              <div className="flex flex-col gap-1 col-span-1 ml-5 ">
                <p className="text-custom-secondary-text">Pembuat Berita</p>
                <p className="text-custom-primary-text">
                  <UsernameWithAvatar
                    avatarPath={contentNewsData?.list[0].user.avatar}
                    username={contentNewsData?.list[0].user.name ?? "-"}
                  />
                </p>
              </div>
            </div>
            <hr />
            <div className="flex flex-col gap-1 text-xs ml-5">
              <p className="text-custom-secondary-text">Konten Berita</p>
              <div className="text-custom-primary-text">
                <MultiLineString text={contentNewsData?.list[0].body ?? "-"} />
              </div>
            </div>
            <hr />
            {/* Row 3 */}
            <div className="grid grid-cols-4 gap-2 text-xs">
              <div className="flex flex-row gap-1 col-span-2 ml-5">
                <p className="text-custom-secondary-text">Kategori</p>
                <p className="text-custom-primary-text ml-5">
                  {contentNewsData?.list[0].contentCategory.name ?? "-"}
                </p>
              </div>
            </div>
            {/* Row 4 */}
            <div className="grid grid-cols-4 gap-2 text-xs">
              <div className="flex flex-row gap-1 col-span-2 ml-5">
                <p className="text-custom-secondary-text">Tags</p>
                {contentNewsData?.list?.map((tag, index) => (
                  <p
                    className="border rounded-md bg-gray-400	 md:px-5 px-2 py-1 md:py-2 border-grey 
            border-secondary-grey text-custom-primary-text ml-10"
                    key={index}
                  >
                    {tag.tag}
                  </p>
                ))}
              </div>
            </div>
          </>
        )}
        {isLoading && (
          <div className="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
        {!isLoading && !isError && (
          <footer className="mt-6 py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
            <Button.Primary
              onClick={() => {
                modalHapus(contentNewsData?.list[0]._id);
              }}
            >
              Hapus Berita
            </Button.Primary>
            <Button.Primary
              onClick={() => {
                modalEdit(contentNewsData?.list[0]._id);
              }}
            >
              Edit Berita
            </Button.Primary>
            <Button.Secondary onClick={toggle}>Kembali</Button.Secondary>
          </footer>
        )}
      </article>
    </ModalBackdrop>
  );
}
export default KontenBeritaDetailModal;
