import moment from "moment";
import 'moment/locale/id';
import Table, { Row } from "../../../components/Table";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";

function KontenBeritaTable({ newsContentData, modalStatusChange }) {
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  return (
    <Table
      currentPage={searchParams.page ?? 1}
      totalPages={newsContentData?.totalPage}
      headingRow={[
        {
          unsortable: true,
          name: "Judul",
        },
        {
          unsortable: true,
          name: "Kategori",
        },
        {
          unsortable: true,
          name: "Perusahaan",
        },
        {
          unsortable: true,
          name: "Publish",
        },
        {
          unsortable: true,
          name: "Tags",
        }, {
          unsortable: true,
          name: "Aksi",
        },
      ]}
    >
      {newsContentData?.list?.map((newsContent, i) => (

        <Row
          key={i}
          rowCells={[
            newsContent?.title ?? "-",
            newsContent?.contentCategory?.name ?? "-",
            <UsernameWithAvatar
            username={newsContent?.company?.name ?? "-"} />,
            moment(newsContent?.createdAt).format("dddd, DD MMM YYYY, HH:mm"),
            newsContent?.tag.join(", "),
            <div className="divide-x-2 divide-gray-400 font-semibold text-custom-primary">
              <div className="px-2 first:pl-0 cursor-pointer hover:underline" onClick={() => modalStatusChange(newsContent?._id)}>
                Lihat Detail
              </div>
            </div>,
          ]}
        />
      ))}
    </Table>
  );
}
export default KontenBeritaTable;
