import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import ErrorMessage from "../../components/ErrorMessage";
import GridLayout from "../../components/GridLayout";
import Icons from "../../components/Icons";
import LoadingSpinner from "../../components/LoadingSpinner";
import Main from "../../components/Main";
import PageHeader from "../../components/PageHeader";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import useError from "../../hooks/useError";
import useReactiveSearchParams from "../../hooks/useReactiveSearchParams";
import { getReportedContentByType } from "../../services/reportedContent";
import LaporanKeluhanDetailModal from "./components/LaporanKeluhanDetailModal";
import LaporanKeluhanHapusModal from "./components/LaporanKeluhanHapusModal";
import LaporanKeluhanTable from "./components/LaporanKeluhanTable";
import LaporanKeluhanToolbar from "./components/LaporanKeluhanToolbar";
import Helmet from "react-helmet";

function LaporanKeluhan() {
  const { error, isError, setError } = useError();
  const [isLoading, setIsLoading] = useState();
  const [complaintReportsData, setComplaintReportsData] = useState();
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  const [isDetailOpened, setIsDetailOpened] = useState();
  const [isHapusOpened, setIsHapusOpened] = useState();
  const [contentReportId, setcontentReportId] = useState();

  const toggle = (contentReportId) => {
    setIsDetailOpened(isDetailOpened => !isDetailOpened);
    setcontentReportId(contentReportId);
  }

  const togglehapus = (contentReportId) => {
    setIsDetailOpened(false);
    setIsHapusOpened(isHapusOpened => !isHapusOpened);
    setcontentReportId(contentReportId);
  }

  const getComplaintReportsData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setComplaintReportsData(
        await getReportedContentByType({ contentType: "complaint", ...searchParams })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getComplaintReportsData();
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Laporan Keluhan | Backoffice Intra Q Media</title>
      </Helmet>
      <GridLayout>
        <Sidebar />
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.Report className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Laporan Keluhan"
          />
          <LaporanKeluhanToolbar />
          {isLoading && (
            <div className="h-24 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
          {isError ? (
            error.code == 404 ? (
              <div className="p-24 flex items-center justify-center">
                <p className="flex gap-4 text-gray-300">
                  <span>
                    <Icons.Database className="stroke-gray-300" />
                  </span>
                  <span>Data Tidak Ditemukan</span>
                </p>
              </div>
            ) : (
              <ErrorMessage error={error} />
            )
          ) : (
            <></>
          )}
          {!isLoading && !isError ? (
            <>

              <LaporanKeluhanTable
                complaintReportsData={complaintReportsData} modalStatusChange={toggle}
              />
            </>
          ) : (
            <></>
          )}
          {!isLoading && isDetailOpened && (
            <LaporanKeluhanDetailModal
              refreshLaporanData={getComplaintReportsData} modalStatusChange={toggle} contentReportId={contentReportId} modalHapus={togglehapus} />
          )}
          {!isLoading && isHapusOpened && (
            <LaporanKeluhanHapusModal
              refreshLaporanData={getComplaintReportsData} modalStatusChange={toggle} contentReportId={contentReportId} modalHapus={togglehapus} />
          )}
        </Main>
      </GridLayout>
      {/* <Routes>
        <Route
          path=":contentReportId/detail"
          element={<LaporanKeluhanDetailModal
            refreshLaporanData={getComplaintReportsData} />}
        />
        <Route
          path=":contentReportId/hapus"
          element={<LaporanKeluhanHapusModal
            refreshLaporanData={getComplaintReportsData} />}
        />
      </Routes> */}
    </>
  );
}
export default LaporanKeluhan;
