import axios from "axios";
import { API_ROUTES, status } from "../constansts";

/**
 * get reported content based on type
 * @async
 * @param {object} params
 * @param {"complaint"|"comment"|"petition"|"reasons|"} params.contentType
 * @param {"unverified"|"verified"|"rejected"} params.status
 * @param {string} params.user_id
 * @param {string} params.reason
 * @param {number} [params.page=1]
 * @param {number} [params.size=10]
 *
 * @returns {Promise<>}
 */

async function getReportedContentByType({
  contentType,
  page = 1,
  size = 10,
  ...searchParams
}) {
  let result;

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_REPORTED_CONTENT,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache",
      },
      params: {
        contentType,
        page,
        size,
        ...searchParams,
      },
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

/**
 * get reported categories
 * @async
 * @param {object} params
 * @param {string} params.name
 *
 * @returns {Promise<>}
 */

async function getReportCategories(params) {
  let result;

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_REPORT_CATEGORIES,
      method: "get",
      auth: {
        username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
        password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
      },
      headers: {
        "Content-Type": "application/json",
      },
      params,
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}

/**
 * get reported content
 * @async
 * @param {object} params
 * @param {"unverified"|"verified"|"rejected"} params.status
 * @param {string} params.content_report_id
 * @param {string} params.content_complaint_id
 * @param {string} params.content_petition_id
 * @param {string} params.content_comment_id
 * @param {string} params.user_id
 * @param {string} params.reason
 * @param {number} [params.page=1]
 * @param {number} [params.size=10]
 *
 * @returns {Promise<>}
 */

async function getReportedContent({ page = 1, size = 10, ...restOfParams }) {
  let result;

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_REPORTED_CONTENT,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache",

      },
      params: {
        page,
        size,
        ...restOfParams,
      },
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function setReportVerified({ content_report_id }) {
  let result;

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.SET_REPORTED_STATUS,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      params : {
        content_report_id
      },
      data: {
        status: status.VERIFIED,
        statusReason: "Laporan telah diverifikasi",
      },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function setReportRejected({ content_report_id }) {
  let result;

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.SET_REPORTED_STATUS,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      params : {
        content_report_id
      },
      data: {
        status: status.REJECTED,
        statusReason: "Laporan telah ditolak",
      },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function DeleteReport({ content_report_id }) {
  let result;

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_REPORTED_CONTENT,
      method: "Delete",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      params : {
        content_report_id
      },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}
export { getReportedContentByType, getReportedContent, getReportCategories, setReportVerified, setReportRejected, DeleteReport };
