import { useState, useEffect } from "react";
import Helmet from "react-helmet";
import ErrorMessage from "../../components/ErrorMessage";
import GridLayout from "../../components/GridLayout";
import Icons from "../../components/Icons";
import LoadingSpinner from "../../components/LoadingSpinner";
import Main from "../../components/Main";
import PageHeader from "../../components/PageHeader";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import useError from "../../hooks/useError";
import KontenKomentarToolbar from "./components/KontenKomentarToolbar";
import { getComment } from "../../services/commentContent";
import useReactiveSearchParams from "../../hooks/useReactiveSearchParams";
import KontenKomentarTable from "./components/KontenKomentarTable";
import KontenKomentarDetailModal from "./components/KontenKomentarDetailModal";
import KontenKomentarHapusModal from "./components/KontenKomentarHapusModal";
import { status } from "../../constansts";

function KontenKomentar() {
  const [isLoading, setIsLoading] = useState();
  const { error, isError, setError } = useError();
  const [commentContentData, setCommentContentData] = useState();
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const [isDetailOpened, setIsDetailOpened] = useState();
  const [isHapusOpened, setIsHapusOpened] = useState();
  const [contentCommentId, setcontentCommentId] = useState();

  const toggle = (comment) => {
    setIsDetailOpened(isDetailOpened => !isDetailOpened);
    setcontentCommentId(comment);
  }

  const togglehapus = (comment) => {
    setIsDetailOpened(false);
    setIsHapusOpened(isHapusOpened => !isHapusOpened);
    setcontentCommentId(comment);
  }

  const getCommentContentData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setCommentContentData(
        await getComment({ page: 1, size: 10, status: status.PUBLISHED, ...searchParams })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCommentContentData();
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Konten Komentar | Backoffice Intra Q Media</title>
      </Helmet>
      <GridLayout>
        <Sidebar />
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.Send className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Konten Komentar"
          />
          <KontenKomentarToolbar />
          {isLoading ? (
            <div className="h-24 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          ) : (
            <></>
          )}
          {!isLoading && !isError ? (
            <KontenKomentarTable commentContentData={commentContentData} modalStatusChange={toggle} />
          ) : (
            <></>
          )}
          {isError ? (
            error.code == 404 ? (
              <div className="p-24 flex items-center justify-center">
                <p className="flex gap-4 text-gray-300">
                  <span>
                    <Icons.Database className="stroke-gray-300" />
                  </span>
                  <span>Data Tidak Ditemukan</span>
                </p>
              </div>
            ) : (
              <ErrorMessage error={error} />
            )
          ) : (
            <></>
          )}
          {!isLoading && isDetailOpened && (
            <KontenKomentarDetailModal
              refreshLaporanData={getCommentContentData} modalStatusChange={toggle} contentCommentId={contentCommentId} modalHapus={togglehapus} />
          )}
          {!isLoading && isHapusOpened && (
            <KontenKomentarHapusModal
              refreshLaporanData={getCommentContentData} modalStatusChange={toggle} contentCommentId={contentCommentId} modalHapus={togglehapus} />
          )}
        </Main>
      </GridLayout>
    </>
  );
}
export default KontenKomentar;
