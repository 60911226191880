import Button from "../../../components/Button";
import ButtonGroup from "../../../components/ButtonGroup";
import Icons from "../../../components/Icons";
import TextField from "../../../components/TextField";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";

function SuperadminManajemenAdminToolbar(props) {
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  return (
    <div className="py-2 flex items-center gap-4">
      <ButtonGroup className="mr-auto">
        <ButtonGroup.Item
          onClick={() => setSearchParams({ ...searchParams, size: 10 })}
          isActive={Number.parseInt(searchParams.size) === 10}
        >
          View 10
        </ButtonGroup.Item>
        <ButtonGroup.Item
          onClick={() => setSearchParams({ ...searchParams, page:1, size: 20 })}
          isActive={Number.parseInt(searchParams.size) === 20}
        >
          View 20
        </ButtonGroup.Item>
        <ButtonGroup.Item
          onClick={() => setSearchParams({ ...searchParams, page:1, size: 30 })}
          isActive={Number.parseInt(searchParams.size) === 30}
        >
          View 30
        </ButtonGroup.Item>
      </ButtonGroup>
      <Button.Primary icon={<Icons.Plus className="w-4 h-4 stroke-white" />}>
        Buat Admin Baru
      </Button.Primary>
      <TextField
        placeholder="Search"
        onChange={(event) =>
          setSearchParams({ ...searchParams, keyword: event.target.value })
        }
        icon={<Icons.Search className="w-4 h-4 stroke-custom-primary-text" />}
      />
    </div>
  );
}
export default SuperadminManajemenAdminToolbar;
