import React from "react";

class Button extends React.Component {
  static Primary = ({ icon, label, className, children, ...restOfProps }) => (
    <button
      className={`text-white bg-custom-primary disabled:bg-blue-300 disabled:hover:bg-blue-300 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center gap-2 ${className}`}
      {...restOfProps}
    >
      {icon ? <span className="w-4 h-4">{icon}</span> : <></>}
      {children}
      {label ? (
        <span className="inline-flex justify-center items-center w-4 h-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
          {label}
        </span>
      ) : (
        <></>
      )}
    </button>
  );
  static Secondary = ({ icon, label, className, children, ...restOfProps }) => (
    <button
      className={`py-2.5 px-5 text-sm font-medium text-gray-900 disabled:text-gray-400 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 disabled:hover:bg-white hover:text-custom-primary focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-custom-primary inline-flex items-center justify-center gap-2 ${className}`}
      {...restOfProps}
    >
      {icon ? <span className="w-4 h-4">{icon}</span> : <></>}
      {children}
      {label ? (
        <span className="inline-flex justify-center items-center ml-2 w-4 h-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
          {label}
        </span>
      ) : (
        <></>
      )}
    </button>
  );
  static Text = ({ icon, label, className, children, ...restOfProps }) => (
    <button
      className={`py-2.5 px-5 text-sm font-medium text-gray-900 disabled:text-gray-400 bg-white rounded-lg hover:bg-gray-100 disabled:hover:bg-white  hover:text-custom-primary focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-custom-primary inline-flex items-center justify-center gap-2 ${className}`}
      {...restOfProps}
    >
      {icon ? <span className="w-4 h-4">{icon}</span> : <></>}
      {children}
      {label ? (
        <span className="inline-flex justify-center items-center ml-2 w-4 h-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
          {label}
        </span>
      ) : (
        <></>
      )}
    </button>
  );

  render() {
    return <></>;
  }
}

export default Button;
