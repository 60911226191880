import { Link } from "react-router-dom";
import moment from "moment";
import Table, { Row } from "../../../components/Table";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";

function VerifikasiDataDiriPenggunaIndividuTable({ individualUserData }) {
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  return (
    <Table
      totalPages={individualUserData?.totalPage}
      currentPage={searchParams.page ?? 1}
      headingRow={[
        {
          name: "Nama",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "nama",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "nama" ? searchParams.order : "unsorted",
        },
        {
          name: "Email",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "nama",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "nama" ? searchParams.order : "unsorted",
        },
        {
          name: "Waktu",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "nama",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "nama" ? searchParams.order : "unsorted",
        },
        {
          name: "Aksi",
          unsortable: true,
        },
      ]}
    >
      
      {individualUserData?.list?.map((user, i) => (
        <Row
          key={i}
          rowCells={[
            <UsernameWithAvatar
              avatarPath={user.avatar}
              username={user.name}
            />,
            user.email,
            moment(user.createdAt).format("YYYY-MM-DD, HH:MM"),
            <>
              <div className="divide-x-2 divide-gray-400 font-semibold text-custom-primary">
                <Link to={`${user._id}/verifikasi`} className="pr-2 hover:underline">
                  Verifikasi
                </Link>
              </div>
            </>,
          ]}
        />
      ))}{" "}
    </Table>
  );
}
export default VerifikasiDataDiriPenggunaIndividuTable;
