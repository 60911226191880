import moment from "moment";
import { Link } from "react-router-dom";
import Table, { Row } from "../../../components/Table";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";

function KontenTanggapanTable({ feedbackContentData , modalStatusChange}) {
  const [searchParams] = useReactiveSearchParams();

  return (
    <Table
      currentPage={searchParams.page ?? 1}
      totalPages={feedbackContentData?.totalPage}
      headingRow={[
        {
          unsortable: true,
          name: "Pembuat Tanggapan",
        },
        {
          unsortable: true,
          name: "Perusahaan",
        },
        {
          unsortable: true,
          name: "Tanggapan",
        },
        {
          unsortable: true,
          name: "Waktu",
        },
        {
          unsortable: true,
          name: "Aksi",
        },
      ]}
    >
      {feedbackContentData?.list?.map((feedback, i) => (
        <Row
          key={i}
          rowCells={[
            <UsernameWithAvatar
            avatarPath={feedback.user?.avatar ?? "-"}
            username={feedback.user?.name ?? "-"}
            />,
            <UsernameWithAvatar username={feedback.user?.company ?? "-"}/>,
            feedback?.body ?? "-",
            moment(feedback?.createdAt).format("DD-MM-YYYY, HH:mm"),
            <div className="divide-x-2 divide-gray-400 font-semibold text-custom-primary">
               <div className="px-2 first:pl-0 cursor-pointer hover:underline" onClick={() => modalStatusChange(feedback?._id)}>
                      Lihat Detail
                  </div>
            </div>,
          ]}
        />
      ))}
    </Table>
  );
}
export default KontenTanggapanTable;
