import GridLayout from "../../components/GridLayout";
import Icons from "../../components/Icons";
import Main from "../../components/Main";
import PageHeader from "../../components/PageHeader";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import ModerasiKontenPetisiTable from "./components/ModerasiKontenPetisiTable";
import ModerasiKontenPetisiToolbar from "./components/ModerasiKontenPetisiToolbar";
import ModerasiKontenPetisiModerasiModal from "./components/ModerasiKontenPetisiModerasiModal";
import ModerasiKontenPetisiPublikasiModal from "./components/ModerasiKontenPetisiPublikasiModal";
import ModerasiKontenPetisiTolakModal from "./components/ModerasiKontenPetisiTolakModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import ErrorMessage from "../../components/ErrorMessage";
import useError from "../../hooks/useError";
import useReactiveSearchParams from "../../hooks/useReactiveSearchParams";
import { useEffect, useState } from "react";
import { getPetition } from "../../services/petitionContent";
import Helmet from "react-helmet";
import { status } from "../../constansts";

function ModerasiKontenPetisi() {
  const [PetisiData, setPetisiData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { error, setError, isError } = useError();
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const [isDetailOpened, setIsDetailOpened] = useState();
  const [isPublikasiOpened, setIsPublikasiOpened] = useState();
  const [isTolakOpened, setIsTolakOpened] = useState();
  const [PetitionId, setPetitionId] = useState();

  const toggle = (Petition) => {
    setIsDetailOpened(wasOpened => !wasOpened);
    setPetitionId(Petition);
  }
  
  const toggletolak = (Petition) => {
    setIsDetailOpened(!isDetailOpened);
    setIsTolakOpened(wasOpened => !wasOpened);
    setPetitionId(Petition);
  }
  const togglepublikasi = (Petition) => {
    setIsDetailOpened(!isDetailOpened );
    setIsPublikasiOpened(wasOpened => !wasOpened);
    setPetitionId(Petition);
  }

  const getPetisiData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setPetisiData(
        await getPetition({ size: 10, status: status.MODERATED, page: 1, ...searchParams })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPetisiData();
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Moderasi Konten Petisi | Backoffice Intra Q Media</title>
      </Helmet>
      <GridLayout>
        <Sidebar />
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.Edit className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Moderasi Konten Petisi"
          />
          <ModerasiKontenPetisiToolbar />
          {isLoading && (
            <div className="h-24 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
          {isError ? (
            error.code == 404 ? (
              <div className="p-24 flex items-center justify-center">
                <p className="flex gap-4 text-gray-300">
                  <span>
                    <Icons.Database className="stroke-gray-300" />
                  </span>
                  <span>Belum ada data</span>
                </p>
              </div>
            ) : (
              <ErrorMessage error={error} />
            )
          ) : (
            <></>
          )}
          {isError && error.code !== 404 && <ErrorMessage error={error} />}
          {!isLoading && !isError && (
            <ModerasiKontenPetisiTable
              PetisiData={PetisiData} modalStatusChange={toggle}
            />
          )}
        </Main>
      </GridLayout>
      {!isLoading && isDetailOpened && (
        <ModerasiKontenPetisiModerasiModal
          refreshPetisiData={getPetisiData} modalStatusChange={toggle} PetitionId={PetitionId} modalTolak={toggletolak} modalPublikasi={togglepublikasi}
        />
      )};
      {!isLoading &&  isPublikasiOpened && !isTolakOpened && !isDetailOpened && (
        <ModerasiKontenPetisiPublikasiModal
        refreshPetisiData={getPetisiData} modalStatusChange={toggle} PetitionId={PetitionId} modalPublikasi={togglepublikasi}
      />
      )};
      {!isLoading && isTolakOpened && !isPublikasiOpened && !isDetailOpened && (
        <ModerasiKontenPetisiTolakModal
        refreshPetisiData={getPetisiData} modalStatusChange={toggle} PetitionId={PetitionId} modalTolak={toggletolak}
      />
      )};


    </>
  );
}
export default ModerasiKontenPetisi;
