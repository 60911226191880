import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import Icons from "../../../components/Icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import TextField from "../../../components/TextField";
import useError from "../../../hooks/useError";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";
import {
  createCompany,
  createSubsCompany,
  getCompany,
  updateCompany,
  updateSubsCompany,
} from "../../../services/companies";
import { getFileUrl, uploadSubscriptionProof } from "../../../services/files";
import {
  subscriptions_duration,
  subscriptions_type,
  subscription_type,
} from "../../../constansts";

function PerusahaanSubscriptonBaruModal({
  modalStatusChange,
  modalsubscription,
  refreshPerusahaanData,
  perusahaanId,
}) {
  const [isLoading, setIsLoading] = useState();
  const { error, setError, isError } = useError();
  const [files, setFiles] = useState([]);
  const [fileurl, setFileUrl] = useState();
  const [price, setPrice] = useState('')
  const [companyData, setCompanyData] = useState();
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const toggle = () => {
    modalStatusChange();
    modalsubscription();
  };
  const currencyIdn = (event) => {
        const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");
        setPrice(addCommas(removeNonNumeric(event.target.value)));
    }
  const getCompanyData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setCompanyData(
        await getCompany({
          withSubs: true,
          withPics: true,
          company_id: perusahaanId,
        })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  // const imageUpload = async () => {
  //   let response;
  //   try {
  //     setError({ status: "ok" });
  //     setIsLoading(true);
  //     console.log(response);
  //   } catch (error) {
  //     setError({
  //       message: error.message,
  //       status: "error",
  //     });
  //   } finally {
  //     setFileUrl(fileUrl);
  //     setIsLoading(false);
  //   }
  // };
  useEffect(() => {
    getCompanyData();
  }, []);
  const formik = useFormik({
    initialValues: {
      company_id: "",
      transaction_date: "",
      account_number: "",
      sender_name: "",
      subscription_type: "",
      sender_bank: "",
      subscription_duration: "",
      subtotal: "",
      payment_proof: "",
    },

    onSubmit: (data) => {
      let url;
      try {
        if (files.length === 0) throw new Error("Bukti Pembayaran dibutuhkan");
        if (data.sender_name === null || data.sender_name === "")
          throw new Error("Masukan Nama Pengirim");
        if (data.account_number === "" || data.account_number === null)
          throw new Error("Masukan Nomor Rekening");
        if (data.sender_bank === "" || data.sender_bank === null)
          throw new Error("Masukan Bank Pengirim");
        if (
          data.subscription_duration === "" ||
          data.subscription_duration === null
        )
          throw new Error("Pilih Durasi Langganan");
        if (data.subscription_type === "" || data.subscription_type === null)
          throw new Error("Pilih Jenis Langganan");
        if (data.subtotal === "" || data.subtotal === null)
          throw new Error("Masukan Total Harga");
        if (data.transaction_date === "" || data.transaction_date === null)
          throw new Error("Masukan Tanggal Transaksi");

        setError({ status: "ok" });
        setIsLoading(true);
        const newSubsProof = new Promise((resolve) => {
          const response = uploadSubscriptionProof({
            company_id: companyData?.list[0]._id,
            file: files[0],
          });
          resolve(response);
        }).then((url) => {
          url = getFileUrl({ filePath: url.data.filePath });
          return url;
        });
        newSubsProof.then((url) => {
          try {
            const params = {
              company_id: companyData?.list[0]._id,
              transaction_date: data.transaction_date,
              account_number: data.account_number,
              sender_name: data.sender_name,
              subscription_type: data.subscription_type,
              sender_bank: data.sender_bank,
              subscription_duration: data.subscription_duration,
              payment_proof: url,
            };
            const newSubs = createSubsCompany(params);
          } catch (error) {
            setError({
              message: error.message,
              status: "error",
            });
          }
        }).then(() => {
          Swal.fire({
            icon: "success",
            title: "Berhasil Menambahkan Subscription",
            showConfirmButton: false,
            timer: 1500,
          });

          refreshPerusahaanData();
          toggle();
        })
      } catch (error) {
        if (error.message === "Bukti Pembayaran dibutuhkan") {
          setError({
            message: error.message,
            status: "error",
          });
        } else if (error.message === "Masukan Nama Pengirim") {
          setError({
            message: error.message,
            status: "error",
          });
        } else if (error.message === "Masukan Nomor Rekening") {
          setError({
            message: error.message,
            status: "error",
          });
        } 
        else if (error.message === "Masukan Bank Pengirim") {
          setError({
            message: error.message,
            status: "error",
          });
        } else if (error.message === "Pilih Durasi Langganan") {
          setError({
            message: error.message,
            status: "error",
          });
        } else if (error.message === "Pilih Jenis Langganan") {
          setError({
            message: error.message,
            status: "error",
          });
        } else if (error.message === "Masukan Total Harga") {
          setError({
            message: error.message,
            status: "error",
          });
        } 
        else if (error.message === "Masukan Tanggal Transaksi") {
          setError({
            message: error.message,
            status: "error",
          });
        } else {
          setError({
            code: error.response.status,
            message: error.response.data.message,
            status: "error",
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <ModalBackdrop>
      <article className="w-[865px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Langganan Baru
          </h2>
          <Button.Text onClick={() => toggle()} className="ml-auto p-2">
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        <div className="flex flex-col gap-6">
          <h3 className="text-lg text-custom-primary-text font-bold flex items-center">
            Informasi Perusahaan
            <span className="ml-3 px-2 py-1 rounded-md bg-custom-background-lighter text-xs font-normal">
              <span className="text-red-500">*</span> Wajib diisi
            </span>
          </h3>
          <div className="grid grid-cols-3 gap-8 text-xs">
            <div className="flex flex-col gap-1">
              <TextField
                type="text"
                name="company_id"
                id="company_id"
                labelText="ID Perusahaan"
                defaultValue={companyData?.list[0]._id}
                disabled
                required
              />
            </div>
            <div className="flex flex-col gap-1">
              <TextField
                type="date"
                name="transaction_date"
                id="transaction_date"
                labelText="Tanggal Transfer"
                placeholder="Masukkan tanggal..."
                onChange={formik.handleChange}
                defaultValue={formik.values.transaction_date}
                isError={formik.errors.transaction_date}
                required
              />
            </div>
            <div className="flex flex-col gap-1">
              <TextField
                labelText="Nama Pengirim"
                name="sender_name"
                placeholder="Masukkan nama pengirim..."
                onChange={formik.handleChange}
                defaultValue={formik.values.sender_name}
                isError={formik.errors.sender_name}
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-8 text-xs">
            <div className="flex flex-col gap-1">
              <span className="text-xs text-custom-secondary-text mb-3 -mt-1">
                {" "}
                Pilih Kategori
                <span className="ml-1 text-red-500 select-none ">*</span>
                <></>
              </span>
              <select
                className="
            form-select appearance-none
            block
            w-full
            px-3
            py-2
            text-base
            font-normal
            -mt-1.5
            text-gray-400
            bg-white bg-clip-padding bg-no-repeat
            border border-solid border-gray-200
            rounded-lg
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                aria-label="Default select example"
                id="subscription_type"
                name="subscription_type"
                onChange={formik.handleChange}
                isError={formik.errors.subscription_type}
                defaultValue={formik.values.subscription_type}
              >
                <option>Pilih Kategori --</option>
                {subscriptions_type.map((type) => (
                  <option value={type.value}>{type.label}</option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-1">
              <TextField
                type="number"
                name="account_number"
                labelText="Nomor Rekening Pengirim"
                placeholder="Masukkan nomor rekening..."
                onChange={formik.handleChange}
                isError={formik.errors.account_number}
                defaultValue={formik.values.account_number}
                required
              />
            </div>
            <div className="flex flex-col gap-1">
              <TextField
                type="text"
                name="sender_bank"
                labelText="Bank Pengirim"
                placeholder="Masukkan bank..."
                onChange={formik.handleChange}
                isError={formik.errors.sender_bank}
                defaultValue={formik.values.sender_bank}
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-8 text-xs">
            <div className="flex flex-col gap-1">
              <p className="text-custom-secondary-text -mt-1">
                Upload Bukti Bayar{" "}
                <span className="ml-1 text-red-500 select-none">*</span>
              </p>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <button className="w-full p-3 text-sm text-custom-primary font-bold hover:bg-gray-100 rounded-md inline-flex items-center gap-4">
                  {files[0] ? (
                    <img
                      src={files[0]?.preview}
                      alt=""
                      className="w-16 h-16 bg-gray-200 flex-shrink-0 rounded-full object-cover"
                    />
                  ) : (
                    <div className="w-16 h-16 bg-gray-200 flex-shrink-0 rounded-full stroke-custom-primary-text flex items-center justify-center">
                      <Icons.Company />
                    </div>
                  )}
                  Upload Foto
                </button>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-xs text-custom-secondary-text mb-3 -mt-1">
                {" "}
                Periode Berlangganan
                <span className="ml-1 text-red-500 select-none">*</span>
                <></>
              </span>
              <select
                className="
            form-select appearance-none
            block
            w-full
            px-3
            py-2
            text-base
            font-normal
            -mt-1.5
            text-gray-400
            bg-white bg-clip-padding bg-no-repeat
            border border-solid border-gray-200
            rounded-lg
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                aria-label="Default select example"
                id="subscription_duration"
                name="subscription_duration"
                onChange={formik.handleChange}
                isError={formik.errors.subscription_duration}
                defaultValue={formik.values.subscription_duration}
              >
                <option>Pilih Salah Satu --</option>
                {subscriptions_duration.map((duration) => (
                  <option value={duration.value}>{duration.label}</option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-1">
              <TextField
                type="text"
                name="subtotal"
                labelText="Subtotal"
                placeholder="Masukkan subtotal..."
                onChange={formik.handleChange}
                onInput={currencyIdn}
                value={"Rp."+price}
                isError={formik.errors.subtotal}
                required
              />
            </div>
          </div>
          <ul className="flex flex-col gap-1.5">
            {Object.entries(formik.errors)?.map((error, i) => (
              <li className="text-red-700 text-xs" key={i}>
                {error[1]}
              </li>
            ))}
            {isError && (
              <li className="text-red-700 text-xs">
                {error?.message ?? "Terjadi kesalahan"}
              </li>
            )}
          </ul>
          <p className="text-custom-secondary-text text-xs">
            Informasi di atas sewaktu-waktu dapat diubah oleh PIC perusahaan.
            Pengisian formulir ini hanya sebagai data awal saja.
          </p>
        </div>
        <footer className="mt-6 py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
          {!isLoading ? (
            <>
              <Button.Secondary onClick={() => toggle()}>
                Batal
              </Button.Secondary>
              <Button.Primary type="submit" onClick={formik.handleSubmit}>
                Tambah subscription
              </Button.Primary>
            </>
          ) : (
            <div className="w-full flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
        </footer>
      </article>
    </ModalBackdrop>
  );
}
export default PerusahaanSubscriptonBaruModal;
