import GridLayout from "../../components/GridLayout";
import Sidebar from "../../components/Sidebar";
import PageHeader from "../../components/PageHeader";
import PageTitle from "../../components/PageTitle";
import Main from "../../components/Main";
import Icons from "../../components/Icons";
import AdminTable from "./components/AdminTable";
import AdminToolbar from "./components/AdminToolbar";
import useError from "../../hooks/useError";
import { useEffect, useState } from "react";
import { getAdminList } from "../../services/admin";
import LoadingSpinner from "../../components/LoadingSpinner";
import ErrorMessage from "../../components/ErrorMessage";
import useReactiveSearchParams from "../../hooks/useReactiveSearchParams";
import AdminDetailModal from "./components/AdminDetailModal";
import AdminHapusPenggunaModal from "./components/AdminHapusPenggunaModal";
import Helmet from "react-helmet";

function Admin() {
  const [AdminData, setAdminData] = useState();
  const [isLoading, setIsLoading] = useState();
  const { error, setError, isError } = useError();
  const [searchParams] = useReactiveSearchParams();
  const [isDetailOpened, setIsDetailOpened] = useState();
  const [isHapusOpened, setIsHapusOpened] = useState();
  const [adminId, setadminId] = useState();

  const toggle = (admin) => {
    setIsDetailOpened(isDetailOpened => !isDetailOpened);
    setadminId(admin);
  }
  const togglehapus = (admin) => {
    setIsDetailOpened(false);
    setIsHapusOpened(isHapusOpened => !isHapusOpened);
    setadminId(admin);
  }

  useEffect(() => {
    getAdminData();
  }, [searchParams]);

  const getAdminData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setAdminData(
        await getAdminList({
          page: 1,
          size: 10,
          ...searchParams,
        })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Admin | Backoffice Intra Q Media</title>
      </Helmet>
      <GridLayout>
        <Sidebar />
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.Users className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Admin"
          />
          <AdminToolbar />
          {isLoading && (
            <div className="h-24 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
          {isError ? (
            error.code === 404 ? (
              <div className="p-24 flex items-center justify-center">
                <p className="flex gap-4 text-gray-300">
                  <span>
                    <Icons.Database className="stroke-gray-300" />
                  </span>
                  <span>Data Tidak Ditemukan</span>
                </p>
              </div>
            ) : (
              <ErrorMessage error={error} />
            )
          ) : (
            <></>
          )}
          {!isLoading && !isError && (
            <AdminTable AdminData={AdminData} modalStatusChange={toggle} />
          )}
          {!isLoading && isDetailOpened && (<AdminDetailModal
            propsAdminData={AdminData} adminId={adminId} modalStatusChange={toggle} modalHapus={togglehapus}

          />)}
          {!isLoading && isHapusOpened && (<AdminHapusPenggunaModal
            propsAdminData={AdminData} adminId={adminId} modalStatusChange={toggle} modalHapus={togglehapus}
          />)}

        </Main>
      </GridLayout>

    </>
  );
}
export default Admin;
