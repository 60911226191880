import { useState, useEffect } from "react";
import GridLayout from "../../components/GridLayout";
import ErrorMessage from "../../components/ErrorMessage";
import Icons from "../../components/Icons";
import Main from "../../components/Main";
import PageHeader from "../../components/PageHeader";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import useError from "../../hooks/useError";
import KontenKeluhanToolbar from "./components/KontenKeluhanToolbar";
import LoadingSpinner from "../../components/LoadingSpinner";
import KontenKeluhanTable from "./components/KontenKeluhanTable";
import useReactiveSearchParams from "../../hooks/useReactiveSearchParams";
import { getComplaint } from "../../services/complaintContent";
import KontenKeluhanDetailModal from "./components/KontenKeluhanDetailModal";
import KontenKeluhanHapusModal from "./components/KontenKeluhanHapusModal";
import Helmet from "react-helmet";

function KontenKeluhan() {
  const [isLoading, setIsLoading] = useState();
  const [complaintContentData, setComplaintContentData] = useState();
  const { error, isError, setError } = useError();
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const [isDetailOpened, setIsDetailOpened] = useState();
  const [isHapusOpened, setIsHapusOpened] = useState();
  const [contentComplaintId, setcontentComplaintId] = useState();

  const toggle = (complaint) => {
    setIsDetailOpened(isDetailOpened => !isDetailOpened);
    setcontentComplaintId(complaint);
  }

  const togglehapus = (complaint) => {
    setIsDetailOpened(false);
    setIsHapusOpened(isHapusOpened => !isHapusOpened);
    setcontentComplaintId(complaint);
  }

  const getComplaintContentData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setComplaintContentData(
        await getComplaint({ page: 1, size: 10, ...searchParams })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getComplaintContentData();
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Konten Keluhan | Backoffice Intra Q Media</title>
      </Helmet>
      <GridLayout>
        <Sidebar />
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.Send className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Konten Keluhan"
          />
          <KontenKeluhanToolbar />
          {isLoading ? (
            <div className="h-24 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          ) : (
            <></>
          )}

          {!isLoading && !isError ? (
            <KontenKeluhanTable complaintContentData={complaintContentData} modalStatusChange={toggle} />
          ) : (
            <></>
          )}
          {isError ? (
            error.code == 404 ? (
              <div className="p-24 flex items-center justify-center">
                <p className="flex gap-4 text-gray-300">
                  <span>
                    <Icons.Database className="stroke-gray-300" />
                  </span>
                  <span>Data Tidak Ditemukan</span>
                </p>
              </div>
            ) : (
              <ErrorMessage error={error} />
            )
          ) : (
            <></>
          )}
          {!isLoading && isDetailOpened && (
            <KontenKeluhanDetailModal
              refreshLaporanData={getComplaintContentData} modalStatusChange={toggle} contentComplaintId={contentComplaintId} modalHapus={togglehapus}
            />
          )}
          {!isLoading && isHapusOpened && (
            <KontenKeluhanHapusModal
              refreshLaporanData={getComplaintContentData} modalStatusChange={toggle} contentComplaintId={contentComplaintId} modalHapus={togglehapus} />
          )}
        </Main>
      </GridLayout>
    </>
  )
}
export default KontenKeluhan;
