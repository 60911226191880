import ButtonGroup from "../../../components/ButtonGroup";
import Button from "../../../components/Button";
import TextField from "../../../components/TextField";
import Icons from "../../../components/Icons";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";
import _ from "lodash";

function JurnalisToolbar({ modalStatusChange }) {
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  return (
    <div className="py-2 flex items-center justify-between gap-4 flex-wrap">
      <ButtonGroup className="mr-auto">
        <ButtonGroup.Item
          onClick={() => setSearchParams({ ...searchParams, size: 10 })}
          isActive={
            searchParams.size ? Number.parseInt(searchParams.size) === 10 : true
          }
        >
          View 10
        </ButtonGroup.Item>
        <ButtonGroup.Item
          onClick={() => setSearchParams({ ...searchParams, page:1, size: 20 })}
          isActive={Number.parseInt(searchParams.size) === 20}
        >
          View 20
        </ButtonGroup.Item>
        <ButtonGroup.Item
          onClick={() => setSearchParams({ ...searchParams, page:1, size: 30 })}
          isActive={Number.parseInt(searchParams.size) === 30}
        >
          View 30
        </ButtonGroup.Item>
      </ButtonGroup>
      <Button.Primary
        icon={<Icons.Plus className="w-4 h-4 stroke-white" />}
        onClick={() => modalStatusChange("import")}
      >
        Import Jurnalis 
      </Button.Primary>
      <Button.Primary
        icon={<Icons.Plus className="w-4 h-4 stroke-white" />}
        onClick={() => modalStatusChange("create")}
      >
        Buat Jurnalis Baru
      </Button.Primary>
      <TextField
        placeholder="Cari berdasarkan Nama Jurnalis"
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            setSearchParams(
              event.target.value !== ""
                ? { name: event.target.value }
                : { ..._.omit(searchParams, ["name"]) }
            );
          }
        }}
        value={searchParams.journalist_id}
        icon={<Icons.Search className="w-4 h-4 stroke-custom-primary-text" />}
      />{" "}
    </div>
  );
}

export default JurnalisToolbar;
