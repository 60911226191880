import { Link } from "react-router-dom";
import Table, { Row } from "../../../components/Table";
import Button from "../../../components/Button";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";

/**
 * Table section of screen `Jurnalis`
 * @typedef {Object} Journalist
 * @property {string} _id
 * @property {string} name
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {string} avatar
 * @property {Object} contact
 * @property {string} contact.email 
 * @property {string} contact.phoneNumber
 * 
 * @param {Object} props
 * @param {Object} props.journalistData
 * @param {Journalist[]} props.journalistData.list
 */
function JurnalisTable({ journalistData, modalStatusChange }) {
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  return (
    <Table
      headingRow={[
        { name: "Nama Jurnalis", unsortable: true },
        { name: "Email Jurnalis", unsortable: true },
        { name: "No Telp", unsortable: true },
        { name: "Nama Media", unsortable: true },
        { name: "Desk", unsortable: true },
        { name: "Aksi", unsortable: true },
      ]}
      currentPage={searchParams.page ?? 1}
      totalPages={journalistData?.totalPage}
    >
      {journalistData?.list?.map((journalist, i) => {
        return (
          <Row
            key={i}
            rowCells={[
              <UsernameWithAvatar
                username={journalist.name}
                avatarPath={journalist.avatar} />,
              journalist?.contact?.email ?? "-",
              journalist?.contact?.phoneNumber ?? "-",
              journalist?.media?.name ?? "-",
              journalist?.desk ?? "-",
              <>
              <div className="flex divide-x-2 divide-gray-400 font-semibold text-custom-primary">
                <div className="px-2 first:pl-0 cursor-pointer hover:underline" onClick={() => modalStatusChange(journalist, "edit")}>
                  Edit
                </div>
                <div className="px-2 first:pl-0 cursor-pointer hover:underline" onClick={() => modalStatusChange(journalist._id, "details")}>
                  Lihat Detail
                </div>
              </div>
              </>
              
            ]}
          />
        );
      }
      )}
      </Table>

      )
      }
export default JurnalisTable;
