export const API_ROUTES = {
  LOGIN_ADMIN: "/users/v1/login-admin",
  CREATE_ADMIN: "/users/v1/admin",
  GET_ADMIN_LIST: "/users/v1/admin",
  DELETE_ADMIN: "/users/v1/admin",
  RESET_ADMIN_PASSWORD: "/users/v1/admin",
  GET_MY_PROFILE: "/users/v1/account",
  DELETE_USER: "/users/v1/account",
  UPDATE_PROFILE: "/users/v1/account",
  GET_COMPLAINT_POPULAR: "/contents/v1/complaint/popular",
  GET_COMPLAINT: "/contents/v1/complaint",
  DELETE_COMPLAINT: "/contents/v1/complaint",
  CHANGE_COMPLAINT_CATEGORY: "/contents/v1/complaint",
  SET_COMPLAINT_PUBLISH: "/contents/v1/complaint/status",
  SET_COMPLAINT_REJECT: "/contents/v1/complaint/status",
  GET_NEWS_POPULAR: "/contents/v1/news/popular",
  GET_NEWS: "/contents/v1/news",
  CREATE_NEWS: "/contents/v1/news",
  UPDATE_NEWS: "/contents/v1/news",
  DELETE_NEWS: "/contents/v1/news",
  CHANGE_NEWS_CATEGORY: "/contents/v1/news",
  GET_REPORT_CATEGORIES: "/contents/v1/report/category",
  GET_REPORTED_CONTENT: "/contents/v1/report",
  SET_REPORTED_STATUS: "/contents/v1/report/status",
  DELETE_REPORT: "/contents/v1/report",
  GET_FILE_URL: "/files/v1/url",
  UPLOAD_COMPANY_AVATAR: "/files/v1/upload/company",
  UPLOAD_NEWS_IMAGE: "/files/v1/upload/content-news",
  GET_FEEDBACK: "/contents/v1/feedback",
  SET_FEEDBACKSTATUS: "/contents/v1/feedback/status",
  DELETE_FEEDBACK: "/contents/v1/feedback",
  GET_USER_LIST: "/users/v1/account-list",
  GET_NEED_VERIFICATION: "/users/v1/kyc/verification",
  SET_USER_STATUS: "/users/v1/kyc/status",
  GET_CATEGORIES: "/contents/v1/category",
  GET_TAGS: "/contents/v1/tags",
  CREATE_TAGS: "/contents/v1/tags",
  GET_COMPANY: "/companies/v1/company",
  CREATE_COMPANY: "/companies/v1/company",
  UPDATE_COMPANY: "/companies/v1/company",
  DELETE_COMPANY: "/companies/v1/company",
  SUBS_COMPANY: "/contents/v1/subscriptions",
  UPDATE_SUBS_COMPANY: "/contents/v1/subscriptions",
  GET_COMMENT: "/contents/v1/comment",
  DELETE_COMMENT: "/contents/v1/comment",
  GET_PETITION: "/contents/v1/petition",
  DELETE_PETITION: "/contents/v1/petition",
  CHANGE_PETITION_CATEGORY: "/contents/v1/petition",
  SET_PETITION_PUBLISH: "/contents/v1/petition/status",
  SET_PETITION_REJECT: "/contents/v1/petition/status",
  GET_JOURNALIST: "/journalists/v1/journalist",
  CREATE_JOURNALIST: "/journalists/v1/journalist",
  UPDATE_JOURNALIST: "/journalists/v1/journalist",
  DELETE_JOURNALIST: "/journalists/v1/journalist",
};

export const status = {
  PUBLISHED: "published",
  REJECTED: "rejected",
  SUBMITTED : "submitted",
  DELETED : "closed",
  VERIFIED : "verified",
  UNVERIFIED : "unverified",
  RESPONDED : "responded",
  MODERATED : "moderated",
};

export const subscriptions_type =[
  {
    label: "GOLD",
    value: "gold",
  },
  {
    label: "SILVER",
    value: "silver",
  },
  {
    label: "BRONZE",
    value: "bronze",
  },
]
export const subscriptions_duration =[
  {
    label: "1 Tahun",
    value: "12",
  },
  {
    label: "2 Tahun",
    value: "24",
  },
  {
    label: "3 Tahun",
    value: "36",
  },
]
export const MINIO_ASSETS = process.env.REACT_APP_MEDIA_URL