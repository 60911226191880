import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import ErrorMessage from "../../../components/ErrorMessage";
import Icons from "../../../components/Icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import MultiLineString from "../../../components/MultiLineString";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useError from "../../../hooks/useError";
import { useFiles } from "../../../hooks/useFiles";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";
import { getFileUrl } from "../../../services/files";
import { getPetition } from "../../../services/petitionContent";

function ModerasiKontenPetisiModerasiModal({ modalStatusChange, PetitionId, modalPublikasi, modalTolak }) {
  const [petisiData, setPetisiData] = useState();
  const [isLoading, setIsLoading] = useState();
  const { error, setError, isError } = useError();
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const [isOpened, setIsOpened] = useState();
  const toggle = () => {
    modalStatusChange(!isOpened)
  }
  const [coverUrl, setCoverUrl] = useState();


  const getPetisiData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setPetisiData(
        await getPetition({ content_petition_id: PetitionId })
      );

    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);

    }
  };
  useEffect(() => {
    getPetisiData();
  }, [searchParams]);

  return (
    <>
      <ModalBackdrop>
        <article className="w-[865px] p-8 rounded-md bg-white flex flex-col gap-4">
          <header className="flex items-center gap-4">
            <h2 className="text-2xl text-custom-primary font-bold">
              Moderasi Petisi
            </h2>
            <Button.Text onClick={toggle} className="ml-auto p-2">
              <Icons.X className="w-6 h-6 stroke-custom-primary" />
            </Button.Text>
          </header>
          {!isLoading && !isError ? (
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <p className="mb-1.5 text-xs text-custom-secondary-text">
                  Isi Petisi
                </p>
                <article className="text-sm text-custom-primary-text">
                  <MultiLineString text={petisiData?.list[0]?.body} />
                </article>
                  <img src={coverUrl} className="w-96 h-96 rounded-md"></img>
                 </div>
              <div className="flex flex-col gap-4">
                <div className="">
                  <p className="mb-1.5 text-xs text-custom-secondary-text">
                    Keluhan yang ditanggapi
                  </p>
                  <p className="text-sm text-custom-primary-text">
                    {petisiData?.list[0]?.title}
                  </p>
                </div>
                <div className="">
                  <p className="mb-1.5 text-xs text-custom-secondary-text">
                    Pembuat Petisi
                  </p>
                  <UsernameWithAvatar
                    className="text-xs"
                    avatarPath={
                      petisiData?.list[0]?.user.avatar ?? "-"
                    }
                    username={petisiData?.list[0]?.user.name ?? "-"}
                  />
                </div>
                <div className="">
                  <p className="mb-1.5 text-xs text-custom-secondary-text">
                    Perusahaan
                  </p>
                  <UsernameWithAvatar username={petisiData?.list[0]?.institution ?? "-"} />
                </div>
                <div className="">
                  <p className="mb-1.5 text-xs text-custom-secondary-text">
                    Waktu Pembuatan
                  </p>
                  <p className="text-sm text-custom-primary-text">
                    {moment(petisiData?.list[0]?.createdAt).format(
                      "DD-MM-YYYY, HH:MM"
                    )}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {isLoading ? (
            <div className="py-16 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          ) : (
            <></>
          )}
          {isError ? <ErrorMessage error={error} /> : <></>}
          <footer className="py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
            <Button.Primary
              onClick={() => modalPublikasi(PetitionId)}
            >
              Publikasikan Petisi
            </Button.Primary>
            <Button.Secondary
              onClick={() => modalTolak(PetitionId)}
            >
              Tolak Petisi
            </Button.Secondary>
          </footer>
        </article>
      </ModalBackdrop>
    </>
  );
}
export default ModerasiKontenPetisiModerasiModal;
