import { useState, useEffect } from "react";
import GridLayout from "../../components/GridLayout";
import ErrorMessage from "../../components/ErrorMessage";
import Icons from "../../components/Icons";
import Main from "../../components/Main";
import PageHeader from "../../components/PageHeader";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import useError from "../../hooks/useError";
import KontenBeritaToolbar from "./components/KontenBeritaToolbar";
import LoadingSpinner from "../../components/LoadingSpinner";
import KontenBeritaTable from "./components/KontenBeritaTable";
import useReactiveSearchParams from "../../hooks/useReactiveSearchParams";
import { getNews } from "../../services/newsContent";
import KontenBeritaDetailModal from "./components/KontenBeritaDetailModal";
import KontenBeritaHapusModal from "./components/KontenBeritaHapusModal";
import Helmet from "react-helmet";
import KontenBeritaBaruModal from "./components/KontenBeritaBaruModal";
import KontenBeritaEditModal from "./components/KontenBeritaEditModal";

function KontenBerita() {
  const [isLoading, setIsLoading] = useState();
  const [newsContentData, setNewsContentData] = useState();
  const { error, isError, setError } = useError();
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const [isDetailOpened, setIsDetailOpened] = useState();
  const [isEditOpened, setIsEditOpened] = useState();
  const [isHapusOpened, setIsHapusOpened] = useState();
  const [contentNewsId, setcontentNewsId] = useState();
  const [isBaruOpened, setIsBaruOpened] = useState();

  const togglebaru = () => {
    setIsBaruOpened(wasOpened => !wasOpened);
  }
  const toggle = (news) => {
    setIsDetailOpened(isDetailOpened => !isDetailOpened);
    setcontentNewsId(news);
  }

  const togglehapus = (news) => {
    setIsDetailOpened(false);
    setIsHapusOpened(isHapusOpened => !isHapusOpened);
    setcontentNewsId(news);
  }
const toggleedit =(news) => {
  setIsDetailOpened(false);
  setIsEditOpened(isEditOpened => !isEditOpened);
  setcontentNewsId(news)
}
  const getNewsContentData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setNewsContentData(
        await getNews({ page: 1, size: 10, ...searchParams })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNewsContentData();
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Konten Berita | Backoffice Intra Q Media</title>
      </Helmet>
      <GridLayout>
        <Sidebar />
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.Send className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Konten Berita"
          />
          <KontenBeritaToolbar modalStatusChange={togglebaru} />
          {isLoading ? (
            <div className="h-24 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          ) : (
            <></>
          )}

          {!isLoading && !isError ? (
            <KontenBeritaTable newsContentData={newsContentData} modalStatusChange={toggle} />
          ) : (
            <></>
          )}
          {isError ? (
            error.code == 404 ? (
              <div className="p-24 flex items-center justify-center">
                <p className="flex gap-4 text-gray-300">
                  <span>
                    <Icons.Database className="stroke-gray-300" />
                  </span>
                  <span>Data Tidak Ditemukan</span>
                </p>
              </div>
            ) : (
              <ErrorMessage error={error} />
            )
          ) : (
            <></>
          )}
          {!isLoading && isDetailOpened && (
            <KontenBeritaDetailModal
              refreshLaporanData={getNewsContentData} modalStatusChange={toggle} contentNewsId={contentNewsId} modalHapus={togglehapus} modalEdit={toggleedit}
            />
          )}
          {!isLoading && isEditOpened && (
            <KontenBeritaEditModal
              refreshLaporanData={getNewsContentData} modalStatusChange={toggle} contentNewsId={contentNewsId} modalEdit={toggleedit}
            />
          )}
          {!isLoading && isHapusOpened && (
            <KontenBeritaHapusModal
              refreshLaporanData={getNewsContentData} modalStatusChange={toggle} contentNewsId={contentNewsId} modalHapus={togglehapus} />
          )}
           {!isLoading && isBaruOpened && (
          <KontenBeritaBaruModal refreshLaporanData={getNewsContentData} modalStatusChange={togglebaru} />
        )}
        </Main>
      </GridLayout>
    </>
  )
}
export default KontenBerita;
