import Button from "./Button";
import Icons from "./Icons";
import TextField from "./TextField";

function SortableListItem({
  item,
  moveUpAction,
  moveDownAction,
  removeAction,
  itemChangeAction,
  isEditable,
  isFirstItem,
  isLastItem,
}) {
  return (
    <li className="w-min p-2 pl-6 rounded-md bg-custom-background-lighter flex gap-4">
      {isEditable ? (
        <button
          onClick={moveUpAction}
          disabled={isFirstItem}
          className="stroke-black disabled:opacity-20"
        >
          <Icons.ChevronUp />
          <span className="sr-only">Move up</span>
        </button>
      ) : (
        <></>
      )}
      {isEditable ? (
        <button
          onClick={moveDownAction}
          disabled={isLastItem}
          className="stroke-black disabled:opacity-20"
        >
          <Icons.ChevronDown />
          <span className="sr-only">Move down</span>
        </button>
      ) : (
        <></>
      )}
      {isEditable ? (
        <TextField
          placeholder="Masukkan alasan laporan"
          value={item}
          className="w-64"
          onChange={(event) => itemChangeAction(event.target.value)}
        />
      ) : (
        <span className="w-64 h-10 inline-flex items-center justify-start">{item}</span>
      )}
      {isEditable ? (
        <button onClick={removeAction}>
          <Icons.Trash />
          <span className="sr-only">Remove</span>
        </button>
      ) : (
        <></>
      )}
    </li>
  );
}

function SortableList({ items, setItems, isEditable }) {
  const moveItemUp = (items, index) => {
    let newItems = [...items];
    newItems[index] = items[index - 1];
    newItems[index - 1] = items[index];

    return newItems;
  };

  const moveItemDown = (items, index) => {
    let newItems = [...items];
    newItems[index] = items[index + 1];
    newItems[index + 1] = items[index];

    return newItems;
  };

  const removeItem = (items, index) => {
    return [...items.slice(0, index), ...items.slice(index + 1)];
  };

  const appendItem = (items, newItem) => [...items, newItem];

  const changeItem = (items, index, newItem) => [
    ...items.slice(0, index),
    newItem,
    ...items.slice(index + 1),
  ];

  return (
    <>
      <ul className="flex flex-col gap-2">
        {items?.map((item, index) => (
          <SortableListItem
            key={index}
            isFirstItem={index === 0}
            isLastItem={index === items.length - 1}
            item={item}
            moveUpAction={() => setItems(moveItemUp(items, index))}
            moveDownAction={() => setItems(moveItemDown(items, index))}
            removeAction={() => setItems(removeItem(items, index))}
            itemChangeAction={(newItem) =>
              setItems(changeItem(items, index, newItem))
            }
            isEditable={isEditable}
          />
        ))}
      </ul>
      <Button.Secondary onClick={() => setItems(appendItem(items, ""))}>
        Add
      </Button.Secondary>
    </>
  );
}

export default SortableList;
export { SortableListItem };
