import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Icons from "../../../components/Icons";
import ModalBackdrop from "../../../components/ModalBackdrop";
import MultiLineString from "../../../components/MultiLineString";
import TextField from "../../../components/TextField";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useError from "../../../hooks/useError";
import { getPetition, setPetitionReject } from "../../../services/petitionContent";

function ModerasiKontenPetisiTolakModal({ refreshPetisiData, modalStatusChange, PetitionId, modalTolak }) {
  const [PetitionData, setPetitionData] = useState();
  const [isLoading, setIsLoading] = useState();
  const { error, isError, setError } = useError();
  const [isOpened] = useState();
  const [statusReason, setstatusReason] = useState('');

  const toggle = () => {
    modalTolak(!isOpened)
    modalStatusChange(!isOpened)
  }

  const getPetitionData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setPetitionData(await getPetition({ content_petition_id: PetitionId }));
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const HandlerejectPetition = () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setPetitionReject({ content_petition_id: PetitionId , statusReason: statusReason});
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
      refreshPetisiData();
      toggle();
    }
  }
  const HandlersetstatusReason = event => {
    setstatusReason(event.target.value);
  }
  useEffect(() => {
    getPetitionData();
  }, []);
  return (
    <ModalBackdrop>
      <article className="w-[540px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Publikasi Petisi
          </h2>
          <Button.Text onClick={toggle} className="ml-auto p-2">
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-custom-primary-text">
            Anda akan menolak Petisi ini.
            <span className="font-bold">
              PERHATIAN: Petisi yang sudah ditolak tidak dapat dikembalikan
              lagi.
            </span>
          </p>
          <article className="p-4 rounded-md bg-custom-background-lighter first:pt-0 flex flex-col gap-4">
            <MultiLineString text={PetitionData?.list[0]?.body} />
            <div className="grid grid-cols-3 gap-2 text-xs">
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Pembuat Petisi</p>
                <UsernameWithAvatar
                  className="text-xs"
                  avatarPath={
                    PetitionData?.list[0]?.user.avatar ?? "-"
                  }
                  username={PetitionData?.list[0]?.user.name ?? "-"}
                />
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Perusahaan</p>
                <UsernameWithAvatar username={PetitionData?.list[0]?.user.company ?? "-"} />
              </div>
            </div>
          </article>
          <p className="text-sm text-custom-primary-text">
            Silahkan pilih alasan penolakan Petisi ini. Alasan akan
            diberitahukan melalui notifikasi pengguna.
          </p>
          <div className="mb-2 flex flex-col gap-1">
            <TextField labelText="Alasan" required={true} value={statusReason} onChange={HandlersetstatusReason} />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Button.Secondary onClick={toggle}>
            Batal
          </Button.Secondary>
          <Button.Primary onClick={() => HandlerejectPetition()}>Tolak Petisi</Button.Primary>
        </div>
      </article>
    </ModalBackdrop>
  );
}
export default ModerasiKontenPetisiTolakModal;
