import axios from "axios";
import { API_ROUTES } from "../constansts";

async function getUserList(params) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_USER_LIST,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache",

      },
      params
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function getMyProfile(params) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_MY_PROFILE,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache",

      },
      params,
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function updateProfile({ user_id, userData }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.UPDATE_PROFILE,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache",

      },
      params: { user_id },
      data: userData,
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function deleteUser({ user_id, statusReason }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.DELETE_USER,
      method: "delete",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache",
      },
      params: { 
        user_id 
      },
      data: {
        statusReason: statusReason,
      },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}
export { getMyProfile, getUserList, updateProfile,  deleteUser,};
