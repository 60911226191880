import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Icons from "../../../components/Icons";
import Table, { Row } from "../../../components/Table";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import TextField from "../../../components/TextField";
import useError from "../../../hooks/useError";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { createJournalist, updateJournalist } from "../../../services/journalists";
import { uploadCompanyAvatar } from "../../../services/files";
import * as XLSX from 'xlsx';
import LoadingOverlay from 'react-loading-overlay';

function JurnalisImportBaruModal({ modalStatusChange, refreshJurnalisData, editData, isEdit }) {
  const [isLoading, setIsLoading] = useState();
  const { error, setError, isError } = useError();
  const [files, setFiles] = useState([]);
  const [uploadedData, setUploadedData] = useState([]);
  const [fileError, setFileError] = useState(null);
  const [bucketErrorData, setBucketErrorData] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const toggle = () => {
    modalStatusChange();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const allowedExtensions = ['xls', 'xlsx'];
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      setFileError('Invalid file format. Please upload an Excel file.');
      setUploadedData([]);
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Convert JSON data into an array of objects
      const formattedData = jsonData.slice(1).map((row) => ({
        name: row[0] || '',
        email: row[1] || '',
        phoneNumber: row[2].toString() || '',
        description: row[3] || '',
        mediaName: row[4] || '',
        mediaEmail: row[5] || '',
        desk: row[6] || '',
      }));

      setUploadedData(formattedData);
      setFileError(null);
    };

    reader.onerror = () => {
      setFileError('Error reading the file. Please try again.');
    };

    reader.readAsArrayBuffer(file);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await Promise.all(uploadedData.map(async (item) => {
        const payload = {
          name: item.name,
          avatar: "/general/default_image_profile.svg",
          contact: {
            email: item.email,
            phoneNumber: item.phoneNumber,
          },
          media: {
            name: item.mediaName,
            email: item.mediaEmail
          },
          desk: item.desk
        };
        console.log(">>> payload ", payload);
        const result = await createJournalist(payload);
        // alert(result);
      }))
    } catch (e) {
      console.log(e);
      // alert("Ada kesalahan data");
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        toggle();
        refreshJurnalisData();
      }, 4000);
    }
  }

  // useEffect(() => {
  //   files.forEach((file) => URL.revokeObjectURL(file.preview));
  // }, [files]);

  return (
    <ModalBackdrop>
      <LoadingOverlay
        active={isLoading}
        spinner
        text='Memproses data anda...'
        >
      </LoadingOverlay>
      <article className="w-[auto] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Import Data Jurnalis
          </h2>
          <Button.Text onClick={() => toggle()} className="ml-auto p-2">
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        <div className="flex flex-col gap-6">
          <h3 className="text-lg text-custom-primary-text font-bold flex items-center">
            <a href="/template-import-jurnalis.xlsx">Template Jurnalis.xlsx</a>
            <span className="ml-3 px-2 py-1 rounded-md bg-custom-background-lighter text-xs font-normal">
              <span className="text-red-500">*</span> Mohon pergunakan template berikut
            </span>
          </h3>
          <div className="grid grid-cols-3 gap-8 text-xs">
            <h3 className="text-custom-secondary-text">Upload Excel File</h3>
            <input type="file" accept=".xls,.xlsx" onChange={handleFileUpload} />
            {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
          </div>
          <div className="grid  text-xs">
            {uploadedData.length > 0 && (
              <div>
                <h4>Data Verifikasi</h4>
                <Table
                  headingRow={[
                    { name: "Nama Jurnalis", unsortable: true },
                    { name: "Email Jurnalis", unsortable: true },
                    { name: "No Telp", unsortable: true },
                    { name: "Nama Media", unsortable: true },
                    { name: "Email Media", unsortable: true },
                    { name: "Desk", unsortable: true }
                  ]}
                  currentPage={1}
                  totalPages={1}
                >
                  {uploadedData?.map((journalist, i) => {
                    return (
                      <Row
                        key={i}
                        rowCells={[
                          journalist?.name ?? "-",
                          journalist?.email ?? "-",
                          journalist?.phoneNumber ?? "-",
                          journalist?.mediaName ?? "-",
                          journalist?.mediaEmail ?? "-",
                          journalist?.desk ?? "-"
                        ]}
                      />
                    );
                  }
                  )}
                  </Table>
              </div>
            )}
          </div>
        </div>
        <footer className="mt-6 py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
          {!isLoading ? (
            <>
              <Button.Secondary onClick={() => toggle()}>
                Batal
              </Button.Secondary>
              <Button.Primary type="submit" onClick={handleSubmit}>
                {isEdit ? "Update Jurnalis" : "Buat Jurnalis"}
              </Button.Primary>
            </>
          ) : (
            <div className="w-full flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
        </footer>
      </article>
    </ModalBackdrop>
  );
}
export default JurnalisImportBaruModal;
