import { Link } from "react-router-dom";
import Table, { Row } from "../../../components/Table";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";

function SuperadminManajemenAdminTable({ adminListData }) {
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  return (
    <div className="">
      <Table
        headingRow={[
          {
            name: "ID",
            handleSort: () =>
              setSearchParams({
                ...searchParams,
                sortBy: "id",
                order: searchParams.order === "desc" ? "asc" : "desc",
              }),
            currentSortMethod:
              searchParams.sortBy === "id" ? searchParams.order : "unsorted",
          },
          {
            name: "Nama",
            handleSort: () =>
              setSearchParams({
                ...searchParams,
                sortBy: "nama",
                order: searchParams.order === "desc" ? "asc" : "desc",
              }),
            currentSortMethod:
              searchParams.sortBy === "nama" ? searchParams.order : "unsorted",
          },
          {
            name: "Aktifitas Terakhir",
            handleSort: () =>
              setSearchParams({
                ...searchParams,
                sortBy: "aktifitas-terakhir",
                order: searchParams.order === "desc" ? "asc" : "desc",
              }),
            currentSortMethod:
              searchParams.sortBy === "aktifitas-terakhir"
                ? searchParams.order
                : "unsorted",
          },
          {
            name: "Dibuat",
            handleSort: () =>
              setSearchParams({
                ...searchParams,
                sortBy: "dibuat",
                order: searchParams.order === "desc" ? "asc" : "desc",
              }),
            currentSortMethod:
              searchParams.sortBy === "dibuat"
                ? searchParams.order
                : "unsorted",
          },
          {
            name: "Aksi",
            unsortable: true,
          },
        ]}
      >
        {adminListData?.list?.map((x) => (
          <Row
            rowCells={[
              1243,
              "Ulfric Stormcloak",
              "17-8-2021, 19:45",
              "17-8-2021, 19:45",
              <>
                <div className="divide-x-2 divide-gray-400 font-semibold text-custom-primary">
                  <Link
                    to="1243/reset-password"
                    className="pr-2 hover:underline"
                  >
                    Reset Password
                  </Link>
                  <Link to="1243/hapus-admin" className="pl-2 hover:underline">
                    Hapus Admin
                  </Link>
                </div>
              </>,
            ]}
          />
        ))}
      </Table>
    </div>
  );
}
export default SuperadminManajemenAdminTable;
