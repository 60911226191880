import { Link } from "react-router-dom";
import moment from "moment";
import Table, { Row } from "../../../components/Table";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";

function VerifikasiDataDiriPenggunaEnterpriseTable({ enterpriseUserData }) {
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  return (
    <Table
      totalPages={enterpriseUserData?.totalPage}
      currentPage={searchParams.page ?? 1}
      headingRow={[
        {
          name: "Nama PIC",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "nama-pic",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "nama-pic" ? searchParams.order : "unsorted",
        },
        {
          name: "Perusahaan",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "perusahaan",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "perusahaan" ? searchParams.order : "unsorted",
        },
        {
          name: "Email",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "email-pic",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "email-pic" ? searchParams.order : "unsorted",
        },
        {
          name: "Waktu",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "nama",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "nama" ? searchParams.order : "unsorted",
        },
        {
          name: "Aksi",
          unsortable: true,
        },
      ]}
    >
      {enterpriseUserData?.list?.map((user, i) => (
        <Row
          key={i}
          rowCells={[
            <UsernameWithAvatar
              avatarPath={user.avatar}
              username={user.name}
            />,
            user.company.name,
            user.email,
            moment(user.createdAt).format("YYYY-MM-DD, HH:MM"),
            <>
              <div className="divide-x-2 divide-gray-400 font-semibold text-custom-primary">
                <Link to={`${user._id}/verifikasi`} className="pr-2 hover:underline">
                  Verifikasi
                </Link>
              </div>
            </>,
          ]}
        />
      ))}{" "}
    </Table>
  );
}
export default VerifikasiDataDiriPenggunaEnterpriseTable;
