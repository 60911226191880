import moment from "moment";
import { Link } from "react-router-dom";
import Table, { Row } from "../../../components/Table";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";

function ModerasiKontenPetisiTable({ PetisiData , modalStatusChange}) {
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  return (
    <Table
      totalPages={PetisiData.totalPage}
      currentPage={searchParams.page ?? 1}
      headingRow={[
        {
          name: "Pembuat Petisi",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "pembuat-Petisi",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "pembuat-Petisi"
              ? searchParams.order
              : "unsorted",
        },
        {
          name: "Perusahaan",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "perusahaan",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "perusahaan"
              ? searchParams.order
              : "unsorted",
        },
        {
          name: "Petisi",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "Petisi",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "Petisi" ? searchParams.order : "unsorted",
        },
        {
          name: "Waktu",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "waktu",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "waktu" ? searchParams.order : "unsorted",
        },
        {
          name: "Aksi",
          unsortable: true,
        },
      ]}
    >
      {PetisiData?.list?.map((Petisi, i) => (
        <Row
          key={i}
          rowCells={[
            <UsernameWithAvatar
              avatarPath={Petisi.user?.avatar ?? "-"}
              username={Petisi.user?.name ?? "-"}
            />,
            <UsernameWithAvatar username = {Petisi.institution ?? "-"}/>,
            Petisi.body,
            moment(Petisi.createdAt).format("YYYY-MM-DD, HH:MM"),
            <>
              <div className="divide-x-2 divide-gray-400 font-semibold text-custom-primary">
              <div className="px-2 first:pl-0 cursor-pointer hover:underline" onClick={() => modalStatusChange(Petisi._id)}>
                      Moderasi
                  </div>
              </div>
            </>,
          ]}
        />
      ))}
    </Table>
  );
}
export default ModerasiKontenPetisiTable;
