import GridLayout from "../../components/GridLayout";
import Sidebar from "../../components/Sidebar";
import PageHeader from "../../components/PageHeader";
import PageTitle from "../../components/PageTitle";
import Main from "../../components/Main";
import Icons from "../../components/Icons";
import PenggunaIndividuTable from "./components/PenggunaIndividuTable";
import PenggunaIndividuToolbar from "./components/PenggunaIndividuToolbar";
import useError from "../../hooks/useError";
import { useEffect, useState } from "react";
import { getUserList } from "../../services/usersDataProfile";
import LoadingSpinner from "../../components/LoadingSpinner";
import ErrorMessage from "../../components/ErrorMessage";
import useReactiveSearchParams from "../../hooks/useReactiveSearchParams";
import PenggunaIndividuDetailModal from "./components/PenggunaIndividuDetailModal";
import PenggunaIndividuHapusPenggunaModal from "./components/PenggunaIndividuHapusPenggunaModal";
import Helmet from "react-helmet";

function PenggunaIndividu() {
  const [individualUserData, setIndividualUserData] = useState();
  const [isLoading, setIsLoading] = useState();
  const { error, setError, isError } = useError();
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const [isDetailOpened, setIsDetailOpened] = useState();
  const [isHapusOpened, setIsHapusOpened] = useState();
  const [penggunaId, setpenggunaId] = useState();

  const toggle = (user) => {
    setIsDetailOpened(isDetailOpened => !isDetailOpened);
    setpenggunaId(user);
  }
  const togglehapus = (user) => {
    setIsDetailOpened(false);
    setIsHapusOpened(isHapusOpened => !isHapusOpened);
    setpenggunaId(user);
  }

  useEffect(() => {
    getIndividualUserData();
  }, [searchParams]);

  const getIndividualUserData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setIndividualUserData(
        await getUserList({
          page: 1,
          size: 10,
          role: "user-individual",
          ...searchParams,
        })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Pengguna Individu | Backoffice Intra Q Media</title>
      </Helmet>
      <GridLayout>
        <Sidebar />
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.Users className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Pengguna Individu"
          />
          <PenggunaIndividuToolbar />
          {isLoading && (
            <div className="h-24 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
          {isError ? (
            error.code == 404 ? (
              <div className="p-24 flex items-center justify-center">
                <p className="flex gap-4 text-gray-300">
                  <span>
                    <Icons.Database className="stroke-gray-300" />
                  </span>
                  <span>Data Tidak Ditemukan</span>
                </p>
              </div>
            ) : (
              <ErrorMessage error={error} />
            )
          ) : (
            <></>
          )}
          {!isLoading && !isError && (
            <PenggunaIndividuTable individualUserData={individualUserData} modalStatusChange={toggle} />
          )}
          {!isLoading && isDetailOpened && (<PenggunaIndividuDetailModal
            refreshPenggunaData={getIndividualUserData} penggunaId={penggunaId} modalStatusChange={toggle} modalHapus={togglehapus}

          />)}
          {!isLoading && isHapusOpened && (<PenggunaIndividuHapusPenggunaModal
            refreshPenggunaData={getIndividualUserData} penggunaId={penggunaId} modalStatusChange={toggle} modalHapus={togglehapus}
          />)}

        </Main>
      </GridLayout>

    </>
  );
}
export default PenggunaIndividu;
