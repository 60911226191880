import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import ErrorMessage from "../../../components/ErrorMessage";
import TextField from "../../../components/TextField";
import Icons from "../../../components/Icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useError from "../../../hooks/useError";
import {
  deleteNews,
  getNews,
} from "../../../services/newsContent";

function KontenBeritaHapusModal({refreshLaporanData, modalStatusChange, contentNewsId, modalHapus}) {
  const [isLoading, setIsLoading] = useState();
  const { error, isError, setError } = useError();
  const [contentNewsData, setContentNewsData] = useState();
  const [statusReason, setstatusReason] = useState('');
  const toggle = () => {
    modalStatusChange()
    modalHapus()
  }
  const HandlersetstatusReason = event => {
    setstatusReason(event.target.value);
  }
  const getContentNewsData = async () => {
    try {
      setIsLoading(true);
      setContentNewsData(
        await getNews({ content_news_id: contentNewsId })
      );
    } catch (error) {
      setError({
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteNews = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      await deleteNews({
        content_news_id: contentNewsData?.list[0]._id,
        statusReason : statusReason
      });
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
      refreshLaporanData();
      toggle();
    }
  }
  
  
  useEffect(() => {
    getContentNewsData();
  }, []);

  return (
    <ModalBackdrop>
      <article className="w-[540px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Hapus Berita Pengguna
          </h2>
          <Button.Text onClick={toggle} className="ml-auto p-2">
            <span className="sr-only">Tutup</span>
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        {isLoading && (
          <div className="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
        {!isLoading && !isError ? (
          <>
            <div>
              <p className="mb-4 text-sm text-custom-secondary-text">
                Anda akan menghapus berita ini.{" "}
                <span className="font-bold text-custom-primary-text">
                  PERHATIAN: Berita yang sudah dihapus tidak dapat dikembalikan
                  lagi.
                </span>
              </p>
              <article className="mb-4 p-4 rounded-md bg-custom-background-lighter first:pt-0 flex flex-col gap-4">
                <div className="text-xs">
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">Judul Berita</p>
                    <p className="text-custom-primary-text">
                      {contentNewsData?.list[0].title ?? "-"}
                    </p>
                  </div>
                </div>
                <div className="text-xs">
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">
                      Pembuat Berita
                    </p>
                    <p className="text-custom-primary-text">
                      <UsernameWithAvatar
                        avatarPath={contentNewsData?.list[0].user.avatar}
                        username={
                          contentNewsData?.list[0].user.name ?? "-"
                        }
                      />
                    </p>
                  </div>
                </div>
              </article>
              <p className="mb-4 text-sm text-custom-secondary-text">
                Silahkan pilih alasan penghapusan berita ini. Alasan akan
                diberitahukan melalui notifikasi pengguna.
              </p>
              <div className="mb-2 flex flex-col gap-1">
              <TextField labelText="Alasan" value={statusReason} onChange={HandlersetstatusReason} />
              </div>
            </div>
            <footer className="grid grid-cols-2 gap-4">
              <Button.Secondary onClick={toggle}>
                Batal
              </Button.Secondary>
              <Button.Primary type="submit" onClick={handleDeleteNews}>
                Hapus Berita Pengguna
              </Button.Primary>
            </footer>
          </>
        ) : (
          <></>
        )}
        {isError ? <ErrorMessage error={error} /> : <></>}
      </article>
    </ModalBackdrop>
  );
}
export default KontenBeritaHapusModal;
