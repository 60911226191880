import GridLayout from "../../components/GridLayout";
import Icons from "../../components/Icons";
import Sidebar from "../../components/Sidebar";

function SuperadminManajemenWebsiteKategoriKeluhan(props) {
  return (
    <GridLayout>
      <Sidebar
        sidebarItems={[
          {
            isGroup: false,
            path: "/superadmin/manajemen-admin",
            icon: <Icons.Users className="w-4 h-4" />,
            title: "Manajemen Admin",
          },
          {
            isGroup: true,
            title: "Manajemen Website",
            icon: <Icons.Edit className="w-4 h-4" />,
            childrenItem: [
              {
                isGroup: false,
                path: "/superadmin/manajemen-website/kategori-keluhan",
                title: "Kategori Keluhan",
              },
              {
                isGroup: false,
                path: "/superadmin/manajemen-website/alasan-laporan",
                title: "Alasan Laporan",
              },
            ],
          },
        ]}
      />
    </GridLayout>
  );
}
export default SuperadminManajemenWebsiteKategoriKeluhan;
