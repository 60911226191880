import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Icons from "../../../components/Icons";
import Table, { Row } from "../../../components/Table";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import TextField from "../../../components/TextField";
import useError from "../../../hooks/useError";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { createJournalist, updateJournalist } from "../../../services/journalists";
import { uploadCompanyAvatar } from "../../../services/files";

function JurnalisBaruModal({ modalStatusChange, refreshJurnalisData, editData, isEdit }) {
  const [isLoading, setIsLoading] = useState();
  const { error, setError, isError } = useError();
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const toggle = () => {
    modalStatusChange();
  };

  const formik = useFormik({
    initialValues: {
      name: editData?.name || "",
      email: editData?.contact?.email || "",
      phoneNumber: editData?.contact?.phoneNumber || "",
      mediaName: editData?.media?.name || "",
      mediaEmail: editData?.media?.email || "",
      desk: editData?.desk || "",
      description: editData?.description || "",
      ratecard: editData?.ratecard || ""
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Nama jurnalis dibutuhkan"),
      email: Yup.string()
        .email("Format email jurnalis tidak benar")
        .required("Email jurnalis dibutuhkan"),
      phoneNumber: Yup.string()
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Format nomor telepon jurnalis tidak benar"
        )
        .required("Nomor telepon jurnalis dibutuhkan"),
    }),
    onSubmit: async (data) => {
      console.log(">>> data ", data);
      try {
        // if (files.length === 0)
        //   throw new Error("Foto profil jurnalis dibutuhkan");
        setError({ status: "ok" });
        setIsLoading(true);
        console.log(">>> isEdit ", isEdit);
        console.log(">>> data ", data);
        if (isEdit) {
          // Update existing journalist
          await updateJournalist(editData._id, {
            name: data.name,
            avatar: "/general/default_image_profile.svg",
            contact: {
              email: data.email,
              phoneNumber: data.phoneNumber
            },
            media: {
              name: data.mediaName,
              email: data.mediaEmail
            },
            desk: data.desk,
            description: data.description,
            ratecard: data.ratecard
          });
        } else {
          await createJournalist({
            name: data.name,
            avatar: "/general/default_image_profile.svg",
            contact: {
              email: data.email,
              phoneNumber: data.phoneNumber
            },
            media: {
              name: data.mediaName,
              email: data.mediaEmail
            },
            desk: data.desk,
            description: data.description,
            ratecard: data.ratecard
          });
        }
        toggle();
        refreshJurnalisData();
      } catch (error) {
        setError({
          code: error.response.status,
          message: error.response.data.message,
          status: "error",
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <ModalBackdrop>
      <article className="w-[865px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            {isEdit ? "Edit Jurnalis" : "Buat Jurnalis"}
          </h2>
          <Button.Text onClick={() => toggle()} className="ml-auto p-2">
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        <div className="flex flex-col gap-6">
          <h3 className="text-lg text-custom-primary-text font-bold flex items-center">
            Informasi Jurnalis
            <span className="ml-3 px-2 py-1 rounded-md bg-custom-background-lighter text-xs font-normal">
              <span className="text-red-500">*</span> Wajib diisi
            </span>
          </h3>
          <div className="grid grid-cols-3 gap-8 text-xs">
            <div className="flex flex-col gap-1">
              <p className="text-custom-secondary-text">
                Foto Profil Jurnalis{" "}
              </p>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <button className="w-full p-3 text-sm text-custom-primary font-bold hover:bg-gray-100 rounded-md inline-flex items-center gap-4">
                  {files[0] ? (
                    <img
                      src={files[0]?.preview}
                      alt=""
                      className="w-16 h-16 bg-gray-200 flex-shrink-0 rounded-full object-cover"
                    />
                  ) : (
                    <div className="w-16 h-16 bg-gray-200 flex-shrink-0 rounded-full stroke-custom-primary-text flex items-center justify-center">
                      <Icons.Company />
                    </div>
                  )}
                  Ganti Foto
                </button>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-8 text-xs">
            <div className="flex flex-col gap-1">
              <TextField
                type="text"
                name="name"
                labelText="Nama Jurnalis"
                placeholder="Masukkan nama..."
                onChange={formik.handleChange}
                isError={formik.errors.name}
                defaultValue={formik.values.name}
                required
              />
            </div>
            <div className="flex flex-col gap-1">
              <TextField
                type="email"
                name="email"
                labelText="Email Jurnalis"
                placeholder="Masukkan email..."
                onChange={formik.handleChange}
                isError={formik.errors.email}
                defaultValue={formik.values.email}
                required
              />
            </div>
            <div className="flex flex-col gap-1">
              <TextField
                labelText="Nomor Telepon Jurnalis"
                name="phoneNumber"
                placeholder="Masukkan nomor telepon..."
                onChange={formik.handleChange}
                defaultValue={formik.values.phoneNumber}
                isError={formik.errors.phoneNumber}
                required
              />
            </div>
            <div className="flex flex-col gap-1">
              <TextField
                labelText="Nama Media"
                name="mediaName"
                placeholder="Masukkan nama media..."
                onChange={formik.handleChange}
                defaultValue={formik.values.mediaName}
                isError={formik.errors.mediaName}
              />
            </div>
            <div className="flex flex-col gap-1">
              <TextField
                labelText="Email Media"
                name="mediaEmail"
                placeholder="Masukkan email media..."
                onChange={formik.handleChange}
                defaultValue={formik.values.mediaEmail}
                isError={formik.errors.mediaEmail}
              />
            </div>
            <div className="flex flex-col gap-1">
              <TextField
                labelText="Desk"
                name="desk"
                placeholder="Masukkan desk..."
                onChange={formik.handleChange}
                defaultValue={formik.values.desk}
                isError={formik.errors.desk}
              />
            </div>
            <div className="flex flex-col gap-1">
              <TextField
                labelText="Ratecard"
                name="ratecard"
                placeholder="Masukkan ratecard"
                onChange={formik.handleChange}
                defaultValue={formik.values.ratecard}
                isError={formik.errors.ratecard}
              />
            </div>
            <div className="flex flex-col gap-1">
              <TextField
                labelText="Catatan"
                name="description"
                placeholder="Masukkan catatan..."
                onChange={formik.handleChange}
                defaultValue={formik.values.description}
                isError={formik.errors.description}
              />
            </div>
          </div>
          <ul className="flex flex-col gap-1.5">
            {Object.entries(formik.errors)?.map((error, i) => (
              <li className="text-red-700 text-xs" key={i}>
                {error[1]}
              </li>
            ))}
            {isError && (
              <li className="text-red-700 text-xs">
                {error?.message ?? "Terjadi kesalahan"}
              </li>
            )}
          </ul>
        </div>
        <footer className="mt-6 py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
          {!isLoading ? (
            <>
              <Button.Secondary onClick={() => toggle()}>
                Batal
              </Button.Secondary>
              <Button.Primary type="submit" onClick={formik.handleSubmit}>
                {isEdit ? "Update Jurnalis" : "Buat Jurnalis"}
              </Button.Primary>
            </>
          ) : (
            <div className="w-full flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
        </footer>
      </article>
    </ModalBackdrop>
  );
}
export default JurnalisBaruModal;
