import { useEffect, useState } from "react";
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button";
import ErrorMessage from "../../components/ErrorMessage";
import GridLayout from "../../components/GridLayout";
import Icons from "../../components/Icons";
import LoadingSpinner from "../../components/LoadingSpinner";
import Main from "../../components/Main";
import MultiLineString from "../../components/MultiLineString";
import PageHeader from "../../components/PageHeader";
import Sidebar from "../../components/Sidebar";
import { getComplaint } from "../../services/complaintContent";
import useError from "../../hooks/useError";
import useReactiveSearchParams from "../../hooks/useReactiveSearchParams";
import ModerasiKontenKeluhanDetailCoverImage from "./components/ModerasiKontenKeluhanDetailCoverImage";
import ModerasiKontenKeluhanDetailPublikasiKeluhanModal from "./components/ModerasiKontenKeluhanDetailPublikasiKeluhanModal";
import ModerasiKontenTanggapanDetailTolakKeluhanModal from "./components/ModerasiKontenKeluhanDetailTolakKeluhanModal";
import ModerasiKontenKeluhanDetailCategoriesSelector from "./components/ModerasiKontenKeluhanDetailCategoriesSelector";
import ModerasiKontenKeluhanDetailDialog from "./components/ModerasiKontenKeluhanDetailDialog";
import ModerasiKontenKeluhanDetailAttachmentImage from "./components/ModerasiKontenKeluhanDetailAttachmentImage";
import Helmet from "react-helmet";

function ModerasiKontenKeluhanDetail(props) {
  const [keluhanData, setKeluhanData] = useState();
  const [keluhanIsLoading, setKeluhanIsLoading] = useState(false);
  const { error, setError, isError } = useError();
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const { keluhanId } = useParams();

  useEffect(() => {
    getKeluhanData(searchParams);
  }, [searchParams]);

  const getKeluhanData = async () => {
    try {
      setError({ status: "ok" });
      setKeluhanIsLoading(true);
      setKeluhanData(
        await getComplaint({
          content_complaint_id: keluhanId,
          ...searchParams,
        })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setKeluhanIsLoading(false);
    }
  };

  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>{keluhanData?.list[0]?.title ?? "Moderasi Konten Keluhan"} | Backoffice Intra Q Media</title>
      </Helmet>
      <GridLayout>
        <Sidebar />
        <Main>
          <PageHeader />
          <Button.Text
            icon={<Icons.ArrowLeft className="w-4 h-4 stroke-custom-primary" />}
            onClick={() => navigate("/moderasi-konten/keluhan")}
          >
            Kembali
          </Button.Text>
          {keluhanIsLoading && (
            <div className="w-full h-80 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
          {isError && <ErrorMessage error={error} />}
          {!keluhanIsLoading && !isError && (
            <section className="grid grid-cols-3 gap-4">
              <div className="col-span-2">
                <h1 className="mb-4 text-2xl text-custom-primary-text font-bold">
                  {keluhanData?.list[0]?.title}
                </h1>
                <p className="mb-4 text-sm text-custom-secondary-text">
                  Anda dapat mengubah kategori keluhan bila dirasa tidak sesuai
                  dan menambahkan gambar cover jika tidak tersedia. Isi konten
                  tidak dapat diubah.
                </p>
                <ModerasiKontenKeluhanDetailCategoriesSelector
                  initialSelectedCategoryId={
                    keluhanData?.list[0]?.contentCategory.content_category_id
                  }
                />
                <article className="mb-4">
                  <MultiLineString text={keluhanData?.list[0]?.body} />
                </article>
                <ModerasiKontenKeluhanDetailCoverImage
                  coverImage={keluhanData?.list[0]?.coverImage}
                />
                <ModerasiKontenKeluhanDetailAttachmentImage
                  keluhanData={keluhanData}
                />
              </div>
              <ModerasiKontenKeluhanDetailDialog keluhanData={keluhanData} />
            </section>
          )}
        </Main>
      </GridLayout>
      {!keluhanIsLoading && !isError && (
        <Routes>
          <Route
            path="publikasi"
            element={
              <ModerasiKontenKeluhanDetailPublikasiKeluhanModal
                refreshKeluhanData={getKeluhanData}
                keluhanData={keluhanData}
              />
            }
          />
          <Route
            path="tolak"
            element={
              <ModerasiKontenTanggapanDetailTolakKeluhanModal
                refreshKeluhanData={getKeluhanData}
                keluhanData={keluhanData}
              />
            }
          />
        </Routes>
      )}
    </>
  );
}
export default ModerasiKontenKeluhanDetail;
