function TextField({
  type = "text",
  name = "",
  placeholder = "",
  className = "",
  icon = <></>,
  labelText = "",
  required,
  isError,
  ...restOfProps
}) {
  return (
    <label className="inline-flex flex-col gap-1.5">
      {labelText && (
        <span className="text-xs text-custom-secondary-text">
          {labelText}
          {required ? <span className="ml-1 text-red-500 select-none">*</span> : <></>}
        </span>
      )}
      <div
        className={`pl-2.5 text-sm font-medium text-gray-900 bg-white rounded-lg border ${isError ? "border-red-500" : "border-gray-200" } inline-flex items-center gap-2 cursor-text overflow-hidden ${className}`}
      >
        {icon}
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          required={required}
          {...restOfProps}
          className="p-2.5 outline-none border-none focus:border-none disabled:text-gray-300"
        />
      </div>
    </label>
  );
}
export default TextField;
