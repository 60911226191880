import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import useError from "../../../hooks/useError";
import { getCategories } from "../../../services/categories";
import { changeComplaintCategory } from "../../../services/complaintContent";

function ModerasiKontenKeluhanDetailCategoriesSelector({
  initialSelectedCategoryId,
}) {
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    initialSelectedCategoryId
  );
  const [categoriesData, setCategoriesData] = useState();
  const [categoriesIsLoading, setCategoriesIsLoading] = useState(false);
  const { error, setError, isError } = useError();
  const { keluhanId } = useParams();

  const getCategoriesData = async () => {
    try {
      setError({ status: "ok" });
      setCategoriesIsLoading(true);
      setCategoriesData(await getCategories());
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setCategoriesIsLoading(false);
    }
  };

  const handleChangeComplaintCategory = async ({ content_category_id }) => {
    try {
      setError({ status: "ok" });
      setCategoriesIsLoading(true);
      setSelectedCategoryId(
        (
          await changeComplaintCategory({
            content_category_id,
            content_complaint_id: keluhanId,
          })
        ).contentCategory.content_category_id
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setCategoriesIsLoading(false);
    }
  };

  useEffect(() => {
    getCategoriesData();
  }, []);

  console.log(selectedCategoryId ?? initialSelectedCategoryId);

  if (!categoriesIsLoading) {
    return (
      <div className="flex flex-col items-stretch">
        <p className="mb-2 text-sm text-custom-secondary-text">Kategori</p>
        <select
          name="kategori"
          className="mb-4 bg-white border hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 inline-flex items-center justify-between"
          onChange={(event) =>
            handleChangeComplaintCategory({
              content_category_id: event.target.value,
            })
          }
        >
          <option>-- Pilih Salah Satu --</option>
          {categoriesData?.map((category) => (
            <option
              value={category._id}
              selected={
                category._id ===
                (selectedCategoryId ?? initialSelectedCategoryId)
              }
            >
              {category.name}
            </option>
          ))}
        </select>
      </div>
    );
  } else {
    return (
      <div className="mb-4 p-2 flex flex-col items-center">
        <LoadingSpinner />
      </div>
    );
  }
}
export default ModerasiKontenKeluhanDetailCategoriesSelector;
