import { useEffect, useState } from "react";
import { getFileUrl } from "../services/files";

/**
 * shows a username with an avatar image alongside it
 * @param {Object} param 
 * @param {string} param.avatarPath - path to image, not a URL to an image
 * @param {string} param.className
 * @param {string} param.username
 * @param {any} param.avatarPlaceholder - placeholder for the avatar image if avatarUrl is not provided
 */

function UsernameWithAvatar({
  avatarPath,
  avatarPlaceholder = <></>,
  username,
  className,
}) {
  const [avatarUrl, setAvatarUrl] = useState();
  const [isError, setIsError] = useState();

  const getAvatarUrl = async () => {
    try {
      setAvatarUrl(await getFileUrl({ filePath: avatarPath }));
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    getAvatarUrl();
  }, []);

  return (
    <div
      className={`text-custom-primary-text inline-flex items-center gap-3 ${className}`}
    >
      {avatarPath ? (
        avatarUrl ? (
          <img
            src={avatarUrl}
            alt=""
            className="w-6 h-6 flex-shrink-0 rounded-full object-cover"
          />
        ) : (
          <div className="w-6 h-6 flex-shrink-0 rounded-full bg-gray-200"></div>
        )
      ) : (
        avatarPlaceholder
      )}
      {username}
    </div>
  );
}
export default UsernameWithAvatar;
