import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Icons from "../../../components/Icons";
import ModalBackdrop from "../../../components/ModalBackdrop";
import MultiLineString from "../../../components/MultiLineString";
import TextField from "../../../components/TextField";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useError from "../../../hooks/useError";
import { getFeedback, rejectFeedback } from "../../../services/feedbackContent";

function ModerasiKontenTanggapanTolakTanggapanModal({ refreshTanggapanData, modalStatusChange, feedbackId, modalTolak }) {
  const [feedbackData, setFeedbackData] = useState();
  const [isLoading, setIsLoading] = useState();
  const { error, isError, setError } = useError();
  const [isOpened] = useState();
  const [statusReason, setstatusReason] = useState('');

  const toggle = () => {
    modalTolak(!isOpened)
    modalStatusChange(!isOpened)
  }

  const getFeedbackData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setFeedbackData(await getFeedback({ content_feedback_id: feedbackId }));
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const HandlerejectFeedback = () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      rejectFeedback({ content_feedback_id: feedbackId , statusReason: statusReason});
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
      refreshTanggapanData();
      toggle();
    }
  }
  const HandlersetstatusReason = event => {
    setstatusReason(event.target.value);
  }
  useEffect(() => {
    getFeedbackData();
  }, []);
  return (
    <ModalBackdrop>
      <article className="w-[540px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Publikasi Tanggapan
          </h2>
          <Button.Text onClick={toggle} className="ml-auto p-2">
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-custom-primary-text">
            Anda akan menolak tanggapan ini.
            <span className="font-bold">
              PERHATIAN: Tanggapan yang sudah ditolak tidak dapat dikembalikan
              lagi.
            </span>
          </p>
          <article className="p-4 rounded-md bg-custom-background-lighter first:pt-0 flex flex-col gap-4">
            <MultiLineString text={feedbackData?.list[0]?.body} />
            <div className="grid grid-cols-3 gap-2 text-xs">
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Pembuat Tanggapan</p>
                <UsernameWithAvatar
                  className="text-xs"
                  avatarPath={
                    feedbackData?.list[0]?.user.avatar ?? "-"
                  }
                  username={feedbackData?.list[0]?.user.name ?? "-"}
                />
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Perusahaan</p>
                <UsernameWithAvatar username={feedbackData?.list[0]?.user.company ?? "-"} />
              </div>
            </div>
          </article>
          <p className="text-sm text-custom-primary-text">
            Silahkan pilih alasan penolakan tanggapan ini. Alasan akan
            diberitahukan melalui notifikasi pengguna.
          </p>
          <div className="mb-2 flex flex-col gap-1">
            <TextField labelText="Alasan" required={true} value={statusReason} onChange={HandlersetstatusReason} />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Button.Secondary onClick={toggle}>
            Batal
          </Button.Secondary>
          <Button.Primary onClick={() => HandlerejectFeedback()}>Tolak Tanggapan</Button.Primary>
        </div>
      </article>
    </ModalBackdrop>
  );
}
export default ModerasiKontenTanggapanTolakTanggapanModal;
