import { Link } from "react-router-dom";
import moment from "moment";
import Table, { Row } from "../../../components/Table";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";
import { status } from "../../../constansts";

function PenggunaEnterpriseTable({ enterpriseUserData, modalStatusChange }) {
  const [searchParams] = useReactiveSearchParams();
  return (
    <Table
      totalPages={enterpriseUserData?.totalPage}
      currentPage={searchParams.page ?? 1}
      headingRow={[
        {
          name: "Nama PIC",
          unsortable: true,

        },
        {
          name: "Perusahaan",
          unsortable: true,

          // handleSort: () =>
          //   setSearchParams({
          //     ...searchParams,
          //     sortBy: "perusahaan",
          //     order: searchParams.order === "desc" ? "asc" : "desc",
          //   }),
          // currentSortMethod:
          //   searchParams.sortBy === "perusahaan"
          //     ? searchParams.order
          //     : "unsorted",
        },
        {
          name: "Email PIC",
          unsortable: true,

        },
        {
          name: "Waktu",
          unsortable: true,

        },
        {
          name: "Status",
          unsortable: true,

        },
        {
          name: "Aksi",
          unsortable: true,
        },
      ]}
    >
      {enterpriseUserData?.list?.map((user, i) => {
        return (
          <Row
            key={i}
            rowCells={[
              // Column for Nama PIC
              <UsernameWithAvatar
                avatarPath={user.avatar}
                username={user.name}
              />,
              // Column for Perusahaan
              <UsernameWithAvatar
                username={user.company.name}
              />,
              // Column for Email PIC
              user.email,
              // Column for Status
              moment(user.createdAt).format("DD-MM-YYYY, HH:mm"),
              <>
                <div className="inline-flex items-center gap-4">
                  {user.kyc.status === status.VERIFIED ?
                    <>
                      <span className="w-3 h-3 rounded-full bg-custom-green flex-shrink-0"></span>
                      <span className="text-sm text-custom-primary-text">
                        Terverifikasi
                      </span>
                    </>
                    : (user.kyc.status === status.UNVERIFIED ?
                      <>
                        <span className="w-3 h-3 rounded-full bg-gray-400 flex-shrink-0"></span>
                        <span className="text-sm text-custom-primary-text">
                          Belum Terverifikasi
                        </span>
                      </>
                      : (user.kyc.status === status.REJECTED ?
                        <>
                          <span className="w-3 h-3 rounded-full bg-blue-400 flex-shrink-0"></span>
                          <span className="text-sm text-custom-primary-text">
                            Ditolak
                          </span>
                        </>
                        : (user.kyc.status === status.SUBMITTED ?
                          <>
                            <span className="w-3 h-3 rounded-full bg-yellow-400 flex-shrink-0"></span>
                            <span className="text-sm text-custom-primary-text">
                              Submitted
                            </span>
                          </>
                          : (<></>)
                        )
                      )
                    )
                  }
                </div>
              </>,
              // Column for Aksi
              <>
                <div className="divide-x-2 divide-gray-400 font-semibold text-custom-primary">
                  <div className="px-2 first:pl-0 cursor-pointer hover:underline" onClick={() => modalStatusChange(user._id)}>
                    Lihat Detail
                  </div>
                  {user.kyc.status === "submitted" && (
                    <Link
                      to={`/verifikasi-data-diri/pengguna-enterprise/${user._id}/verifikasi`}
                      className="px-2 first:pl-0 hover:underline"
                    >
                      Verifikasi
                    </Link>
                  )}
                </div>
              </>,
            ]}
          />
        );
      })}{" "}
    </Table>
  );
}
export default PenggunaEnterpriseTable;
