import GridLayout from "../../components/GridLayout";
import Sidebar from "../../components/Sidebar";
import PageHeader from "../../components/PageHeader";
import PageTitle from "../../components/PageTitle";
import DashboardModerasiKontenKeluhan from "./components/DashboardModerasiKontenKeluhan";
import Main from "../../components/Main";
import Icons from "../../components/Icons";
import Hr from "../../components/Hr";
import DashboardModerasiKontenTanggapan from "./components/DashboardModerasiKontenTanggapan";
import DashboardVerifikasiPenggunaIndividu from "./components/DashboardVerifikasiPenggunaIndividu";
import DashboardVerifikasiPenggunaEnterprise from "./components/DashboardVerifikasiPenggunaEnterprise";
import DashboardLaporanKeluhan from "./components/DashboardLaporanKeluhan";
import DashboardLaporanKomentar from "./components/DashboardLaporanKomentar";
import Helmet from "react-helmet";

function Dashboard() {
  return (
    <>
      <Helmet>
        <title>Dashboard | Backoffice Intra Q Media</title>
      </Helmet>
      <GridLayout>
        <Sidebar />
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.Dashboard className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Dashboard"
          />
          <section className="grid grid-cols-2 gap-8">
            <DashboardModerasiKontenKeluhan />
            <DashboardModerasiKontenTanggapan />
          </section>
          <Hr className="my-8" />
          <section className="grid grid-cols-2 gap-8">
            <DashboardVerifikasiPenggunaIndividu />
            <DashboardVerifikasiPenggunaEnterprise />
          </section>
          <Hr className="my-8" />
          <section className="grid grid-cols-2 gap-8">
            <DashboardLaporanKeluhan />
            <DashboardLaporanKomentar />
          </section>
        </Main>
      </GridLayout>
    </>
  );
}
export default Dashboard;
