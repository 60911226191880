import GridLayout from "../../components/GridLayout";
import Icons from "../../components/Icons";
import Main from "../../components/Main";
import PageHeader from "../../components/PageHeader";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import ModerasiKontenTanggapanTable from "./components/ModerasiKontenTanggapanTable";
import ModerasiKontenTanggapanToolbar from "./components/ModerasiKontenTanggapanToolbar";
import ModerasiKontenTanggapanModerasiTanggapanModal from "./components/ModerasiKontenTanggapanModerasiTanggapanModal";
import { Route, Routes } from "react-router-dom";
import ModerasiKontenTanggapanPublikasiTanggapanModal from "./components/ModerasiKontenTanggapanPublikasiTanggapanModal";
import ModerasiKontenTanggapanTolakTanggapanModal from "./components/ModerasiKontenTanggapanTolakTanggapanModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import ErrorMessage from "../../components/ErrorMessage";
import useError from "../../hooks/useError";
import useReactiveSearchParams from "../../hooks/useReactiveSearchParams";
import { useEffect, useState } from "react";
import { getFeedback } from "../../services/feedbackContent";
import Helmet from "react-helmet";
import { status } from "../../constansts";

function ModerasiKontenTanggapan() {
  const [tanggapanData, setTanggapanData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { error, setError, isError } = useError();
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const [isDetailOpened, setIsDetailOpened] = useState();
  const [isPublikasiOpened, setIsPublikasiOpened] = useState();
  const [isTolakOpened, setIsTolakOpened] = useState();
  const [feedbackId, setfeedbackId] = useState();

  const toggle = (feedback) => {
    setIsDetailOpened(wasOpened => !wasOpened);
    setfeedbackId(feedback);
  }
  
  const toggletolak = (feedback) => {
    setIsDetailOpened(!isDetailOpened);
    setIsTolakOpened(wasOpened => !wasOpened);
    setfeedbackId(feedback);
  }
  const togglepublikasi = (feedback) => {
    setIsDetailOpened(!isDetailOpened );
    setIsPublikasiOpened(wasOpened => !wasOpened);
    setfeedbackId(feedback);
  }

  const getTanggapanData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setTanggapanData(
        await getFeedback({ size: 10, status: status.MODERATED, page: 1, ...searchParams })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTanggapanData();
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Moderasi Konten Tanggapan | Backoffice Intra Q Media</title>
      </Helmet>
      <GridLayout>
        <Sidebar />
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.Edit className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Moderasi Konten Tanggapan"
          />
          <ModerasiKontenTanggapanToolbar />
          {isLoading && (
            <div className="h-24 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
          {isError ? (
            error.code == 404 ? (
              <div className="p-24 flex items-center justify-center">
                <p className="flex gap-4 text-gray-300">
                  <span>
                    <Icons.Database className="stroke-gray-300" />
                  </span>
                  <span>Belum ada data</span>
                </p>
              </div>
            ) : (
              <ErrorMessage error={error} />
            )
          ) : (
            <></>
          )}
          {isError && error.code !== 404 && <ErrorMessage error={error} />}
          {!isLoading && !isError && (
            <ModerasiKontenTanggapanTable
              tanggapanData={tanggapanData} modalStatusChange={toggle}
            />
          )}
        </Main>
      </GridLayout>
      {!isLoading && isDetailOpened && (
        <ModerasiKontenTanggapanModerasiTanggapanModal
          refreshTanggapanData={getTanggapanData} modalStatusChange={toggle} feedbackId={feedbackId} modalTolak={toggletolak} modalPublikasi={togglepublikasi}
        />
      )};
      {!isLoading &&  isPublikasiOpened && !isTolakOpened && !isDetailOpened && (
        <ModerasiKontenTanggapanPublikasiTanggapanModal
        refreshTanggapanData={getTanggapanData} modalStatusChange={toggle} feedbackId={feedbackId} modalPublikasi={togglepublikasi}
      />
      )};
      {!isLoading && isTolakOpened && !isPublikasiOpened && !isDetailOpened && (
        <ModerasiKontenTanggapanTolakTanggapanModal
        refreshTanggapanData={getTanggapanData} modalStatusChange={toggle} feedbackId={feedbackId} modalTolak={toggletolak}
      />
      )};
    </>
  );
}
export default ModerasiKontenTanggapan;
