import Hr from "../../../components/Hr";
import TextField from "../../../components/TextField";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";
import { useState, useEffect } from "react";
import Button from "../../../components/Button";
import { usePopper } from "react-popper";
import Icons from "../../../components/Icons";
import { status } from "../../../constansts";

function PenggunaIndividuFilterPopup(props) {
  const [startDateFilterIsEnabled, setStartDateFilterIsEnabled] = useState();
  const [endDateFilterIsEnabled, setEndDateFilterIsEnabled] = useState();
  const [statusFilterIsEnabled, setStatusFilterIsEnabled] = useState();
  const [filterFormIsError, setFilterFormIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [setStatusFilter] = useState();
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const [filterPopupIsOpen, setFilterPopupIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  useEffect(() => {
    if (searchParams.startDate) {
      setStartDateFilterIsEnabled(true);
    }
    if (searchParams.endDate) {
      setEndDateFilterIsEnabled(true);
    }
    if (searchParams.status) {
      setStatusFilterIsEnabled(true);
    }
   
  }, [searchParams]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let newSearchParams = {};

    if (startDateFilterIsEnabled) {
      newSearchParams.startDate = event.target.startDate.value;
    }
    if (endDateFilterIsEnabled) {
      newSearchParams.endDate = event.target.endDate.value;
    }
    if (statusFilterIsEnabled) {
      newSearchParams.status = event.target.status.value;
    }
    if (
      startDateFilterIsEnabled &&
      endDateFilterIsEnabled &&
      event.target.startDate.value >= event.target.endDate.value
    ) {
      setErrorMessage("Tanggal mulai harus lebih kecil dari tanggal selesai");
      return setFilterFormIsError(true);
    }

    return setSearchParams(newSearchParams);
  };

  return (
    <>
      <div className="" ref={setReferenceElement}>
        {Object.entries(searchParams).length > 0 ? (
          <Button.Primary
            icon={<Icons.Filter className="w-4 h-4 stroke-white" />}
            label={Object.entries(searchParams).length}
            onClick={() => setFilterPopupIsOpen((x) => !x)}
          >
            Filter
          </Button.Primary>
        ) : (
          <Button.Secondary
            icon={
              <Icons.Filter className="w-4 h-4 stroke-custom-primary-text" />
            }
            onClick={() => setFilterPopupIsOpen((x) => !x)}
          >
            Filter
          </Button.Secondary>
        )}
      </div>
      {filterPopupIsOpen ? (
        <form
          className="w-56 rounded-lg bg-white border shadow p-4 mb-4 z-10 text-sm text-custom-primary-text flex flex-col gap-2"
          style={styles.popper}
          ref={setPopperElement}
          {...attributes.popper}
          onSubmit={(event) => handleSubmit(event)}
        >
            <div>
            <p className="font-bold mb-2">Status Pengguna</p>
            <input 
              type="checkbox" 
              name="statusFilterIsEnabled"
              checked={statusFilterIsEnabled}
              onClick={() => setStatusFilterIsEnabled((x) => !x)}
            />
            <select className="ml-1 "id="status" defaultValue="Select Status"
                    onChange={(e) => setStatusFilter(e.target.value)}
                    disabled={!statusFilterIsEnabled}
                    >        
              <option value={status.VERIFIED}>Terverifikasi</option>
              <option value={status.UNVERIFIED}>Belum Terverifikasi</option>
              <option value={status.SUBMITTED}>Submit</option>
              <option value={status.REJECTED}>Ditolak</option>
            </select>
          </div>
          
          <Hr />
          <p className="font-bold">Waktu</p>

          <label className="inline-flex items-center justify-start gap-2">
            <input
              type="checkbox"
              name="startDateFilterIsEnabled"
              checked={startDateFilterIsEnabled}
              onClick={() => setStartDateFilterIsEnabled((x) => !x)}
            />
            Dari
          </label>
          <TextField
            type="date"
            name="startDate"
            labelText="Dari Tanggal"
            defaultValue={searchParams.startDate}
            disabled={!startDateFilterIsEnabled}
          />
          <label className="inline-flex items-center justify-start gap-2">
            <input
              type="checkbox"
              name="endDateFilterIsEnabled"
              checked={endDateFilterIsEnabled}
              onClick={() => setEndDateFilterIsEnabled((x) => !x)}
            />
            Hingga
          </label>
          <TextField
            type="date"
            name="endDate"
            labelText="Hingga Tanggal"
            defaultValue={searchParams.endDate}
            disabled={!endDateFilterIsEnabled}
          />

          <Hr />

          {filterFormIsError ? (
            <p className="mb-2 text-red-600 text-xs">{errorMessage}</p>
          ) : (
            <></>
          )}

          <Button.Primary>Terapkan</Button.Primary>
          <Button.Text type="button" onClick={() => setSearchParams({})}>
            Hapus Filter
          </Button.Text>
        </form>
      ) : (
        <></>
      )}
    </>
  );
}

export default PenggunaIndividuFilterPopup;
