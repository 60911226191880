import { useState, useEffect } from "react";

/**
 * hooks for handling errors from Axios response
 * @param {object} initialState - initial state of API response
 * @param {Number} initialState.code - code of API response
 * @param {"ok"|"error"} initialState.status - status of API response
 * @param {String} initialState.data - contains error data of API response
 * @param {String} initialState.message - contains message data of API response
 * @returns 
 */

function useError(
  initialState = {
    code: 200,
    status: "ok",
    data: "",
    message: "",
  }
) {
  const [errorState, setErrorState] = useState(initialState);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(errorState.status.toLowerCase() !== "ok");
  }, [errorState]);

  const setError = (
    nextError = {
      code: 200,
      status: "ok",
      data: "",
      message: "",
    }
  ) => setErrorState((prevError) => ({ ...prevError, ...nextError }));

  return {
    error: errorState,
    setError,
    isError,
  };
}

export default useError;
