import axios from "axios";
import { API_ROUTES } from "../constansts";

/**
 * @typedef {object} JournalistResponse
 * @property {object} data
 * @property {Journalist[]} data.list
 * @property {number} data.totalData
 * @property {number} data.totalPage
 * @property {object} data.query
 * @property {string} data.query.withPics
 * @property {string} message
 * @property {number} code
 */

/**
 * @typedef {object} Journalist
 * @property {string} name
 * @property {string} description
 * @property {object} contact
 * @property {string} contact.email
 * @property {string} contact.phoneNumber
 * @property {string} contact._id
 * @property {string} avatar
 * @property {string} createdAt
 * @property {string} updatedAt
 */

/**
 * get journalist
 * @async
 * @param {Object} params
 * @param {boolean} [params.withPics=true]
 * @param {string} params.journalist_id
 * @param {number} [params.page=1]
 * @param {number} [params.size=25]
 * @returns {Promise<JournalistResponse>}
 */

async function getJournalist(params) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_JOURNALIST,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache"
      },
      params
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

/**
 * create journalist
 * @async
 * @param {object} params
 * @param {string} params.name
 * @param {string} params.avatar
 * @param {object} params.contact
 * @param {string} params.contact.email
 * @param {string} params.contact.phoneNumber
 * 
 * @typedef {object} CreateJournalistResponse
 * @property {boolean} success
 * @property {Journalist} data
 * @property {string} message
 * @property {number} code
 * 
 * @returns {Promise<CreateJournalistResponse>}
 */

async function createJournalist(data) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.CREATE_JOURNALIST,
      method: "post",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache"
      },
      data: data,
    });
    result = response.data;
  } catch (error) {
    return error;
  }

  return result;
}

/**
 * update journalist data 
 * @async
 * @param {object} params
 * @param {string} params.name
 * @param {string} params.avatar
 * @param {object} params.contact
 * @param {string} params.contact.email
 * @param {string} params.contact.phoneNumber
 * 
 * @typedef {object} UpdateJournalistResponse
 * @property {boolean} success
 * @property {Journalist} data
 * @property {string} message
 * @property {number} code
 * 
 * @returns {Promise<CreateJournalistResponse>}
 */

async function updateJournalist(id, data) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.UPDATE_JOURNALIST,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache"
      },
      params: {
        journalist_id: id
      },
      data: data
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function deleteJournalist({journalist_id, statusReason}) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.DELETE_JOURNALIST,
      method: "delete",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache"
      },
      params: {
        journalist_id,
      },
      data: {
        statusReason: statusReason,
      }
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

export { getJournalist, createJournalist, updateJournalist, deleteJournalist };
