import { Navigate, useRoutes } from "react-router-dom";
import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";
import SuperadminManajemenAdmin from "./screens/SuperadminManajemenAdmin";
import SuperadminManajemenWebsiteKategoriKeluhan from "./screens/SuperadminManajemenWebsiteKategoriKeluhan";
import SuperadminManajemenWebsiteAlasanLaporan from "./screens/SuperadminManajemenWebsiteAlasanLaporan";
import ModerasiKontenKeluhan from "./screens/ModerasiKontenKeluhan";
import ModerasiKontenTanggapan from "./screens/ModerasiKontenTanggapan";
import ModerasiKontenKeluhanDetail from "./screens/ModerasiKontenKeluhanDetail";
import VerifikasiDataDiriPenggunaIndividu from "./screens/VerifikasiDataDiriPenggunaIndividu";
import RequireAuth from "./components/RequireAuth";
import VerifikasiDataDiriPenggunaEnterprise from "./screens/VerifikasiDataDiriPenggunaEnterprise";
import PenggunaIndividu from "./screens/PenggunaIndividu";
import PenggunaEnterprise from "./screens/PenggunaEnterprise";
import Perusahaan from "./screens/Perusahaan";
import LaporanKeluhan from "./screens/LaporanKeluhan";
import LaporanPetisi from "./screens/LaporanPetisi";
import LaporanKomentar from "./screens/LaporanKomentar";
import KontenKeluhan from "./screens/KontenKeluhan";
import KontenTanggapan from "./screens/KontenTanggapan";
import KontenKomentar from "./screens/KontenKomentar";
import KontenPetisi from "./screens/KontenPetisi";
import ModerasiKontenPetisi from "./screens/ModerasiKontenPetisi";
import Admin from "./screens/admin";
import KontenBerita from "./screens/KontenBerita";
import Jurnalis from "./screens/Jurnalis";


function AppRoutes() {
  let element = useRoutes([
    {
      path: "/",
      element: <Navigate to="/jurnalis" />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/superadmin/manajemen-admin",
      element: (
        <RequireAuth
          path="/superadmin/manajemen-admin"
          element={<SuperadminManajemenAdmin />}
        />
      ),
    },
    {
      path: "/superadmin/manajemen-website/kategori-keluhan",
      element: <SuperadminManajemenWebsiteKategoriKeluhan />,
    },
    {
      path: "/superadmin/manajemen-website/alasan-laporan",
      element: <SuperadminManajemenWebsiteAlasanLaporan />,
    },
    {
      path: "/dashboard",
      element: <RequireAuth path="/dashboard" element={<Dashboard />} />,
    },
    {
      path: "/moderasi-konten/keluhan",
      element: (
        <RequireAuth
          path="/moderasi-konten/keluhan"
          element={<ModerasiKontenKeluhan />}
        />
      ),
    },
    {
      path: "/moderasi-konten/keluhan/:keluhanId/*",
      element: (
        <RequireAuth
          path="/moderasi-konten/keluhan/:keluhanId"
          element={<ModerasiKontenKeluhanDetail />}
        />
      ),
    },
    {
      path: "/moderasi-konten/tanggapan/*",
      element: (
        <RequireAuth
          path="/moderasi-konten/tanggapan"
          element={<ModerasiKontenTanggapan />}
        />
      ),
    },
    {
      path: "/moderasi-konten/petisi",
      element: (
        <RequireAuth
          path="/moderasi-konten/petisi"
          element={<ModerasiKontenPetisi />}
        />
      ),
    },
    {
      path: "/verifikasi-data-diri/pengguna-individu/*",
      element: (
        <RequireAuth
          path="/verifikasi-data-diri/pengguna-individu"
          element={<VerifikasiDataDiriPenggunaIndividu />}
        />
      ),
    },
    {
      path: "/verifikasi-data-diri/pengguna-enterprise/*",
      element: (
        <RequireAuth
          path="/verifikasi-data-diri/pengguna-enterprise"
          element={<VerifikasiDataDiriPenggunaEnterprise />}
        />
      ),
    },
    {
      path: "/laporan/keluhan/*",
      element: (
        <RequireAuth path="/laporan/keluhan" element={<LaporanKeluhan />} />
      ),
    },
    {
      path: "/laporan/komentar/*",
      element: (
        <RequireAuth path="/laporan/komentar" element={<LaporanKomentar />} />
      ),
    },
    {
      path: "/laporan/petisi/*",
      element: (
        <RequireAuth path="/laporan/petisi" element={<LaporanPetisi />} />
      ),
    },
    {
      path: "/konten/keluhan/*",
      element: (
        <RequireAuth path="/konten/keluhan/" element={<KontenKeluhan />} />
      ),
    },
    {
      path: "/konten/komentar/*",
      element: (
        <RequireAuth path="/konten/komentar/" element={<KontenKomentar />} />
      ),
    },
    {
      path: "/konten/petisi/*",
      element: (
        <RequireAuth path="/konten/petisi/" element={<KontenPetisi />} />
      ),
    },
    {
      path: "/konten/tanggapan/*",
      element: (
        <RequireAuth path="/konten/tanggapan" element={<KontenTanggapan />} />
      ),
    },
    {
      path: "/pengguna/individu/*",
      element: (
        <RequireAuth path="/pengguna/individu" element={<PenggunaIndividu />} />
      ),
    },
    {
      path: "/pengguna/enterprise/*",
      element: (
        <RequireAuth
          path="/pengguna/enterprise"
          element={<PenggunaEnterprise />}
        />
      ),
    },
    {
      path: "/jurnalis/*",
      element: (
        <>
          <RequireAuth path="/jurnalis" element={<Jurnalis />} />
        </>
      ),
    },
    {
      path: "/perusahaan/*",
      element: (
        <>
          <RequireAuth path="/perusahaan" element={<Perusahaan />} />
        </>
      ),
    },
    {
      path: "/admin/*",
      element: (
        <>
          <RequireAuth path="/admin" element={<Admin />} />
        </>
      ),
    },{
      path: "/berita/*",
      element: (
        <>
          <RequireAuth path="/berita" element={<KontenBerita />} />
        </>
      ),
    },
  
  ]);

  return element;
}

export default AppRoutes;
