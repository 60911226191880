import moment from "moment";
import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import ErrorMessage from "../../../components/ErrorMessage";
import Icons from "../../../components/Icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import MultiLineString from "../../../components/MultiLineString";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import { status } from "../../../constansts";
import useError from "../../../hooks/useError";
import { getJournalist } from "../../../services/journalists";
import { useFormik } from "formik";

function JurnalisDetailModal({ refreshJurnalisData, modalStatusChange, modalHapus, jurnalisId }) {
  const [isLoading, setIsLoading] = useState();
  const [journalistData, setJournalistData] = useState();
  const { error, isError, setError } = useError();
  const [isOpened] = useState();

  function toggle() {
    modalStatusChange(!isOpened);
  }
  const getJournalistData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setJournalistData(
        (await getJournalist({ journalist_id: jurnalisId }))
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);

    }
  };
  
  useEffect(() => {
    getJournalistData();
  }, []);
  
  return (
    <ModalBackdrop>
      <article className="w-[865px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Detail Jurnalis
          </h2>
          <Button.Text onClick={toggle} className="ml-auto p-2">
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        {!isLoading && !isError && (
          <div className="flex flex-col gap-6">

            <h3 className="text-lg text-custom-primary-text font-bold">
              Informasi Jurnalis
            </h3>
            <div className="grid grid-cols-4">
              <div className="flex flex-col gap-6 col-span-3">
                <div className="grid grid-cols-3 gap-2 text-xs">
                  <div className="flex flex-col gap-1 hidden">
                    <p className="text-custom-secondary-text">ID</p>
                    <p className="text-custom-primary-text line-clamp-1">
                      {journalistData?.list[0]._id}
                    </p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">
                      Nama Jurnalis
                    </p>
                    <UsernameWithAvatar username={journalistData?.list[0].name} avatarPath={journalistData?.list[0].avatar} />
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">
                      Email Jurnalis
                    </p>
                    <p className="text-custom-primary-text line-clamp-1">
                      {journalistData?.list[0].contact?.email ?? "-"}
                    </p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">
                      Nomor Telp Jurnalis
                    </p>
                    <p className="text-custom-primary-text line-clamp-1">
                      {journalistData?.list[0].contact?.phoneNumber ?? "-"}
                    </p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">
                      Nama Media
                    </p>
                    <p className="text-custom-primary-text line-clamp-1">
                      {journalistData?.list[0].media?.name ?? "-"}
                    </p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">
                      Email Media
                    </p>
                    <p className="text-custom-primary-text line-clamp-1">
                      {journalistData?.list[0].media?.email ?? "-"}
                    </p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">Pembuatan</p>
                    <p className="text-custom-primary-text line-clamp-1">
                      {moment(journalistData?.list[0].createdAt).format("YYYY-MM-DD, HH:MM")}

                    </p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">
                      Terakhir Diubah
                    </p>
                    <p className="text-custom-primary-text line-clamp-1">
                      {moment(journalistData?.list[0].updatedAt).format("YYYY-MM-DD, HH:MM")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2">
              <div className="flex flex-col gap-1 text-xs">
                <p className="text-custom-secondary-text">
                  Ratecard
                </p>
                <p className="text-custom-primary-text line-clamp-1">
                  {journalistData?.list[0].ratecard ?? "0"}
                </p>
              </div>
              <div className="flex flex-col gap-1 text-xs">
                <p className="text-custom-secondary-text">Catatan Tentang Jurnalis</p>
                <div className="text-custom-primary-text">
                  <MultiLineString text={journalistData?.list[0].description ?? "-"} />
                </div>
              </div>
            </div>
            <hr />
            <div className="grid">
              <div className="flex flex-col gap-5 text-xs">
                <h3 className="text-lg text-custom-primary-text font-bold">
                  Rekam Jejak Jurnalis
                </h3>
                <div className="flex flex-col">
                {journalistData?.list[0].history ? (
                  journalistData?.list[0].history.map((item, i) => {
                    return (
                      <div className="mb-5">
                        <div className="flex gap-5">
                          <p className="text-custom-secondary-text">
                            Nama Media
                          </p>
                          <p className="text-custom-primary-text line-clamp-1">
                            {item.media.name} 
                          </p>
                        </div>
                        <div className="flex gap-5">
                          <p className="text-custom-secondary-text">
                            Update pada
                          </p>
                          <p className="text-custom-primary-text line-clamp-1">
                            {item.media.updatedAt}
                          </p>
                        </div>
                      </div>
                    )
                  })
                ) : <></>}
                </div>
              </div>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
        {isError && (
          error.code === 404 ? (
            <div className="p-24 flex items-center justify-center">
              <div className="flex gap-4 text-gray-300">
                <span>
                  <Icons.Database className="stroke-gray-300" />
                </span>
                <span>Data Tidak Ditemukan</span>
              </div>
            </div>,
            <footer className="py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
              <Button.Primary onClick={toggle}>
                Kembali
              </Button.Primary>
            </footer>
          ) : (
            <ErrorMessage error={error} />
          )
        )
        }
        {!isLoading && !isError && (
          <footer className="mt-6 py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
            <Button.Primary onClick={toggle}>
              Kembali
            </Button.Primary>
            <Button.Secondary onClick={() => modalHapus(journalistData.list[0]._id)}>Hapus Jurnalis</Button.Secondary>
          </footer>
        )}
      </article>
    </ModalBackdrop>
  );
}
export default JurnalisDetailModal;
