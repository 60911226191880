import Table, { Row } from "../../../components/Table";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";

function AdminTable({ AdminData, modalStatusChange}) {
  const [searchParams, ] = useReactiveSearchParams();
  return (
    <Table
      totalPages={AdminData?.totalPage}
      currentPage={searchParams.page ?? 1}
      headingRow={[
        {
          name: "Nama",
          unsortable: false,
        },
        {
          name: "Username",
          unsortable: false,

        },
        {
          name: "Aksi",
          unsortable: false,
        },
      ]}
    >
      {AdminData?.list?.map((admin, i) => {
        return (
          <Row
            key={i}
            rowCells={[
              <UsernameWithAvatar
                avatarPath={admin.avatar}
                username={admin.name}
              />,
              admin.username,
              <>
                <div className="divide-x-2 divide-gray-400 font-semibold text-custom-primary">
                  <div className="px-2 first:pl-0 cursor-pointer hover:underline" onClick={() => modalStatusChange(admin._id)}>
                      Lihat Detail
                  </div>
                </div>
              </>
            ]}
          />
         
        );
      })}


    </Table>

  );
}
export default AdminTable;
