import Hr from "./Hr";

function PageTitle({ icon = <></>, title = "" }) {
  return (
    <div className="py-4">
      <h1 className="inline-flex items-center gap-4 text-custom-primary text-4xl font-bold">
        {icon && <span className="w-8 h-8">
          {icon}
        </span>}
        <span>{title}</span>
      </h1>
      <Hr />
    </div>
  );
}
export default PageTitle;
