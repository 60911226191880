import axios from "axios";
import { API_ROUTES, status } from "../constansts";

async function getNeedVerification(params) {
  let result = [];

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_NEED_VERIFICATION,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache",

      },
      params,
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function setStatusVerified({ user_id }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.SET_USER_STATUS,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      data: {
        status: "verified",
        statusReason: "Data diri sudah sesuai",
      },
      params: { user_id },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function setStatusRejected({ user_id, statusReason }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.SET_USER_STATUS,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      data: {
        status: status.REJECTED,
        statusReason,
      },
      params: { user_id },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}

export { getNeedVerification, setStatusVerified, setStatusRejected };
