import React from "react";
import { ReactComponent as Dashboard } from "../assets/icons/dashboard.svg";
import { ReactComponent as Edit } from "../assets/icons/edit.svg";
import { ReactComponent as IdCard } from "../assets/icons/id-card.svg";
import { ReactComponent as Report } from "../assets/icons/report.svg";
import { ReactComponent as Send } from "../assets/icons/send.svg";
import { ReactComponent as Users } from "../assets/icons/users.svg";
import { ReactComponent as Company } from "../assets/icons/company.svg";
import { ReactComponent as Database } from "../assets/icons/database.svg";
import { ReactComponent as Filter } from "../assets/icons/filter.svg";
import { ReactComponent as Search } from "../assets/icons/search.svg";
import { ReactComponent as Sort } from "../assets/icons/sort.svg";
import { ReactComponent as ArrowLeft } from "../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowDropDown } from "../assets/icons/arrow-dropdown.svg";
import { ReactComponent as Trash } from "../assets/icons/trash.svg";
import { ReactComponent as NewTab } from "../assets/icons/new-tab.svg";
import { ReactComponent as X } from "../assets/icons/x.svg";
import { ReactComponent as ChevronDown } from "../assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUp } from "../assets/icons/chevron-up.svg";
import { ReactComponent as Plus } from "../assets/icons/plus.svg";
import { ReactComponent as Key } from "../assets/icons/key.svg";
import { ReactComponent as LogOut } from "../assets/icons/log-out.svg";
import { ReactComponent as Eye } from "../assets/icons/eye.svg";
import { ReactComponent as Eye_off } from "../assets/icons/eye-off.svg";

class Icons extends React.Component {
  static Company = (props) => <Company {...props} />
  static Dashboard = (props) => <Dashboard {...props} />
  static Edit = (props) => <Edit {...props} />
  static IdCard = (props) => <IdCard {...props} />
  static Report = (props) => <Report {...props} />
  static Send = (props) => <Send {...props} />
  static Users = (props) => <Users {...props} />
  static Database = (props) => <Database {...props} />
  static Filter = (props) => <Filter {...props} />
  static Search = (props) => <Search {...props} />
  static Eye = (props) => <Eye {...props} />
  static Eye_off = (props) => <Eye_off {...props} />
  static Sort = (props) => <Sort {...props} />
  static ArrowLeft = (props) => <ArrowLeft {...props} />
  static ArrowDropDown = (props) => <ArrowDropDown {...props} />
  static Trash = (props) => <Trash {...props} />
  static NewTab = (props) => <NewTab {...props} />
  static X = (props) => <X {...props} />
  static ChevronDown = (props) => <ChevronDown {...props} />
  static ChevronUp = (props) => <ChevronUp {...props} />
  static Plus = (props) => <Plus {...props} />
  static Key = (props) => <Key {...props} />
  static LogOut = (props) => <LogOut {...props} />
  
  render() { 
    return (<></>);
  }
}
 
export default Icons;