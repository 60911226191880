import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import ErrorMessage from "../../../components/ErrorMessage";
import Icons from "../../../components/Icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useError from "../../../hooks/useError";
import { getFeedback, deleteFeedback } from "../../../services/feedbackContent";

function KontenTanggapanHapusModal({refreshLaporanData, modalStatusChange, contentFeedbackId, modalHapus}) {
  const [isLoading, setIsLoading] = useState();
  const { error, isError, setError } = useError();
  const [feedbackContentData, setfeedbackContentData] = useState();
  const toggle = () => {
    modalStatusChange()
    modalHapus()
  }

  const getContentFeedbackData = async () => {
    try {
      setIsLoading(true);
      setfeedbackContentData(
        await getFeedback({ content_feedback_id: contentFeedbackId })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteFeedback = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      await deleteFeedback({
        content_feedback_id: feedbackContentData?.list[0]._id,
      });
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
      refreshLaporanData();
      toggle();
    }
  };

  useEffect(() => {
    getContentFeedbackData();
  }, []);

  return (
    <ModalBackdrop>
      <article className="w-[540px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Hapus Tanggapan
          </h2>
          <Button.Text onClick={toggle} className="ml-auto p-2">
            <span className="sr-only">Tutup</span>
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        {isLoading && (
          <div className="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
        {!isLoading && !isError ? (
          <>
            <div>
              <p className="mb-4 text-sm text-custom-secondary-text">
                Anda akan menghapus Tanggapan ini.{" "}
                <span className="font-bold text-custom-primary-text">
                  PERHATIAN: Tanggapan yang sudah dihapus tidak dapat
                  dikembalikan lagi.
                </span>
              </p>
              <article className="mb-4 p-4 rounded-md bg-custom-background-lighter first:pt-0 flex flex-col gap-4">
                <div className="text-xs"> 
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">Isi Tanggapan</p>
                    <p className="text-custom-primary-text">
                      {feedbackContentData?.list[0]?.body ?? "-"}
                    </p>
                  </div>
                </div>
                <div className="text-xs">
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">
                      Keluhan Yang Ditanggapi
                    </p>
                    <p className="text-xs text-custom-primary-text">
                    {`${feedbackContentData?.list[0].contentComplaint.title} (ID: ${feedbackContentData?.list[0].contentComplaint.content_complaint_id})`}
                  </p>
                  </div>
                </div>
              </article>
              <p className="mb-4 text-sm text-custom-secondary-text">
                Silahkan pilih alasan penghapusan Tanggapan ini. Alasan akan
                diberitahukan melalui notifikasi pengguna.
              </p>
              {/* TODO: field alasan penghapusan keluhan */}
            </div>
            <footer className="grid grid-cols-2 gap-4">
              <Button.Secondary onClick={toggle}>
                Batal
              </Button.Secondary>
              <Button.Primary type="submit" onClick={handleDeleteFeedback}>
                Hapus Tanggapan 
              </Button.Primary>
            </footer>
          </>
        ) : (
          <></>
        )}
        {isError ? <ErrorMessage error={error} /> : <></>}
      </article>
    </ModalBackdrop>
  );
}
export default KontenTanggapanHapusModal;
