import { Link, useNavigate } from "react-router-dom";
import Box from "../../../components/Box";
import Icons from "../../../components/Icons";
import { useEffect, useState } from "react";
import useError from "../../../hooks/useError";
import { getReportedContent, getReportedContentByType } from "../../../services/reportedContent";
import ErrorMessage from "../../../components/ErrorMessage";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { getComplaint } from "../../../services/complaintContent";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import { status } from "../../../constansts";

function DashboardLaporanKeluhan(props) {
  const [isLoading, setIsLoading] = useState();
  const [complaintReportData, setComplaintReportData] = useState();
  const { error, isError, setError } = useError();
  const navigate = useNavigate();

  const getComplaintReportData = async () => {
    try {
      setIsLoading(true);
      setComplaintReportData(await getReportedContentByType({ contentType: "complaint" , status : status.UNVERIFIED}));
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getComplaintReportData();
  }, []);
  return (
    <article className="">
      <header className="mb-2">
        <h2 className="inline-flex items-center gap-4 text-custom-primary-text text-xl font-bold">
          <span className="w-6 h-6">
            <Icons.Report className="w-6 h-6 stroke-custom-secondary-text" />
          </span>
          {true && (
            <div className="w-0 -m-2">
              <div className="w-3 h-3 rounded-full bg-blue-500 relative right-4 bottom-2"></div>
            </div>
          )}
          <span>Laporan Keluhan</span>
        </h2>
      </header>
      <Box className="h-72 flex flex-col gap-4 divide-y overflow-y-auto overflow-x-hidden">
        {isLoading && (
          <div className="pt-16 flex items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
        {!isLoading &&
          !isError &&
          complaintReportData?.list?.length >= 1 &&
          complaintReportData?.list?.map((complaintReport, i) => (
            <article className="pt-4 first:pt-0 flex flex-col gap-4" key={i}>
              <p className="text-custom-primary-text text-sm">
                {complaintReport.reportedContent.contentComplaint.title}
              </p>
              <div className="grid grid-cols-3 gap-2 text-xs">
                <div className="flex flex-col gap-1">
                  <p className="text-custom-secondary-text">Pengirim</p>
                  <UsernameWithAvatar
                    username={complaintReport.user.name}
                    avatarPath={complaintReport.user.avatar}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-custom-secondary-text">Alasan</p>
                  {complaintReport.reason}
                </div>
                <div className="justify-self-end ">
                  {/* <div className="text-custom-primary cursor-pointer hover:underline" onClick={() => navigate('/laporan/keluhan', { state: { isDetailOpened: true, contentReportId: complaintReport._id }})}>
                    Lihat Detail
                  </div> */}
                  <Link
                    to={`/laporan/keluhan/${complaintReport._id}/detail`}
                    className="text-custom-primary hover:underline"
                  >
                    Lihat Detail
                  </Link>
                </div>
              </div>
            </article>
          ))}
        {complaintReportData?.list?.length < 1 ||
          (error.code === 404 && (
            <div className="h-full flex items-center justify-center">
              <p className="flex gap-4 text-gray-300">
                <span>
                  <Icons.Database className="stroke-gray-300" />
                </span>
                <span>Belum ada data</span>
              </p>
            </div>
          ))}
        {isError && error.code !== 404 && <ErrorMessage error={error} />}
      </Box>
    </article>
  );
}
export default DashboardLaporanKeluhan;
