import GridLayout from "../../components/GridLayout";
import Sidebar from "../../components/Sidebar";
import PageHeader from "../../components/PageHeader";
import PageTitle from "../../components/PageTitle";
import Main from "../../components/Main";
import Icons from "../../components/Icons";
import SuperadminManajemenAdminToolbar from "./components/SuperadminManajemenAdminToolbar";
import SuperadminManajemenAdminTable from "./components/SuperadminManajemenAdminTable";
import { useEffect, useState } from "react";
import { getAdminList } from "../../services/admin";
import useReactiveSearchParams from "../../hooks/useReactiveSearchParams";
import useError from "../../hooks/useError";
import LoadingSpinner from "../../components/LoadingSpinner";
import ErrorMessage from "../../components/ErrorMessage";

function SuperadminManajemenAdmin(props) {
  const [adminListData, setAdminListData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { error, setError, isError } = useError();
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  useEffect(() => {
    getAdminListData();
  }, []);

  const getAdminListData = async () => {
    try {
      setIsLoading(true);
      setAdminListData(
        await getAdminList({
          page: searchParams.page ?? 1,
          size: searchParams.size ?? 10,
        })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <GridLayout>
      <Sidebar
        sidebarItems={[
          {
            isGroup: false,
            path: "/superadmin/manajemen-admin",
            icon: <Icons.Users className="w-4 h-4" />,
            title: "Manajemen Admin",
          },
          {
            isGroup: true,
            title: "Manajemen Website",
            icon: <Icons.Edit className="w-4 h-4" />,
            childrenItem: [
              {
                isGroup: false,
                path: "/superadmin/manajemen-website/kategori-keluhan",
                title: "Kategori Keluhan",
              },
              {
                isGroup: false,
                path: "/superadmin/manajemen-website/alasan-laporan",
                title: "Alasan Laporan",
              },
            ],
          },
        ]}
      />
      {isLoading && (
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.Users className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Manajemen Admin"
          />
          <div className="h-24 flex items-center justify-center">
            <LoadingSpinner />
          </div>
        </Main>
      )}
      {!isLoading && !isError && (
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.Users className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Manajemen Admin"
          />
          <SuperadminManajemenAdminToolbar />
          <SuperadminManajemenAdminTable adminListData={adminListData} />
        </Main>
      )}
      {isError && (
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.Users className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Manajemen Admin"
          />
          <ErrorMessage error={error} />
        </Main>
      )}
    </GridLayout>
  );
}
export default SuperadminManajemenAdmin;
