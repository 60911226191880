import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

function RequireAuth({ element = <></>, path }) {
  const { isAuth, authIsLoading } = useContext(AuthContext);
  const redirectLocation = new URL(
    path,
    window.location.origin
  ).toString();

  return !authIsLoading ? (
    isAuth ? (
      element
    ) : (
      <Navigate replace to={`/login`} />
    )
  ) : (
    <>
      <div className="w-full h-screen flex items-center justify-center">
        <p className="text-custom-primary-text">Sedang Memuat...</p>
      </div>
    </>
  );
}
export default RequireAuth;
