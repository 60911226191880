import Icons from "../../../components/Icons";
import { useEffect, useState } from "react";
import { getFileUrl } from "../../../services/files";
import { useDropzone } from "react-dropzone";

function ModerasiKontenKeluhanDetailCoverImage({ coverImage }) {
  const [coverImageUrl, setCoverImageUrl] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isError, setIsError] = useState();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => console.log(files),
  });

  const getCoverImageUrl = async () => {
    try {
      setIsLoading(true);
      setCoverImageUrl(await getFileUrl({ filePath: coverImage }));
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCoverImageUrl();
  }, []);

  return (
    <div className="mb-4 flex flex-col gap-4">
      <h2 className="text-2xl text-custom-primary-text font-bold">
        Foto Cover
      </h2>
      <div className="flex gap-4 flex-wrap">
        {Boolean(coverImage) ? (
          // If cover image is available
          !isLoading ? (
            <div
              className="w-48 h-48 p-4 flex-shrink-0 bg-cover rounded-md flex items-stretch"
              style={{
                background: `url(${coverImageUrl})`,
                backgroundSize: "cover",
              }}
            >
              {/* <div className="w-full opacity-0 hover:opacity-100">
                <button className="p-2 bg-black hover:bg-gray-700 rounded-full">
                  <Icons.Trash className="w-3 h-3 stroke-white" />
                </button>
              </div> */}
            </div>
          ) : (
            <div className="w-48 h-48 p-4 flex-shrink-0 bg-gray-100 rounded-md flex items-stretch"></div>
          )
        ) : (
          // If cover image is not available
          <div
            {...getRootProps({
              className:
                "w-48 h-48 flex-shrink-0 text-custom-secondary-text text-sm flex items-center justify-center object-cover rounded-md border-2 border-dashed hover:border-gray-500",
            })}
          >
            <input {...getInputProps()} />
            <p>Upload</p>
          </div>
        )}
      </div>
    </div>
  );
}
export default ModerasiKontenKeluhanDetailCoverImage;
