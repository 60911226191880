import moment from "moment";
import { Link } from "react-router-dom";
import Table, { Row } from "../../../components/Table";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";

function ModerasiKontenTanggapanTable({ tanggapanData , modalStatusChange}) {
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  return (
    <Table
      totalPages={tanggapanData.totalPage}
      currentPage={searchParams.page ?? 1}
      headingRow={[
        {
          name: "Pembuat Tanggapan",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "pembuat-tanggapan",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "pembuat-tanggapan"
              ? searchParams.order
              : "unsorted",
        },
        {
          name: "Perusahaan",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "perusahaan",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "perusahaan"
              ? searchParams.order
              : "unsorted",
        },
        {
          name: "Tanggapan",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "tanggapan",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "tanggapan" ? searchParams.order : "unsorted",
        },
        {
          name: "Waktu",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "waktu",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "waktu" ? searchParams.order : "unsorted",
        },
        {
          name: "Aksi",
          unsortable: true,
        },
      ]}
    >
      {tanggapanData?.list?.map((tanggapan, i) => (
        <Row
          key={i}
          rowCells={[
            <UsernameWithAvatar
              avatarPath={tanggapan.user?.avatar ?? "-"}
              username={tanggapan.user?.name ?? "-"}
            />,
            <UsernameWithAvatar username = {tanggapan.user?.company ?? "-"}/>,
            tanggapan.body,
            moment(tanggapan.createdAt).format("DD-MM-YYYY, HH:mm"),
            <>
              <div className="divide-x-2 divide-gray-400 font-semibold text-custom-primary">
              <div className="px-2 first:pl-0 cursor-pointer hover:underline" onClick={() => modalStatusChange(tanggapan._id)}>
                      Moderasi
                  </div>
              </div>
            </>,
          ]}
        />
      ))}
    </Table>
  );
}
export default ModerasiKontenTanggapanTable;
