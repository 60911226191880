import React from "react";

class ButtonGroup extends React.Component {
  static Item = ({ className, children, isActive, ...restOfProps }) => (
    <button
      className={`py-2 px-4 text-sm ${
        isActive ? "text-custom-primary font-bold" : "text-gray-900"
      } bg-white first:rounded-l-lg last:rounded-r-lg border border-gray-200 hover:bg-gray-100 hover:text-custom-primary focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-custom-primary ${className}`}
      {...restOfProps}
    >
      {children}
    </button>
  );

  render() {
    return (
      <div
        className={`inline-flex rounded-md ${this.props.className}`}
        {...this.props}
        role="group"
      >
        {this.props.children}
      </div>
    );
  }
}

export default ButtonGroup;
