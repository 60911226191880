import axios from "axios";
import { API_ROUTES } from "../constansts";

async function getFileUrl({ filePath }) {
  let fileUrl = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_FILE_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      params: {
        filePath,
      },
    });
    fileUrl = response.data.data.fileUrl;
  } catch (error) {
    throw error;
  }

  return fileUrl;
}

/** 
 * @typedef {object} UploadCompanyAvatarResponse
 * @property {boolean} success
 * @property {object} data
 * @property {string} data.filePath
 * @property {string} message
 * @property {number} code
 */

/** 
 * upload an avatar for a specific company
 * @async
 * @param {object} data
 * @param {File} data.file 
 * @param {string} data.company_id 
 * @returns {Promise<UploadCompanyAvatarResponse>}
 */
async function uploadCompanyAvatar({ file, company_id }) {
  let result = {};
  let data = new FormData();

  data.append("company_id", company_id);
  data.append("fileName", "avatar");
  data.append("fileLocation", file);

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.UPLOAD_COMPANY_AVATAR,
      method: "post",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      data,
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}
async function uploadSubscriptionProof({ file, company_id }) {
  let result = {};
  let data = new FormData();

  data.append("company_id", company_id);
  data.append("fileName", "payment_proof_" + Date.now());
  data.append("fileLocation", file);

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.UPLOAD_COMPANY_AVATAR,
      method: "post",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      data,
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}
async function uploadCoverNews({ file, content_news_id }) {
  let result = {};
  let data = new FormData();

  data.append("content_news_id", content_news_id);
  data.append("fileName", "cover");
  data.append("fileLocation", file);

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.UPLOAD_NEWS_IMAGE,
      method: "post",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      data,
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}
async function uploadImageCover({ file,user_id, title }) {
  let result = {};
  let data = new FormData();
  data.append("user_id", user_id)
  data.append("title", title);
  data.append("fileName", "cover");
  data.append("fileLocation", file);

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.UPLOAD_NEWS_IMAGE,
      method: "post",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      data,
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function uploadAttachmentImage({ file,user_id, title }) {
  let result = {};
  let data = new FormData();
  data.append("user_id", user_id)
  data.append("title", title);
  data.append("fileName", "FeatureImage");
  data.append("fileLocation", file);

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.UPLOAD_NEWS_IMAGE,
      method: "post",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      data,
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}

export { getFileUrl, uploadCompanyAvatar, uploadImageCover,uploadCoverNews,uploadSubscriptionProof,uploadAttachmentImage };
