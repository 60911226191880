import _ from "lodash";
import Button from "../../../components/Button";
import ButtonGroup from "../../../components/ButtonGroup";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";
import Icons from "../../../components/Icons";
import TextField from "../../../components/TextField";
import LaporanKeluhanFilterPopup from "./LaporanKeluhanFilterPopup";
import { useState } from "react";
import { usePopper } from "react-popper";

function LaporanKeluhanToolbar() {
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });
  const [filterPopupIsOpen, setFilterPopupIsOpen] = useState(false);

  return (
    <>
      <div className="py-2 flex items-center gap-4">
        <ButtonGroup className="mr-auto">
          <ButtonGroup.Item
            onClick={() => setSearchParams({ ...searchParams, size: 10 })}
            isActive={
              // Defaults to 10
              searchParams.size
                ? Number.parseInt(searchParams.size) === 10
                : true
            }
          >
            View 10
          </ButtonGroup.Item>
          <ButtonGroup.Item
            onClick={() => setSearchParams({ ...searchParams, page:1, size: 20 })}
            isActive={Number.parseInt(searchParams.size) === 20}
          >
            View 20
          </ButtonGroup.Item>
          <ButtonGroup.Item
            onClick={() => setSearchParams({ ...searchParams, page:1, size: 30 })}
            isActive={Number.parseInt(searchParams.size) === 30}
          >
            View 30
          </ButtonGroup.Item>
        </ButtonGroup>
        <LaporanKeluhanFilterPopup />
        <TextField
          placeholder="Cari berdasarkan alasan pembuatan laporan"
          onKeyPress={(event) => {
            if (event.key == "Enter") {
              setSearchParams(
                event.target.value !== ""
                  ? { ...searchParams, reason: event.target.value }
                  : { ..._.omit(searchParams, ["reason"]) }
              );
            }
          }}
          defaultValue={searchParams.reason}
          icon={<Icons.Search className="w-4 h-4 stroke-custom-primary-text" />}
        />
      </div>
    </>
  );
}
export default LaporanKeluhanToolbar;
