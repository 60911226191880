import Button from "../../../components/Button";
import Icons from "../../../components/Icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ErrorMessage from "../../../components/ErrorMessage";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import MultiLineString from "../../../components/MultiLineString";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useError from "../../../hooks/useError";
import { getPetition, setPetitionPublish } from "../../../services/petitionContent";

function ModerasiKontenPetisiPublikasiModal({refreshPetisiData ,modalStatusChange,PetitionId, modalPublikasi}) {
  const [petitionData, setPetitionData] = useState();
  const [isLoading, setIsLoading] = useState();
  const {setError } = useError();
  const [isOpened] = useState();

  const navigate = useNavigate();
  const toggle = () =>{
    modalPublikasi(!isOpened)
    modalStatusChange(!isOpened)
  }
  const getPetitionData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setPetitionData(await getPetition({ content_petition_id: PetitionId }));
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  const HandlepublishPetition = () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setPetitionPublish({ content_petition_id: PetitionId });
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
      refreshPetisiData();
      toggle();
    }
  }

  useEffect(() => {
    getPetitionData();
  }, []);

  return (
    <ModalBackdrop>
      <article className="w-[540px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Publikasi Petisi
          </h2>
          <Button.Text onClick={toggle} className="ml-auto p-2">
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        <div>
          <p className="mb-4 text-sm text-custom-primary-text">
            Apakah Anda yakin ingin mempublikasikan Petisi ini?
          </p>
          <article className="p-4 rounded-md bg-custom-background-lighter first:pt-0 flex flex-col gap-4">
          <MultiLineString text={petitionData?.list[0]?.body} />
            <div className="grid grid-cols-3 gap-2 text-xs">
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Pembuat Petisi</p>
                <UsernameWithAvatar
                    className="text-xs"
                    avatarPath={
                      petitionData?.list[0]?.user.avatar ?? "-"
                    }
                    username={petitionData?.list[0]?.user.name ?? "-"}
                  />              
                  </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Perusahaan</p>
                <UsernameWithAvatar username = {petitionData?.list[0]?.institution ?? "-"}/>
              </div>
            </div>
          </article>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Button.Secondary onClick={toggle}>
            Batal
          </Button.Secondary>
          <Button.Primary onClick={() => HandlepublishPetition()}>
          Publikasi</Button.Primary>
        </div>
      </article>
    </ModalBackdrop>
  );
}
export default ModerasiKontenPetisiPublikasiModal;
