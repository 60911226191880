import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Icons from "../../../components/Icons";
import ModalBackdrop from "../../../components/ModalBackdrop";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useError from "../../../hooks/useError";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";
import { getFeedback } from "../../../services/feedbackContent";
import MultiLineString from "../../../components/MultiLineString";
import LoadingSpinner from "../../../components/LoadingSpinner";
import moment from "moment";

function KontenTanggapanDetailModal({modalStatusChange, modalHapus, contentFeedbackId}) {
  const [isLoading, setIsLoading] = useState();
  const { error, isError, setError } = useError();
  const [feedbackContentData, setfeedbackContentData] = useState();
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  const [isOpened] = useState();

  const toggle =() =>{
    modalStatusChange(!isOpened)
  }

  const getFeedbackContentData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setfeedbackContentData(
        await getFeedback({ content_feedback_id : contentFeedbackId, page: 1, size: 10, ...searchParams })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFeedbackContentData();
  }, []);

  return (
    <ModalBackdrop>
      <article className="w-[865px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Detail Konten Tanggapan
          </h2>
          {isLoading ? (
            <></>
          ) : (
            <a
              href={`https://dev.intramedia.site/keluhan/${feedbackContentData?.list[0].contentComplaint.slug ?? ""}`}
              target="_blank"
              className="p-2 rounded-md hover:bg-gray-100 inline-flex"
            >
              Buka di Intra Q Media
              <Icons.NewTab className="w-6 h-6 stroke-custom-primary" />
            </a>
          )}
          <Button.Text onClick={toggle} className="ml-auto p-2">  
            <span className="sr-only">Tutup</span>
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        {!isLoading && !isError && (
          <>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <p className="mb-1.5 text-xs text-custom-secondary-text">
                  Isi Tanggapan
                </p>
                <article className="text-sm text-custom-primary-text">
                  <MultiLineString text={feedbackContentData?.list[0]?.body} />
                </article>
              </div>
              <div className="flex flex-col gap-4">
                <div className="">
                  <p className="mb-1.5 text-xs text-custom-secondary-text">
                    Keluhan yang ditanggapi
                  </p>
                  <p className="text-xs text-custom-primary-text">
                    {`${feedbackContentData?.list[0].contentComplaint.slug} (ID: ${feedbackContentData?.list[0].contentComplaint.content_complaint_id})`}
                  </p>
                </div>
                <div className="">
                  <p className="mb-1.5 text-xs text-custom-secondary-text">
                    Pembuat Tanggapan
                  </p>
                  <UsernameWithAvatar
                    avatarPath={feedbackContentData?.list[0].user?.avatar ?? "-"}
                    username={feedbackContentData?.list[0].user?.name ?? "-"}
                  />
                </div>
                <div className="">
                  <p className="mb-1.5 text-xs text-custom-secondary-text">
                    Perusahaan
                  </p>
                  <UsernameWithAvatar username= {feedbackContentData?.list[0].user?.company ?? "-"}/>
                </div>
                <div className="">
                  <p className="mb-1.5 text-xs text-custom-secondary-text">
                    Waktu Pembuatan
                  </p>
                  <p className="text-xs text-custom-primary-text">
                    {moment(feedbackContentData?.list[0]?.createdAt).format(
                      "DD-MM-YYYY, HH:MM"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
        {isLoading && (
          <div className="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
        {!isLoading && !isError && (
          <footer className="mt-6 py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
            <Button.Primary
              onClick={() => modalHapus(feedbackContentData?.list[0].content_feedback_id)}
            >
              Hapus Tanggapan
            </Button.Primary>
            <Button.Secondary onClick={toggle}>
              Kembali
            </Button.Secondary>
          </footer>
        )}
      </article>
    </ModalBackdrop>
  );
}
export default KontenTanggapanDetailModal;
