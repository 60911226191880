import GridLayout from "../../components/GridLayout";
import Icons from "../../components/Icons";
import Main from "../../components/Main";
import PageHeader from "../../components/PageHeader";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import ModerasiKontenKeluhanToolbar from "./components/ModerasiKontenKeluhanToolbar";
import ModerasiKontenKeluhanTable from "./components/ModerasiKontenKeluhanTable";
import useError from "../../hooks/useError";
import { useEffect, useState } from "react";
import useReactiveSearchParams from "../../hooks/useReactiveSearchParams";
import { getComplaint } from "../../services/complaintContent";
import LoadingSpinner from "../../components/LoadingSpinner";
import ErrorMessage from "../../components/ErrorMessage";
import Helmet from "react-helmet";
import { status } from "../../constansts";

function ModerasiKontenKeluhan(props) {
  const [keluhanData, setKeluhanData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { error, setError, isError } = useError();
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  useEffect(() => {
    getKeluhanData(searchParams);
  }, [searchParams]);

  const getKeluhanData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setKeluhanData(
        await getComplaint({ size: 10, status: status.MODERATED, ...searchParams })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Moderasi Konten Keluhan | Backoffice Intra Q Media</title>
      </Helmet>
      <GridLayout>
        <Sidebar />
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.Edit className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Moderasi Konten Keluhan"
          />
          <ModerasiKontenKeluhanToolbar />
          {isLoading && (
            <div className="h-24 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
          {isError && <ErrorMessage error={error} />}
          {!isLoading && !isError && (
            <ModerasiKontenKeluhanTable keluhanData={keluhanData} />
          )}
        </Main>
      </GridLayout>
    </>
  );
}
export default ModerasiKontenKeluhan;
