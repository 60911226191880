import { Link } from "react-router-dom";
import Box from "../../../components/Box";
import Icons from "../../../components/Icons";
import { useEffect, useState } from "react";
import useError from "../../../hooks/useError";

import { getReportedContent,getReportedContentByType } from "../../../services/reportedContent";
import ErrorMessage from "../../../components/ErrorMessage";
import LoadingSpinner from "../../../components/LoadingSpinner";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import { status } from "../../../constansts";

function DashboardLaporanKomentar(props) {
  const [isLoading, setIsLoading] = useState();
  const [reportedContentData, setReportedContentData] = useState();
  const { error, isError, setError } = useError();
  const getReportedContentData = async () => {
    try {
      setIsLoading(true);
      setReportedContentData( await getReportedContentByType({ contentType: "comment", status : status.UNVERIFIED}));
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getReportedContentData();
  }, []);

  return (
    <article className="">
      <header className="mb-2">
        <h2 className="inline-flex items-center gap-4 text-custom-primary-text text-xl font-bold">
          <span className="w-6 h-6">
            <Icons.Report className="w-6 h-6 stroke-custom-secondary-text" />
          </span>
          {true && (
            <div className="w-0 -m-2">
              <div className="w-3 h-3 rounded-full bg-blue-500 relative right-4 bottom-2"></div>
            </div>
          )}
          <span>Laporan Komentar</span>
        </h2>
      </header>
      <Box className="h-72 flex flex-col gap-4 divide-y overflow-y-auto overflow-x-hidden">
      {isLoading && (
          <div className="pt-16 flex items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
        {!isLoading &&
          !isError &&
          reportedContentData?.list?.length >= 1 &&
          reportedContentData?.list?.map((reportContent, i) => (
            <article className="pt-4 first:pt-0 flex flex-col gap-4" key={i}>
              <p className="text-custom-primary-text text-sm">
              {reportContent.reportedContent.contentComment.message}
              </p>
              <div className="grid grid-cols-3 gap-2 text-xs">
                <div className="flex flex-col gap-1">
                  <p className="text-custom-secondary-text">Pengirim</p>
                  <UsernameWithAvatar
                    username={reportContent.user.name}
                    avatarPath={reportContent.user.avatar}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-custom-secondary-text">Alasan</p>
                {reportContent.reason}
                </div>
                <div className="justify-self-end ">
                  <Link
                    to={`/laporan/komentar/${reportContent._id}/detail`}
                    className="text-custom-primary hover:underline"
                  >
                    Lihat Detail
                  </Link>
                </div>
              </div>
            </article>
          ))}
     {reportedContentData?.list?.length < 1 ||
          (error.code === 404 && (
            <div className="h-full flex items-center justify-center">
              <p className="flex gap-4 text-gray-300">
                <span>
                  <Icons.Database className="stroke-gray-300" />
                </span>
                <span>Belum ada data</span>
              </p>
            </div>
          ))}
        {isError && error.code !== 404 && <ErrorMessage error={error} />}
      </Box>
    </article>
  );
}
export default DashboardLaporanKomentar;
