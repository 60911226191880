import { useState } from "react";
import GridLayout from "../../components/GridLayout";
import Icons from "../../components/Icons";
import Main from "../../components/Main";
import PageHeader from "../../components/PageHeader";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import SortableList from "../../components/SortableList";

function SuperadminManajemenWebsiteAlasanLaporan() {
  const [items, setItems] = useState([]);

  return (
    <GridLayout>
      <Sidebar
        sidebarItems={[
          {
            isGroup: false,
            path: "/superadmin/manajemen-admin",
            icon: <Icons.Users className="w-4 h-4" />,
            title: "Manajemen Admin",
          },
          {
            isGroup: true,
            title: "Manajemen Website",
            icon: <Icons.Edit className="w-4 h-4" />,
            childrenItem: [
              {
                isGroup: false,
                path: "/superadmin/manajemen-website/kategori-keluhan",
                title: "Kategori Keluhan",
              },
              {
                isGroup: false,
                path: "/superadmin/manajemen-website/alasan-laporan",
                title: "Alasan Laporan",
              },
            ],
          },
        ]}
      />
      <Main>
        <PageHeader />
        <PageTitle
          icon={<Icons.Edit className="w-8 h-8 stroke-custom-secondary-text" />}
          title="Manajemen Alasan Laporan"
        />
        <SortableList  />
      </Main>
    </GridLayout>
  );
}
export default SuperadminManajemenWebsiteAlasanLaporan;
