import { NavLink } from "react-router-dom";

export default function SidebarItem({ path = "/", title = "", icon = <></> }) {
  return (
    <NavLink
    to={path}
      className={({ isActive }) =>
        `w-full px-2 py-3 ${
          isActive
            ? "bg-custom-primary hover:bg-custom-primary text-white fill-white stroke-white"
            : "bg-transparent hover:bg-custom-primary text-custom-white-text stroke-custom-white-text"
        } text-sm text-left inline-flex items-center justify-start gap-4 leading-none`
      }
    >
      <div className="w-4 h-4">{icon}</div>
      {/* TODO: Integrate notification from API */}
      {false && <div className="w-0 -m-2">
        <div className="w-2 h-2 rounded-full bg-blue-500 relative right-2.5 bottom-1.5 animate-ping"></div>
      </div>}
      <span className="flex-shrink">{title}</span>
    </NavLink>
  );
}
