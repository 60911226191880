import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Icons from "../../../components/Icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useError from "../../../hooks/useError";
import { getReportedContent, setReportVerified, setReportRejected, DeleteReport } from "../../../services/reportedContent";
import moment from "moment";
import { status } from "../../../constansts";

function LaporanPetisiDetailModal({ refreshLaporanData, modalStatusChange, modalHapus, contentReportId }) {
  const { error, isError, setError } = useError();
  const [isLoading, setIsLoading] = useState();
  const [petitionReportsData, setPetitionReportData] = useState();

  const [isOpened] = useState();

  const toggle = () => {
    modalStatusChange(!isOpened)
  }

  const getPetitionReportData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setPetitionReportData(
        await getReportedContent({  content_report_id: contentReportId  })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleSetReportVerified = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      await setReportVerified({  content_report_id: contentReportId  });
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
      refreshLaporanData();
      toggle();
    }
  };
  const handleSetReportRejected = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      await setReportRejected({  content_report_id: contentReportId  });
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
      refreshLaporanData();
      toggle();
    }
  };

  useEffect(() => {
    getPetitionReportData();
  }, []);

  return (
    <ModalBackdrop>
      <article className="w-[865px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Detail Laporan Keluhan
          </h2>
          <a
            href="https://intramedia.site"
            target="_blank"
            className="p-2 rounded-md hover:bg-gray-100 inline-flex"
          >
            Buka di Intra Q Media
            <Icons.NewTab className="w-6 h-6 stroke-custom-primary" />
          </a>
          <Button.Text onClick={toggle} className="ml-auto p-2">
            <span className="sr-only">Tutup</span>
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        {!isLoading && !isError && (
          <>
            {/* Row 1 */}
            <div className="grid grid-cols-4 gap-2 text-xs">
              <div className="flex flex-col gap-1 col-span-2">
                <p className="text-custom-secondary-text">Judul Petisi</p>
                <p className="text-custom-primary-text line-clamp-1">
                  {petitionReportsData?.list[0].reportedContent?.contentPetition.title?? "-"}
                </p>
              </div>
              <div className="flex flex-col gap-1 col-span-1">
                <p className="text-custom-secondary-text">ID</p>
                <p className="text-custom-primary-text">
                  {petitionReportsData?.list[0]._id ?? "-"}
                </p>
              </div>
              <div className="flex flex-col gap-1 col-span-1">
                <p className="text-custom-secondary-text">Kategori</p>
                <p className="text-custom-primary-text">{petitionReportsData?.list[0].reportedContent?.contentPetition.category ?? "-"}</p>
              </div>
            </div>

            {/* Row 2 */}
            <div className="grid grid-cols-4 gap-2 text-xs">
              <div className="flex flex-col gap-1 col-span-1">
                <p className="text-custom-secondary-text">Pembuat Petisi</p>
                <p className="text-custom-primary-text">
                <UsernameWithAvatar
                    avatarPath={
                        petitionReportsData?.list[0].reportedContent?.user.avatar ?? '-'
                    }
                    username={
                        petitionReportsData?.list[0].reportedContent?.user.name ?? '-'
                    }
                  />
                </p>
              </div>
              <div className="flex flex-col gap-1 col-span-1">
                <p className="text-custom-secondary-text">Waktu Laporan</p>
                <p className="text-custom-primary-text">
                  {moment(petitionReportsData?.list[0].createdAt).format(
                    "DD-MM-YYYY, HH:MM"
                  )}
                </p>
              </div>
              <div className="flex flex-col gap-1 col-span-1">
                <p className="text-custom-secondary-text">Alasan</p>
                <p className="text-custom-primary-text">
                  {petitionReportsData?.list[0].reason ?? "-"}
                </p>
              </div>
              <div className="flex flex-col gap-1 col-span-1">
                <p className="text-custom-secondary-text">Dilaporkan Oleh</p>
                <p className="text-custom-primary-text">
                  <p className="text-custom-primary-text">
                  <UsernameWithAvatar
                    avatarPath={petitionReportsData?.list[0].user.avatar}
                    username={petitionReportsData?.list[0].user.name}
                  />
                </p>
                </p>
              </div>
            </div>
          </>
        )}
        {isLoading && (
          <div className="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
        {!isLoading && !isError && petitionReportsData?.list[0].status !== status.UNVERIFIED && (
          <footer className="mt-6 py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
            <Button.Primary
              onClick={() => modalHapus(petitionReportsData?.list[0]._id)}
            >
              Hapus Laporan Petisi
            </Button.Primary>
            <Button.Secondary onClick={toggle}>
              Kembali
            </Button.Secondary>
          </footer>
        )}
        {!isLoading && !isError && petitionReportsData?.list[0].status === status.UNVERIFIED && (
          <footer className="mt-6 py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
            <Button.Text className={'bg-custom-green btn-terima'}
              onClick={handleSetReportVerified}>
              Terima Laporan Petisi
            </Button.Text>
            <Button.Text className={'bg-custom-red'} onClick={handleSetReportRejected}>
              Tolak Laporan Petisi
            </Button.Text>
            <Button.Secondary onClick={toggle}>
              Kembali
            </Button.Secondary>
          </footer>
        )}
      </article>
    </ModalBackdrop>
  );
}
export default LaporanPetisiDetailModal;
