import axios from "axios";
import { API_ROUTES } from "../constansts";

async function getCategories(params) {
  let result;

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_CATEGORIES,
      method: "get",
      auth: {
        username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
        password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

export { getCategories };
