import axios from "axios";
import { API_ROUTES, status } from "../constansts";

async function getComplaintPopular(params) {
  let result = [];

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_COMPLAINT_POPULAR,
      method: "get",
      auth: {
        username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
        password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
      },
      headers: {
        "Content-Type": "application/json",
      },
      params,
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function getComplaint(params) {
  let result = [];

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_COMPLAINT,
      method: "get",
      auth: {
        username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
        password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
      },
      headers: {
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache",

      },
      params,
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function changeComplaintCategory({
  content_complaint_id,
  content_category_id,
}) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.CHANGE_COMPLAINT_CATEGORY,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      data: { content_category_id },
      params: {
        content_complaint_id,
      },
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function setComplaintPublish({ content_complaint_id }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.SET_COMPLAINT_PUBLISH,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      params: {
        content_complaint_id,
      },
      data: {
        status: status.PUBLISHED,
        statusReason: "Keluhan telah disetujui",
      },
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function setComplaintReject({ content_complaint_id, statusReason}) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.SET_COMPLAINT_REJECT,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      params: {
        content_complaint_id,
      },
      data: {
        status: status.REJECTED,
        statusReason: statusReason,
      },
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

/**
 * delete complaint
 * @async
 * @param {*} params
 * @param {string} params.content_complaint_id
 * @returns
 */
async function deleteComplaint({ content_complaint_id, statusReason }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.DELETE_COMPLAINT,
      method: "delete",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      params: {
        content_complaint_id,
      },
      data: {
        statusReason: statusReason,
      }
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

export {
  getComplaintPopular,
  getComplaint,
  changeComplaintCategory,
  setComplaintPublish,
  setComplaintReject,
  deleteComplaint,
};
