import moment from "moment";
import { Link } from "react-router-dom";
import Table, { Row } from "../../../components/Table";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import { status } from "../../../constansts";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";

function LaporanKeluhanTable({ complaintReportsData , modalStatusChange}) {
  const [searchParams] = useReactiveSearchParams();

  return (
    <Table
      totalPages={complaintReportsData?.totalPage}
      currentPage={searchParams.page ?? 1}
      headingRow={[
        {
          name: "Pembuat Laporan",
          unsortable: true,
        },
        {
          name: "Judul Keluhan",
          unsortable: true,
        },
        {
          name: "Alasan",
          unsortable: true,
        },
        {
          name: "Waktu",
          unsortable: true,
        },
        {
          name: "Status",
          unsortable: true,
        },
        {
          name: "Aksi",
          unsortable: true,
        },
      ]}
    >
      {complaintReportsData?.list?.map((complaintReport, i) => (
        <Row
          key={i}
          rowCells={[
            <UsernameWithAvatar
              username={complaintReport.user.name}
              avatarPath={complaintReport.user.avatar}
            />,
            complaintReport?.reportedContent?.contentComplaint.title,
            complaintReport?.reason,
            moment(complaintReport?.createdAt).format("MM-DD-YYYY, HH:mm"),
            <>
             <div className="inline-flex items-center gap-4">
                  {complaintReport?.status === status.VERIFIED && (
                    <>
                      <span className="w-3 h-3 rounded-full bg-custom-green flex-shrink-0"></span>
                      <span className="text-sm text-custom-primary-text">
                        Disetujui
                      </span>
                    </>
                  ) }
                   {complaintReport?.status === status.UNVERIFIED && (
                    <>
                      <span className="w-3 h-3 rounded-full bg-yellow-400 flex-shrink-0"></span>
                      <span className="text-sm text-custom-primary-text">
                        Moderasi
                      </span>
                    </>
                  )}{complaintReport?.status === status.REJECTED && (
                    <>
                      <span className="w-3 h-3 rounded-full bg-custom-red flex-shrink-0"></span>
                      <span className="text-sm text-custom-primary-text">
                        Ditolak
                      </span>
                    </>
                  )}
                </div>
              </>,
            <>
              <div className="divide-x-2 divide-gray-400 font-semibold text-custom-primary">
              <div className="px-2 first:pl-0 cursor-pointer hover:underline" onClick={() => modalStatusChange(complaintReport._id)}>
                      Lihat Detail
                  </div>
              </div>
            </>,
          ]}
        />
      ))}
    </Table>
  );
}
export default LaporanKeluhanTable;
