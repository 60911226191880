import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Icons from "../../../components/Icons";
import ModalBackdrop from "../../../components/ModalBackdrop";
import TextField from "../../../components/TextField";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useError from "../../../hooks/useError";
import { setComplaintReject } from "../../../services/complaintContent";

function ModerasiKontenTanggapanTolakKeluhanModal({
  keluhanData,
  refreshKeluhanData,
}) {
  const [isLoading, setIsLoading] = useState();
  const { error, setError, isError } = useError();
  const navigate = useNavigate();
  const { keluhanId } = useParams();
  const [statusReason, setstatusReason] = useState('');

  const HandlersetstatusReason = event => {
    setstatusReason(event.target.value);
  }
  
  const formik = useFormik({
    initialValues: {
      statusReason: statusReason,
    },
    onSubmit: async () => {
      try {
        setError({ status: "ok" });
        setIsLoading(true);
        await setComplaintReject({ content_complaint_id: keluhanId , statusReason : statusReason});
      } catch (error) {
        setError({
          code: error.response.status,
          message: error.response.data.message,
          status: "error",
        });
      } finally {
        setIsLoading(false);
        refreshKeluhanData();
        navigate("/moderasi-konten/keluhan");
      }
    },
  });


  return (
    <ModalBackdrop>
      <form onSubmit={formik.handleSubmit} className="w-[540px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Tolak Keluhan
          </h2>
          <Button.Text onClick={() => navigate("..")} className="ml-auto p-2">
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-custom-primary-text">
            Anda akan menolak keluhan ini.{" "}
            <span className="font-bold">
              PERHATIAN: Keluhan yang sudah ditolak tidak dapat dikembalikan
              lagi.
            </span>
          </p>
          <article className="p-4 rounded-md bg-custom-background-lighter first:pt-0 flex flex-col gap-4">
            <p className="text-custom-primary-text text-sm">
              {keluhanData?.list[0]?.title}
            </p>
            <div className="grid grid-cols-1 gap-2 text-xs">
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Pembuat Tanggapan</p>
                <UsernameWithAvatar
                  username={keluhanData?.list[0]?.user?.name}
                  avatarPath={keluhanData?.list[0]?.user?.avatar}
                />
              </div>
            </div>
          </article>
          <p className="text-sm text-custom-primary-text">
            Silahkan pilih alasan penolakan tanggapan ini. Alasan akan
            diberitahukan melalui notifikasi pengguna.
          </p>
          <TextField labelText="Alasan" required={true} value={statusReason} onChange={HandlersetstatusReason} />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Button.Secondary type="button" onClick={() => navigate("../")}>
            Batal
          </Button.Secondary>
          <Button.Primary type="submit">
            Tolak Tanggapan
          </Button.Primary>
        </div>
      </form>
    </ModalBackdrop>
  );
}
export default ModerasiKontenTanggapanTolakKeluhanModal;
