import { useFormik } from "formik";
import _ from "lodash";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Icons from "../../../components/Icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import TextField from "../../../components/TextField";
import useError from "../../../hooks/useError";
import { useFiles } from "../../../hooks/useFiles";
import { setStatusVerified } from "../../../services/kyc";
import { updateProfile } from "../../../services/usersDataProfile";

function VerifikasiDataDiriPenggunaIndividuVerifikasiModal({
  individualUserData,
  refreshIndividualUserData,
}) {
  const [isLoading, setIsLoading] = useState();
  const { error, isError, setError } = useError();
  const navigate = useNavigate();
  const { penggunaId } = useParams();
  const userData = _.find(individualUserData?.list, { _id: penggunaId });
  const { fileUrl: idCardImageUrl, fileIsLoading: idCardImageIsLoading } =
    useFiles(userData?.kyc.idCardImage);
  const formik = useFormik({
    initialValues: {
      name: userData?.name,
      email: userData?.email,
      province: userData?.address.province,
      district: userData?.address.district,
      subdistrict: userData?.address.subdistrict,
      village: userData?.address.village,
      street: userData?.address.street,
      postalCode: userData?.address.postalCode,
    },
    onSubmit: (values) => {
      handleUpdateProfile(values, penggunaId)
      setStatusVerified({ user_id: penggunaId });
      refreshIndividualUserData();
      navigate("/verifikasi-data-diri/pengguna-individu");
    },
  });

  const handleUpdateProfile = async (formValues, user_id) => {
    try {
      setIsLoading(true);
      updateProfile({
        user_id,
        userData: {
          name: formValues.name,
          email: formValues.email,
          address: {
            province: formValues.province,
            district: formValues.district,
            subdistrict: formValues.subdistrict,
            village: formValues.village,
            street: formValues.street,
            postalCode: formValues.postalCode,
          },
        },
      });
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalBackdrop>
      <form
        onSubmit={formik.handleSubmit}
        className="w-[865px] p-8 rounded-md bg-white flex flex-col gap-4"
      >
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Verifikasi Pengguna
          </h2>
          <Button.Text
            type="button"
            onClick={() => navigate(-1)}
            className="ml-auto p-2"
          >
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        <div className="flex flex-col gap-2">
          <h2 className="text-lg text-custom-primary-text font-bold">
            Data Diri
          </h2>
          <p className="mb-6 text-sm text-custom-secondary-text">
            Silahkan masukkan data sesuai dengan foto KTP yang disediakan
            pengguna.
          </p>
          <div className="grid grid-cols-2 gap-8">
            <div className="flex flex-col items-stretch gap-6">
              <TextField
                labelText="Nama"
                placeholder="Masukkan Nama"
                onChange={formik.handleChange}
                value={formik.values.name}
                required
              />
              <TextField
                labelText="Alamat"
                placeholder="Masukkan Alamat"
                onChange={formik.handleChange}
                value={formik.values.street}
                required
              />
              <div className="grid grid-cols-2 gap-8">
                <TextField
                  labelText="Kelurahan"
                  placeholder="Masukkan Kelurahan"
                  onChange={formik.handleChange}
                  value={formik.values.village}
                  required
                />
                <TextField
                  labelText="Kecamatan"
                  placeholder="Masukkan Kecamatan"
                  onChange={formik.handleChange}
                  value={formik.values.subdistrict}
                  required
                />
              </div>
              <div className="grid grid-cols-2 gap-8">
                <TextField
                  labelText="Kota/Kabupaten"
                  placeholder="Masukkan Kota/Kabupaten"
                  onChange={formik.handleChange}
                  value={formik.values.district}
                  required
                />
                <TextField
                  labelText="Provinsi"
                  placeholder="Masukkan Provinsi"
                  onChange={formik.handleChange}
                  value={formik.values.province}
                  required
                />
              </div>
              <div className="grid grid-cols-2 gap-8">
                <TextField
                  labelText="Kode Pos"
                  placeholder="Masukkan Kode Pos"
                  onChange={formik.handleChange}
                  value={formik.values.postalCode}
                  required
                />
              </div>
            </div>
            {idCardImageIsLoading ? (
              <div className="w-96 h-96 rounded-md bg-custom-background-lighter"></div>
            ) : (
              <img src={idCardImageUrl} className="w-96 h-96 rounded-md"></img>
            )}
          </div>
        </div>
        {!isLoading && !isError ? (
          <footer className="py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
            <Button.Primary type="submit">Verifikasi</Button.Primary>
            <Button.Secondary
              onClick={() => navigate(`../${penggunaId}/tolak`)}
            >
              Tolak
            </Button.Secondary>
            <Button.Secondary onClick={() => navigate(`..`)}>
              Kembali
            </Button.Secondary>
          </footer>
        ) : (
          <footer className="py-4 border-t bg-white sticky -bottom-16 flex items-center justify-center gap-4">
            <LoadingSpinner />
          </footer>
        )}
      </form>
    </ModalBackdrop>
  );
}
export default VerifikasiDataDiriPenggunaIndividuVerifikasiModal;
