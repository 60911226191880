import { Link } from "react-router-dom";
import Box from "../../../components/Box";
import Icons from "../../../components/Icons";
import ErrorMessage from "../../../components/ErrorMessage";
import LoadingSpinner from "../../../components/LoadingSpinner";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useError from "../../../hooks/useError";
import { useEffect, useState } from "react";
import { getNeedVerification } from "../../../services/kyc";

function DashboardVerifikasiPenggunaEnterprise(props) {
  const [isLoading, setIsLoading] = useState();
  const [enterpriseUserData, setEnterpriseUserData] = useState();
  const { error, isError, setError } = useError();

  const getEnterpriseUserData = async () => {
    try {
      setIsLoading(true);
      setEnterpriseUserData(
        await getNeedVerification({ role: "user-enterprise" })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEnterpriseUserData();
  }, []);

  return (
    <article className="">
      <header className="mb-2">
        <h2 className="inline-flex items-center gap-4 text-custom-primary-text text-xl font-bold">
          <span className="w-6 h-6">
            <Icons.IdCard className="w-6 h-6 stroke-custom-secondary-text" />
          </span>
          {true && (
            <div className="w-0 -m-2">
              <div className="w-3 h-3 rounded-full bg-blue-500 relative right-4 bottom-2"></div>
            </div>
          )}
          <span>Verifikasi Pengguna Enterprise</span>
        </h2>
      </header>
      <Box className="h-72 flex flex-col gap-4 divide-y overflow-y-auto overflow-x-hidden">
        {isLoading && (
          <div className="pt-16 flex items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
        {!isLoading &&
          !isError &&
          enterpriseUserData?.list?.map((user, i) => (
            <article className="pt-4 first:pt-0 flex flex-col gap-4" key={i}>
              <div className="grid grid-cols-2 gap-2 text-xs">
                <div className="flex flex-col gap-1">
                  <p className="text-custom-secondary-text">Nama</p>
                  <UsernameWithAvatar
                    avatarPath={user.avatar}
                    username={user.name}
                  />
                </div>
                <div className="justify-self-end self-center">
                  <Link
                    to={`/verifikasi-data-diri/pengguna-enterprise/${user._id}/verifikasi`}
                    className="text-custom-primary hover:underline"
                  >
                    Moderasi
                  </Link>
                </div>
              </div>
            </article>
          ))}
        {enterpriseUserData?.list?.length < 1 ||
          (error.code === 404 && (
            <div className="h-full flex items-center justify-center">
              <p className="flex gap-4 text-gray-300">
                <span>
                  <Icons.Database className="stroke-gray-300" />
                </span>
                <span>Belum ada data</span>
              </p>
            </div>
          ))}
        {isError && error.code !== 404 && <ErrorMessage error={error} />}
      </Box>
    </article>
  );
}
export default DashboardVerifikasiPenggunaEnterprise;
