import moment from "moment";
import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Icons from "../../../components/Icons";
import ModalBackdrop from "../../../components/ModalBackdrop";
import TextField from "../../../components/TextField";
import useError from "../../../hooks/useError";
import { deleteJournalist, getJournalist } from "../../../services/journalists";

function JurnalisHapusModal({ refreshJurnalisData, modalStatusChange, jurnalisId, modalHapus }) {
  const [isLoading, setIsLoading] = useState();
  const [journalistData, setJournalistData] = useState();
  const { setError, isError } = useError();
  const [statusReason, setstatusReason] = useState('');

  const toggle = () => {
    modalStatusChange()
    modalHapus()
  }
  const HandlersetstatusReason = event => {
    setstatusReason(event.target.value);
  }

  const getJournalistData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setJournalistData(
        (await getJournalist({ journalist_id: jurnalisId }))
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const HandlerHapusJurnalis = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      await deleteJournalist({
        journalist_id: jurnalisId, statusReason: statusReason,
      });
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
      refreshJurnalisData();
      toggle();
    }
  }
  useEffect(() => {
    getJournalistData();
  }, []);
  return (

    <ModalBackdrop>
      {!isLoading && (
        <article className="w-[540px] p-8 rounded-md bg-white flex flex-col gap-4">
          <header className="flex items-center gap-4">
            <h2 className="text-2xl text-custom-primary font-bold">
              Hapus Jurnalis
            </h2>
            <Button.Text onClick={toggle} className="ml-auto p-2">
              <Icons.X className="w-6 h-6 stroke-custom-primary" />
            </Button.Text>
          </header>
          <article className="p-4 rounded-md bg-custom-background-lighter first:pt-0 flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-2 text-xs">
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">ID</p>
                <p className="text-custom-primary-text line-clamp-1">
                  {journalistData?.list[0]._id}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Email</p>
                <p className="text-custom-primary-text line-clamp-1">
                {journalistData?.list[0].contact?.email ?? "-"}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">
                  Tanggal Pendaftaran Akun
                </p>
                <p className="text-custom-primary-text line-clamp-1">
                  {moment(journalistData?.list[0].createdAt).format("YYYY-MM-DD, HH:MM")}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Terakhir diubah</p>
                <p className="text-custom-primary-text line-clamp-1">
                  {moment(journalistData?.list[0].updatedAt).format("YYYY-MM-DD, HH:MM")}
                </p>
              </div>
            </div>
          </article>
          <p className="text-sm text-custom-primary-text">
            Silahkan masukkan pesan alasan penghapusan akun ini. Pesan akan
            dikirimkan ke admin internal intra-Q media.
          </p>
          <div className="mb-2 flex flex-col gap-1">
            <TextField labelText="Alasan" placeholder="Masukkan alasan..." required={true} value={statusReason} onChange={HandlersetstatusReason} />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Button.Secondary onClick={toggle}>
              Batal
            </Button.Secondary>
            <Button.Primary onClick={HandlerHapusJurnalis}>Hapus Jurnalis</Button.Primary>
          </div>

        </article>
      )}
    </ModalBackdrop>
  );
}
export default JurnalisHapusModal;
