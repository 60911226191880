import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import ErrorMessage from "../../components/ErrorMessage";
import GridLayout from "../../components/GridLayout";
import Icons from "../../components/Icons";
import LoadingSpinner from "../../components/LoadingSpinner";
import Main from "../../components/Main";
import PageHeader from "../../components/PageHeader";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import useError from "../../hooks/useError";
import { getNeedVerification } from "../../services/kyc";
import VerifikasiDataDiriPenggunaEnterpriseTable from "./components/VerifikasiDataDiriPenggunaEnterpriseTable";
import VerifikasiDataDiriPenggunaEnterpriseTolakVerifikasiModal from "./components/VerifikasiDataDiriPenggunaEnterpriseTolakVerifikasiModal";
import VerifikasiDataDiriPenggunaEnterpriseToolbar from "./components/VerifikasiDataDiriPenggunaEnterpriseToolbar";
import VerifikasiDataDiriPenggunaEnterpriseVerifikasiModal from "./components/VerifikasiDataDiriPenggunaEnterpriseVerifikasiModal";
import Helmet from "react-helmet";
import useReactiveSearchParams from "../../hooks/useReactiveSearchParams";

function VerifikasiDataDiriPenggunaEnterprise(props) {
  const [isLoading, setIsLoading] = useState();
  const [enterpriseUserData, setEnterpriseUserData] = useState({});
  const { error, isError, setError } = useError();
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  const getEnterpriseUserData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setEnterpriseUserData(
        await getNeedVerification({ role: "user-enterprise", ...searchParams })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEnterpriseUserData();
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>
          Pengguna Individu | Verifikasi Data Diri | Backoffice Intra Q Media
        </title>
      </Helmet>
      <GridLayout>
        <Sidebar />
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.IdCard className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Verifikasi Data Diri Pengguna Enterprise"
          />
          <VerifikasiDataDiriPenggunaEnterpriseToolbar />
          {isLoading && (
            <div className="h-24 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
          {isError ? (
            error.code == 404 ? (
              <div className="p-24 flex items-center justify-center">
                <p className="flex gap-4 text-gray-300">
                  <span>
                    <Icons.Database className="stroke-gray-300" />
                  </span>
                  <span>Belum ada data</span>
                </p>
              </div>
            ) : (
              <ErrorMessage error={error} />
            )
          ) : (
            <></>
          )}
          {!isLoading && !isError && (
            <VerifikasiDataDiriPenggunaEnterpriseTable
              enterpriseUserData={enterpriseUserData}
            />
          )}
        </Main>
      </GridLayout>

      {!isLoading && !isError && (
        <Routes>
          <Route
            path=":penggunaId/verifikasi"
            element={
              <VerifikasiDataDiriPenggunaEnterpriseVerifikasiModal
                enterpriseUserData={enterpriseUserData}
                refreshEnterpriseUserData={getEnterpriseUserData}
              />
            }
          />
          <Route
            path=":penggunaId/tolak"
            element={
              <VerifikasiDataDiriPenggunaEnterpriseTolakVerifikasiModal
                enterpriseUserData={enterpriseUserData}
                refreshEnterpriseUserData={getEnterpriseUserData}
              />
            }
          />
        </Routes>
      )}
    </>
  );
}
export default VerifikasiDataDiriPenggunaEnterprise;
