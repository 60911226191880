import axios from "axios";
import { API_ROUTES, status } from "../constansts";

async function getPetition(params) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_PETITION,
      method: "get",
      auth: {
        username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
        password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
      },
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache",

      },
      params
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function deletePetition({ content_petition_id, statusReason }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.DELETE_PETITION,
      method: "delete",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      params: {
        content_petition_id,
      },
      data: {
        statusReason: statusReason,
      },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}
async function changePetitionCategory({
  content_petition_id,
  content_category_id,
}) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.CHANGE_COMPLAINT_CATEGORY,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      data: { content_category_id },
      params: {
        content_petition_id,
      },
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function setPetitionPublish({ content_petition_id }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.SET_PETITION_PUBLISH,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      params: {
        content_petition_id,
      },
      data: {
        status: status.PUBLISHED,
        statusReason: "Petisi telah disetujui",
      },
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function setPetitionReject({ content_petition_id, statusReason}) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.SET_PETITION_REJECT,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      params: {
        content_petition_id,
      },
      data: {
        status: status.REJECTED,
        statusReason: statusReason,
      },
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

export { 
  getPetition, 
  deletePetition,
  setPetitionReject,
  setPetitionPublish,
  changePetitionCategory,
};
