/**
 * convert a string with line break(s) into a multiple <p> element
 * @param {Object} props
 * @param {string} props.text input string
 * @returns {JSX.Element} 
 */
function MultiLineString({ text = "" }) {
  return (
    <>
      {text.split("\\n").map((line) => (
        <p>{line}</p>
      ))}
    </>
  );
}
export default MultiLineString;
