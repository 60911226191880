import moment from "moment";
import { Link } from "react-router-dom";
import Table, { Row } from "../../../components/Table";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";
import { status } from "../../../constansts";

function KontenKeluhanTable({ complaintContentData , modalStatusChange}) {
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  return (
    <Table
      currentPage={searchParams.page ?? 1}
      totalPages={complaintContentData?.totalPage}
      headingRow={[
        {
          unsortable: true,
          name: "Pembuat Keluhan",
        },
        {
          unsortable: true,
          name: "Judul Keluhan",
        },
        {
          unsortable: true,
          name: "Kategori",
        },
        {
          unsortable: true,
          name: "status",
        },
        {
          unsortable: true,
          name: "Waktu",
        },
        {
          unsortable: true,
          name: "Aksi",
        },
      ]}
    >
      {complaintContentData?.list?.map((complaintContent, i) => (
        <Row
          key={i}
          rowCells={[
            <UsernameWithAvatar
              username={complaintContent?.user.name}
              avatarPath={complaintContent?.user.avatar}
            />,
            complaintContent?.title ?? "-",
            complaintContent?.contentCategory.name ?? "-",
            <>
            <div className="inline-flex items-center gap-4">
              {complaintContent.status === status.MODERATED ?
                <>
                  <span className="w-3 h-3 rounded-full bg-yellow-400	 flex-shrink-0"></span>
                  <span className="text-sm text-custom-primary-text">
                    Tahap Moderasi
                  </span>
                </>
                : (complaintContent.status === status.PUBLISHED ?
                  <>
                    <span className="w-3 h-3 rounded-full bg-green-400	 flex-shrink-0"></span>
                    <span className="text-sm text-custom-primary-text">
                      Diterima
                    </span>
                  </>
                   : (complaintContent.status === status.RESPONDED ?
                    <>
                      <span className="w-3 h-3 rounded-full bg-sky-700		 flex-shrink-0"></span>
                      <span className="text-sm text-custom-primary-text">
                        Ditanggapi
                      </span>
                    </>
                  : (complaintContent.status === status.REJECTED ?
                    <>
                      <span className="w-3 h-3 rounded-full bg-blue-400 flex-shrink-0"></span>
                      <span className="text-sm text-custom-primary-text">
                        Ditolak
                      </span>
                    </>
                   : (<></>)
                   )
                  )
                )
              }
            </div>
          </>,
            moment(complaintContent?.createdAt).format("DD-MM-YYYY, HH:mm"),
            <div className="divide-x-2 divide-gray-400 font-semibold text-custom-primary">
              <div className="px-2 first:pl-0 cursor-pointer hover:underline" onClick={() => modalStatusChange(complaintContent?._id)}>
                      Lihat Detail
                  </div>
            </div>,
          ]}
        />
      ))}
    </Table>
  );
}
export default KontenKeluhanTable;
