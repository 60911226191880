import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Icons from "../../../components/Icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import TextField from "../../../components/TextField";
import useError from "../../../hooks/useError";
import { useDropzone } from "react-dropzone";
import { uploadImageCover, getFileUrl } from "../../../services/files";
import { createNewsContent, getNews } from "../../../services/newsContent";
import { getCategories } from "../../../services/categories";
import { getCompany } from "../../../services/companies";
import Swal from "sweetalert2";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";
import { Select } from "antd";
function KontenBeritaBaruModal({
  modalStatusChange,
  refreshLaporanData,
  initialSelectedCompanyId,
  perusahaanId,
}) {
  const [isLoading, setIsLoading] = useState();
  const { error, setError, isError } = useError();
  const [files, setFiles] = useState([]);
  const [categoriesData, setCategoriesData] = useState();
  const [companyData, setCompaniesData] = useState();
  const [categoriesIsLoading, setCategoriesIsLoading] = useState(false);
  const [searchParams] = useReactiveSearchParams();
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);
  const authData = JSON.parse(window.localStorage.getItem("authData"));
  const [perusahaanIsEnabled, setPerusahaanIsEnabled] = useState();
  const [companyIsSelected, setCompanyIsSelected] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [companyNewsData, setCompanyNewsData] = useState("");
 
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const toggle = () => {
    modalStatusChange();
  };

  const getCategoriesData = async () => {
    try {
      setError({ status: "ok" });
      setCategoriesIsLoading(true);
      setCategoriesData(await getCategories());
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setCategoriesIsLoading(false);
      setIsSubmitting(false)
    }
  };

  const getCompaniesData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setCompaniesData(
        await getCompany({
          withPics: true,
          withSubs: true,
          size:20,
        })
      );
    } catch (error) {
      setError({
        message: "data tidak ada",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const addTags = () => {
    if (tag !== "") {
      if (tags.includes(tag)) {
        setError({
          message: "Tag sudah digunakan",
          status: "error",
        });
      } else {
        setTags([...tags, tag]);
        setTag("");
      }
    }
  };
  const removeTag = (index) => {
    let temp = [...tags];
    temp.splice(index, 1);
    setTags([...temp]);
  };
  const getCompanyNewsData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setCompanyNewsData(await getNews({ company_id : companyIsSelected ?.company_id}));
    } catch (error) {
      if (error.response.data.message === "Konten Berita tidak ditemukan!") {
        setCompanyNewsData([]);
      } else {
        setError({
          code: error.response.status,
          message: error.response.data.message,
          status: "error",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      content_category_id: "",
      company_id: "",
      subscription: "",
      title: "",
      body: "",
      createdAt: "",
      tag: [],
      coverImage: "",
      isFeatured: false,
      // FeatureImage:[],
    },
    onSubmit: async (data) => {
      setIsSubmitting(true)
      const totalNews = companyNewsData?.list?.length ?? 0;
      let   news = companyNewsData?.list?.length ?? 0;
      try {
        if (files.length === 0) throw new Error("Foto Cover Berita dibutuhkan");
        if (data.title === "" || data.title === null) {
          throw new Error("Judul Berita dibutuhkan");
        }
        if (data.body === "" || data.body === null) {
          throw new Error("Konten Berita dibutuhkan");
        }
        if (files[0].size / 1000 > 600) {
          throw new Error("Ukuran File Terlalu Besar");
        }
        if (news > companyIsSelected.list?.[0]?.subscription?.[0]?.policy?.content_allowed.publish_news) {
          console.log("Perusahaan sudah mencapai batas publish berita");
        }
        console.log("total",totalNews)
        // console.log("data news",companyIsSelected.list?.[0]?.subscription?.[0]?.policy?.content_allowed.publish_news);
          const newImageCover = new Promise((resolve) => {
            const response = uploadImageCover({
              user_id: authData._id,
              title: formik.values.title,
              file: files[0],
            });
            resolve(response);
          }).then((url) => {
            url = getFileUrl({ filePath: url.data.filePath });
            return url;
          })
          .then((url) => {
            try {
              setIsLoading(true);
              setError({ status: "ok" });
              const params = {
                content_category_id: data.content_category_id,
                company: {
                  company_id: companyIsSelected,
                },
                title: data.title,
                body: data.body,
                tag: tags,
                coverImage: url,
                isFeatured: data.isFeatured,
              };
              if (companyIsSelected === "" | companyIsSelected === null) {
                delete params.company;
              }
              const newCover = createNewsContent({ params });
              newCover.then(() => {
                Swal.fire({
                  icon: "success",
                  title: "Berhasil Membuat Berita",
                  showConfirmButton: false,
                  timer: 1500,  
              })
              refreshLaporanData();
              toggle();
              }).catch((error) => {
                setIsLoading(false);
                Swal.fire({
                  icon: "error",
                  title: "gagal membuat berita.",
                  text: "Perusahaan sudah mencapai batas publish berita",
                  timer: 1500,
                });
                throw error;
              });
            } catch (error) {
              Swal.fire({
                icon: "error",
                title: "gagal membuat berita.",
                text: error.message,
                timer: 1500,
              });
            }
          }).catch((error) => {
          Swal.fire({
            icon: "error",
            title: "gagal membuat berita.",
            text: error.message,
            timer: 1500,
          });
          throw error;
        });
      } catch (error) {
        if (error.message === "Foto Cover Berita dibutuhkan") {
          Swal.fire({
            icon: "error",
            title: "gagal membuat berita.",
            text: error.message,
            timer: 1500,
          });
        } else if (error.message === "Judul Berita dibutuhkan") {
          Swal.fire({
            icon: "error",
            title: "gagal membuat berita.",
            text: error.message,
            timer: 1500,
          });
        } else if (error.message === "Konten Berita dibutuhkan") {
          Swal.fire({
            icon: "error",
            title: "gagal membuat berita.",
            text: error.message,
            timer: 1500,
          });
        } else if (error.message === "Ukuran File Terlalu Besar") {
          Swal.fire({
            icon: "error",
            title: "gagal membuat berita.",
            text: error.message,
            timer: 1500,
          });
        }
        else {

          Swal.fire({
            icon: "error",
            title: "gagal membuat berita.",
            text: error.message,
            timer: 1500,
          });

        }
      } finally {
        setIsLoading(false);

      }
      setIsSubmitting(false)
        },
  });
  const onChange = (value) => {
    setCompanyIsSelected(value);
  };
  
const onSearch = (value) => {
};
  useEffect(() => {
    getCategoriesData();
    getCompaniesData();
    getCompanyNewsData();
  }, [searchParams]);
  return (
    <ModalBackdrop>
      {!isLoading && (
        <article className="w-[553px] p-8 rounded-md bg-white flex flex-col gap-4">
          <header className="flex items-center gap-4">
            <h2 className="text-2xl text-custom-primary font-bold">
              Buat Berita
            </h2>
            <Button.Text onClick={() => toggle()} className="ml-auto p-2">
              <Icons.X className="w-6 h-6 stroke-custom-primary" />
            </Button.Text>
          </header>
          <p className="text-xs text-slate-400	">
            Buat Berita Anda di Intra Q Media dan Unggah Berita
          </p>
          <div className="flex flex-col gap-6">
            <h3 className="text-lg text-custom-primary-text font-bold flex items-center">
              Isi Form Berita
              <span className="ml-3 px-2 py-1 rounded-md bg-custom-background-lighter text-xs font-normal">
                <span className="text-red-500">*</span> Wajib diisi
              </span>
            </h3>
            <div className="grid grid-cols-1 text-xs">
              <div className="flex flex-col ">
                <span className="text-xs text-custom-secondary-text mb-2">
                  {" "}
                  Pilih Perusahaan                  <></>
                </span>
                <div className="flex flex-row mb-2">
                  <input
                    type="checkbox"
                    name="perusahaanIsEnabled"
                    checked={perusahaanIsEnabled}
                    onClick={() => setPerusahaanIsEnabled((x) => !x)}
                  />
                  <p className="text-xs text-custom-secondary ml-2">
                    klik jika ingin Memilih Perusahaan
                  </p>
                </div>
                <div class="mb-3 xl:w-80">
                <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    style={{ width: 317 }}
                    aria-label="Default select example"
                    defaultValue={formik.values.company_id}
                    disabled={!perusahaanIsEnabled}
                    id="company_id"
                    name="company_id"
                  >
                    {companyData?.list
                      .filter((company) => company.subscription.length > 0)
                      .map((company) => (
                        <option
                          value={company._id}
                          selected={
                            company._id ===
                            (setCompanyIsSelected ?? initialSelectedCompanyId)
                          }
                        >
                          {company.name}
                        </option>
                        
                      ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 text-xs">
              <div className="flex flex-col gap-1 w-80">
                <TextField
                  type="text"
                  name="title"
                  labelText="Judul Berita"
                  placeholder="Masukkan Judul..."
                  onChange={formik.handleChange}
                  isError={formik.errors.title}
                  defaultValue={formik.values.title}
                  required
                />
              </div>
            </div>
            <div className="grid grid-cols-1 text-xs">
              <div className="flex flex-col w-80">
                <span className="text-xs text-custom-secondary-text mb-2">
                  {" "}
                  Konten
                  <span className="ml-1 text-red-500 select-none">*</span>
                  <></>
                </span>
                <textarea
                  class="
                  form-control
                  block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                "
                  rows="3"
                  placeholder=" Masukan konten..."
                  onChange={formik.handleChange}
                  isError={formik.errors.body}
                  defaultValue={formik.values.body}
                  required
                  id="body"
                  name="body"
                ></textarea>
              </div>
            </div>
            <div className="grid grid-cols-1 text-xs">
              <div className="flex flex-col ">
                <span className="text-xs text-custom-secondary-text mb-2">
                  {" "}
                  Pilih Kategori
                  <span className="ml-1 text-red-500 select-none">*</span>
                  <></>
                </span>
                <div class="mb-3 xl:w-80">
                  <select
                    class="form-select appearance-none
                  block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding bg-no-repeat
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    aria-label="Default select example"
                    onChange={formik.handleChange}
                    defaultValue={formik.values.content_category_id}
                    id="content_category_id"
                    name="content_category_id"
                    required
                  >
                    <option>-- Pilih Salah Satu --</option>
                    {categoriesData?.map((category) => (
                      <option value={category._id}>{category.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 text-xs">
              <div className="flex flex-col ">
                <span className="text-xs text-custom-secondary-text mb-2">
                  {" "}
                  Tags
                  <></>
                </span>
                <div className="border border-lg bg-slate-300 p-1 rounded-xl w-10/12">
                  <div className="flex flex-wrap">
                    {tags.length > 0 &&
                      tags.map((item, index) => {
                        return (
                          <div
                            className="w-max border rounded-lg bg-slate-200 pl-2 -pb-1 mr-1 mb-2"
                            key={index}
                          >
                            <div className="">
                              <Icons.X
                                className="border rounded-lg h-3 border-red-500 stroke-red-500 float-right w-3 ml-1 "
                                onClick={() => removeTag(index)}
                              />
                              <p className="float-left "> {item}</p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="px-1 pb-2 w-full flex mt-2">
                    <TextField
                      placeholder="Masukan Tags"
                      name="tag"
                      onChange={(e) => setTag(e.target.value)}
                      value={tag}
                      id="tag"
                      isError={formik.errors.tag}
                      defaultValue={formik.values.tag}
                    />
                    <Button.Primary
                      className="font-size-14 ml-3"
                      onClick={() => addTags()}
                    >
                      Tambah
                    </Button.Primary>
                  </div>
                </div>
                <p className="text-xs text-slate-400">
                  Contoh : ekonomi,bank,dst
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 text-xs">
              <div className="flex flex-col ">
                <span className="text-xs text-custom-secondary-text mb-2">
                  {" "}
                  Publish On Date
                  <></>
                </span>
                <TextField
                  className="w-80"
                  type="date"
                  name="createdAt"
                  id="createdAt"
                  onChange={formik.handleChange}
                  isError={formik.errors.createdAt}
                  defaultValue={formik.values.createdAt}
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-8 text-xs">
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">
                  Foto Cover{" "}
                  <span className="ml-1 text-red-500 select-none">*</span>
                </p>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <button className="w-full p-3 text-sm text-custom-primary font-bold hover:bg-gray-100 rounded-md inline-flex items-center gap-4">
                    {files[0] ? (
                      <img
                        src={files[0]?.preview}
                        alt=""
                        className="w-16 h-16 bg-gray-200 flex-shrink-0 rounded-full object-cover"
                      />
                    ) : (
                      <div className="w-16 h-16 bg-gray-200 flex-shrink-0 rounded-full stroke-custom-primary-text flex items-center justify-center">
                        <Icons.Plus />
                      </div>
                    )}
                    Upload Foto
                  </button>
                  <p className="text-xs text-slate-400">
                    File Size : Max 600kb
                  </p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 text-xs">
              <div className="flex flex-col ">
                <span className="text-xs text-custom-secondary-text mb-2">
                  {" "}
                  Feature News
                  <></>
                </span>
                <div className="flex flex-row">
                  <div className="custom-control mr-6">
                    <input
                      id="isFeatured"
                      type="radio"
                      name="isFeatured"
                      value="true"
                      onChange={formik.handleChange}
                    />
                    <label
                      className="custom-control-label relative bottom-1"
                      htmlFor="yes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="custom-control">
                    <input
                      id="isFeatured"
                      type="radio"
                      name="isFeatured"
                      onChange={formik.handleChange}
                      defaultChecked={formik.values.isFeatured === false}
                    />
                    <label
                      className="custom-control-label relative bottom-1"
                      htmlFor="no"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <ul className="flex flex-col gap-1.5">
              {Object.entries(formik.errors)?.map((error, i) => (
                <li className="text-red-700 text-xs" key={i}>
                  {error[1]}
                </li>
              ))}
              {isError && (
                <li className="text-red-700 text-xs">
                  {error?.message ?? "Terjadi kesalahan"}
                </li>
              )}
            </ul>
          </div>

          <footer className="mt-6 py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
          {!isSubmitting ? (
            <>
              <Button.Secondary onClick={() => toggle()}>
                Batal
              </Button.Secondary>
              <Button.Primary type="submit" onClick={formik.handleSubmit} >
                Buat Berita
              </Button.Primary>
            </>
          ) : (
            <>
              <Button.Secondary onClick={() => toggle()}>
                Batal
              </Button.Secondary>
              <Button.Primary type="submit" onClick={formik.handleSubmit} disabled>
                Buat Berita
              </Button.Primary>
            </>
          )}
        </footer>
        </article>
      )}
    </ModalBackdrop>
  );
}
export default KontenBeritaBaruModal;
