import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Icons from "../../../components/Icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import TextField from "../../../components/TextField";
import useError from "../../../hooks/useError";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { createCompany, updateCompany } from "../../../services/companies";
import { uploadCompanyAvatar } from "../../../services/files";

function PerusahaanBaruModal({ modalStatusChange, refreshPerusahaanData }) {
  const [isLoading, setIsLoading] = useState();
  const { error, setError, isError } = useError();
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const toggle = () => {
    modalStatusChange();
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      website: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Nama perusahaan dibutuhkan"),
      email: Yup.string()
        .email("Format email perusahaan tidak benar")
        .required("Email perusahaan dibutuhkan"),
      phoneNumber: Yup.string()
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Format nomor telepon perusahaan tidak benar"
        )
        .required("Nomor telepon perusahaan dibutuhkan"),
      website: Yup.string()
        .url("Format website perusahaan tidak benar")
        .nullable()
        .required("Website perusahaan dibutuhkan"),
    }),
    onSubmit: async (data) => {
      try {
        if (files.length === 0)
          throw new Error("Foto profil perusahaan dibutuhkan");
        setError({ status: "ok" });
        setIsLoading(true);
        const newCompany = (
          await createCompany({
            name: data.name,
            avatar: "",
            contact: {
              email: data.email,
              phoneNumber: data.phoneNumber,
              website: data.website,
            },
          })
        ).data;
        const newCompanyAvatar = (
          await uploadCompanyAvatar({
            company_id: newCompany._id,
            file: files[0],
          })
        ).data;
        await updateCompany({
          company_id: newCompany._id,
          avatar: process.env.REACT_APP_MEDIA_URL + newCompanyAvatar.filePath,
          contact: newCompany.contact,
        });
        toggle();
        refreshPerusahaanData();
      } catch (error) {
        if (error.message === "Foto profil perusahaan dibutuhkan") {
          setError({
            message: error.message,
            status: "error",
          });
        } else {
          setError({
            code: error.response.status,
            message: error.response.data.message,
            status: "error",
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <ModalBackdrop>
      <article className="w-[865px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Buat Perusahaan
          </h2>
          <Button.Text onClick={() => toggle()} className="ml-auto p-2">
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        <div className="flex flex-col gap-6">
          <h3 className="text-lg text-custom-primary-text font-bold flex items-center">
            Informasi Perusahaan
            <span className="ml-3 px-2 py-1 rounded-md bg-custom-background-lighter text-xs font-normal">
              <span className="text-red-500">*</span> Wajib diisi
            </span>
          </h3>
          <div className="grid grid-cols-3 gap-8 text-xs">
            <div className="flex flex-col gap-1">
              <TextField
                type="text"
                name="name"
                labelText="Nama Perusahaan"
                placeholder="Masukkan nama..."
                onChange={formik.handleChange}
                isError={formik.errors.name}
                defaultValue={formik.values.name}
                required
              />
            </div>
            <div className="flex flex-col gap-1">
              <TextField
                type="email"
                name="email"
                labelText="Email Perusahaan"
                placeholder="Masukkan email..."
                onChange={formik.handleChange}
                isError={formik.errors.email}
                defaultValue={formik.values.email}
                required
              />
            </div>
            <div className="flex flex-col gap-1">
              <TextField
                type="text"
                name="website"
                labelText="Website Perusahaan"
                placeholder="Masukkan alamat website..."
                onChange={formik.handleChange}
                isError={formik.errors.website}
                defaultValue={formik.values.website}
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-8 text-xs">
            <div className="flex flex-col gap-1">
              <p className="text-custom-secondary-text">
                Foto Profil Perusahaan{" "}
                <span className="ml-1 text-red-500 select-none">*</span>
              </p>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <button className="w-full p-3 text-sm text-custom-primary font-bold hover:bg-gray-100 rounded-md inline-flex items-center gap-4">
                  {files[0] ? (
                    <img
                      src={files[0]?.preview}
                      alt=""
                      className="w-16 h-16 bg-gray-200 flex-shrink-0 rounded-full object-cover"
                    />
                  ) : (
                    <div className="w-16 h-16 bg-gray-200 flex-shrink-0 rounded-full stroke-custom-primary-text flex items-center justify-center">
                      <Icons.Company />
                    </div>
                  )}
                  Ganti Foto
                </button>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <TextField
                labelText="Nomor Telepon Perusahaan"
                name="phoneNumber"
                placeholder="Masukkan nomor telepon..."
                onChange={formik.handleChange}
                defaultValue={formik.values.phoneNumber}
                isError={formik.errors.phoneNumber}
                required
              />
            </div>
          </div>
          <ul className="flex flex-col gap-1.5">
            {Object.entries(formik.errors)?.map((error, i) => (
              <li className="text-red-700 text-xs" key={i}>
                {error[1]}
              </li>
            ))}
            {isError && (
              <li className="text-red-700 text-xs">
                {error?.message ?? "Terjadi kesalahan"}
              </li>
            )}
          </ul>
          <p className="text-custom-secondary-text text-xs">
            Informasi di atas sewaktu-waktu dapat diubah oleh PIC perusahaan.
            Pengisian formulir ini hanya sebagai data awal saja.
          </p>
        </div>
        <footer className="mt-6 py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
          {!isLoading ? (
            <>
              <Button.Secondary onClick={() => toggle()}>
                Batal
              </Button.Secondary>
              <Button.Primary type="submit" onClick={formik.handleSubmit}>
                Buat Perusahaan
              </Button.Primary>
            </>
          ) : (
            <div className="w-full flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
        </footer>
      </article>
    </ModalBackdrop>
  );
}
export default PerusahaanBaruModal;
