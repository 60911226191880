import { Link } from "react-router-dom";
import Box from "../../../components/Box";
import Icons from "../../../components/Icons";
import { useEffect, useState } from "react";
import useError from "../../../hooks/useError";
import { getReportedContent } from "../../../services/reportedContent";
import ErrorMessage from "../../../components/ErrorMessage";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { getComplaint } from "../../../services/complaintContent";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import { status } from "../../../constansts";

function DashboardModerasiKontenKeluhan(props) {
  const [isLoading, setIsLoading] = useState();
  const [complaintData, setComplaintData] = useState();
  const { error, isError, setError } = useError();

  const getComplaintData = async () => {
    try {
      setIsLoading(true);
      setComplaintData(await getComplaint({ status: status.MODERATED}));
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getComplaintData();
  }, []);

  return (
    <article className="">
      <header className="mb-2">
        <h2 className="inline-flex items-center gap-4 text-custom-primary-text text-xl font-bold">
          <span className="w-6 h-6">
            <Icons.Edit className="w-6 h-6 stroke-custom-secondary-text" />
          </span>
          {true && (
            <div className="w-0 -m-2">
              <div className="w-3 h-3 rounded-full bg-blue-500 relative right-4 bottom-2"></div>
            </div>
          )}
          <span>Moderasi Konten Keluhan</span>
        </h2>
      </header>
      <Box className="h-72 flex flex-col gap-4 divide-y overflow-y-auto overflow-x-hidden">
        {isLoading && (
          <div className="pt-16 flex items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
        {!isLoading &&
          !isError &&
          complaintData?.list?.length >= 1 &&
          complaintData?.list?.map((complaintItem , i) => (
            <article className="pt-4 first:pt-0 flex flex-col gap-4" key={i}>
              <p className="text-custom-primary-text text-sm">
                {complaintItem.title}
              </p>
              <div className="grid grid-cols-3 gap-2 text-xs">
                <div className="flex flex-col gap-1">
                  <p className="text-custom-secondary-text">Pengirim</p>
                  <UsernameWithAvatar
                    avatarPath={complaintItem.user.avatar}
                    username={complaintItem.user.name}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-custom-secondary-text">Tujuan</p>
                  <UsernameWithAvatar username={complaintItem.company.name} />
                </div>
                <div className="justify-self-end ">
                  <Link
                    to={`/moderasi-konten/keluhan/${complaintItem._id}`}
                    className="text-custom-primary hover:underline"
                  >
                    Moderasi
                  </Link>
                </div>
              </div>
            </article>
          ))}
        {!isLoading && !isError && complaintData?.list?.length < 1 && (
          <>
            <div className="h-full flex items-center justify-center">
              <p className="flex gap-4 text-gray-300">
                <span>
                  <Icons.Database className="stroke-gray-300" />
                </span>
                <span>Belum ada data</span>
              </p>
            </div>
          </>
        )}
        {isError && <ErrorMessage error={error} />}
      </Box>
    </article>
  );
}
export default DashboardModerasiKontenKeluhan;
