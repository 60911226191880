import Icons from "../../../components/Icons";
import { useEffect, useState } from "react";
import { getFileUrl } from "../../../services/files";
import { useFiles } from "../../../hooks/useFiles";

const Image = ({ imagePath }) => {
  const { fileIsLoading, fileIsError, fileUrl } = useFiles(imagePath);

  return !fileIsLoading && !fileIsError ? (
    <div
      className="w-48 h-48 p-4 flex-shrink-0 bg-cover rounded-md flex items-stretch"
      style={{
        background: `url(${fileUrl})`,
      }}
    ></div>
  ) : (
    <div className="w-48 h-48 p-4 flex-shrink-0 bg-gray-100 bg-cover rounded-md flex items-stretch"></div>
  );
};

function ModerasiKontenKeluhanDetailAttachmentImage({ keluhanData }) {
  return (
    <div className="mb-4 flex flex-col gap-4">
      <h2 className="text-2xl text-custom-primary-text font-bold">
        Foto Lampiran
      </h2>
      <div className="flex gap-4 flex-wrap">
        
        {keluhanData?.list[0].attachmentImage.map((imagePath) => (
          <Image imagePath={imagePath}/>
        ))}
      </div>
    </div>
  );
}
export default ModerasiKontenKeluhanDetailAttachmentImage;
