import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Hr from "../../../components/Hr";
import Icons from "../../../components/Icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import { status } from "../../../constansts";
import useError from "../../../hooks/useError";
import { getComment } from "../../../services/commentContent";
import { getComplaint } from "../../../services/complaintContent";
import { getUserList } from "../../../services/usersDataProfile";

function UserComplaintCount({ penggunaId }) {
  const [isLoading, setIsLoading] = useState();
  const [complaintCount, setComplaintCount] = useState();
  const { error, setError, isError } = useError();

  const getComplaintCount = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setComplaintCount(
        (await getComplaint({ user_id: penggunaId }))?.totalData
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getComplaintCount();
  }, []);

  return (
    <>
      {!isLoading && !isError ? complaintCount : ""}
      {isError ? (error.code == "404" ? 0 : "-") : null}
    </>
  );
}

function UserCommentCount({ penggunaId }) {
  const [isLoading, setIsLoading] = useState();
  const [commentCount, setCommentCount] = useState();
  const { error, setError, isError } = useError();

  const getCommentCount = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setCommentCount((await getComment({ user_id: penggunaId }))?.totalData);
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCommentCount();
  }, []);

  return (
    <>
      {!isLoading && !isError ? commentCount : ""}
      {isError ? (error.code == "404" ? 0 : "-") : null}
    </>
  );
}

function PenggunaEnterpriseDetailModal({ modalStatusChange, penggunaId, modalHapus }) {
  const [isLoading, setIsLoading] = useState();
  const [userData, setUserData] = useState();
  const { error, setError, isError } = useError();

  function toggle() {
    modalStatusChange();
  }
  
  const getUserData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setUserData((await getUserList({ user_id: penggunaId }))?.list[0]);
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <ModalBackdrop>
      <article className="w-[865px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Detail Pengguna
          </h2>
          <Button.Text onClick={toggle} className="ml-auto p-2">
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        {!isLoading && !isError && (
          <div className="flex flex-col gap-6">
            <h3 className="text-lg text-custom-primary-text font-bold">
              Data Diri
            </h3>
            {/* Row 1 */}
            <div className="grid grid-cols-4 gap-2 text-xs">
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">ID</p>
                <p className="text-custom-primary-text line-clamp-1">
                  {userData?._id ?? "-"}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Email PIC</p>
                <p className="text-custom-primary-text line-clamp-1">
                  {userData?.email ?? "-"}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">
                  Tanggal Pendaftaran Akun
                </p>
                <p className="text-custom-primary-text line-clamp-1">
                  {moment(userData?.createdAt).format("YYYY-MM-DD, HH:MM")}
                </p>
              </div>
            </div>

            {/* Row 2 */}
            <div className="grid grid-cols-4 gap-2 text-xs">
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Nama PIC</p>
                <p className="text-custom-primary-text line-clamp-1">
                  {userData?.name ?? "-"}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Email Perusahaan</p>
                <p className="text-custom-primary-text line-clamp-1">
                  {userData?.email ?? "-"}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">
                  Tanggal Terakhir Masuk
                </p>
                <p className="text-custom-primary-text line-clamp-1">
                  {moment(userData?.lastLoginAt).format("YYYY-MM-DD, HH:MM")}
                </p>
              </div>
            </div>

            {/* Row 3 */}
            <div className="grid grid-cols-4 gap-2 text-xs">
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Nama Perusahaan</p>
                <p className="text-custom-primary-text line-clamp-1">
                  {userData?.company?.name ?? "-"}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Status</p>
                {userData?.kyc.status === status.VERIFIED ?
                  <>
                    <div className="w-max flex items-center gap-2">
                      <span className="w-2 h-2 rounded-full bg-custom-green flex-shrink-0"></span>
                      <span className="text-xs text-custom-primary-text">
                        {status.VERIFIED}
                      </span>
                    </div>
                  </>
                  : (userData?.kyc.status === status.UNVERIFIED ?
                    <>
                      <div className="w-max flex items-center gap-2">
                        <span className="w-2 h-2 rounded-full bg-custom-red flex-shrink-0"></span>
                        <span className="text-xs text-custom-primary-text">
                          {status.UNVERIFIED}
                        </span>
                      </div>
                    </>
                   :  (userData?.kyc.status === status.REJECTED ?
                    <>
                      <div className="w-max flex items-center gap-2">
                        <span className="w-2 h-2 rounded-full bg-custom-red flex-shrink-0"></span>
                        <span className="text-xs text-custom-primary-text">
                          Ditolak
                        </span>
                      </div>
                    </>
                    : (
                      <>
                        <div className="w-max flex items-center gap-2">
                          <span className="w-3 h-3 rounded-full bg-yellow-400 flex-shrink-0"></span>
                          <span className="text-sm text-custom-primary-text">
                            {status.SUBMITTED}
                          </span>
                        </div>
                      </>
                    )
                  )
                  )
                }
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Terakhir diubah</p>
                <p className="text-custom-primary-text line-clamp-1">
                  {moment(userData?.updatedAt).format("YYYY-MM-DD, HH:MM")}
                </p>
              </div>
            </div>
            {userData?.kyc.status === status.VERIFIED ? <>
              <Hr />
              <h3 className="text-lg text-custom-primary-text font-bold">
                Alamat
              </h3>
              <div className="grid grid-cols-4 gap-2 text-xs">
                <div className="flex flex-col gap-1">
                  <p className="text-custom-secondary-text">Provinsi</p>
                  <p className="text-custom-primary-text line-clamp-1">
                    {userData?.address.province ?? "-"}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-custom-secondary-text">Kecamatan</p>
                  <p className="text-custom-primary-text line-clamp-1">
                    {userData?.address.subdistrict ?? "-"}
                  </p>
                </div>
                <div className="flex flex-col gap-1 col-span-2">
                  <p className="text-custom-secondary-text">Alamat</p>
                  <p className="text-custom-primary-text line-clamp-1">
                    {userData?.address.street ?? "-"}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-2 text-xs">
                <div className="flex flex-col gap-1">
                  <p className="text-custom-secondary-text">Kota</p>
                  <p className="text-custom-primary-text line-clamp-1">
                    {userData?.address.district ?? "-"}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-custom-secondary-text">Kelurahan</p>
                  <p className="text-custom-primary-text line-clamp-1">
                    {userData?.address.village ?? "-"}
                  </p>
                </div>
                <div className="flex flex-col col-span-2">
                  <p className="text-custom-secondary-text">Kode Pos</p>
                  <p className="text-custom-primary-text line-clamp-1">
                    {userData?.address.postalCode ?? "-"}
                  </p>
                </div>
              </div>
            </> : <></>}
            <Hr />
            <h3 className="text-lg text-custom-primary-text font-bold">
              Konten Intra Q Media
            </h3>
            <div className="grid grid-cols-4 gap-2 text-xs">
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Jumlah Keluhan</p>
                <p className="text-custom-primary-text line-clamp-1">
                  {<UserComplaintCount penggunaId={penggunaId} />}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Jumlah Komentar</p>
                <p className="text-custom-primary-text line-clamp-1">
                  {<UserCommentCount penggunaId={penggunaId} />}
                </p>
              </div>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
        {!isLoading && !isError && (
          <footer className="py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
            <Button.Primary onClick={toggle}>
              Kembali
            </Button.Primary>
            <Button.Secondary
              onClick={() => { modalHapus(penggunaId) }}
            >
              Hapus Pengguna
            </Button.Secondary>
          </footer>
        )}
      </article>
    </ModalBackdrop>
  );
}
export default PenggunaEnterpriseDetailModal;
