import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import Icons from "./Icons";
import LoadingSpinner from "./LoadingSpinner";
import UsernameWithAvatar from "./UsernameWithAvatar";

function PageHeader() {
  const [profileButtonElement, setProfileButtonElement] = useState();
  const [profileSettingPopupElement, setProfileSettingPopupElement] =
    useState();
  const [profileSettingPopupIsOpen, setProfileSettingPopupIsOpen] =
    useState(false);
  const [currentTime, setCurrentTime] = useState(moment().format("HH:mm"));
  const [currentDate, setCurrentDate] = useState(
    moment().format("dddd, DD MMMM YYYY")
  );
  const { authIsLoading, logout } = useContext(AuthContext);
  const authData = JSON.parse(window.localStorage.getItem("authData"));
  const { styles, attributes } = usePopper(
    profileButtonElement,
    profileSettingPopupElement
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("HH:mm"));
      setCurrentDate(moment().locale("ID").format("dddd, DD MMMM YYYY"));
    }, 1000);
    return () => clearInterval(interval);
  });

  return (
    <header className="pb-4 flex items-center justify-between">
      <p>
        <span className="text-custom-primary-text text-sm">{currentDate}</span>
        <span className="ml-4 text-custom-secondary-text">{currentTime}</span>
      </p>
      {!authIsLoading && authData ? (
        <>
          <button
            ref={setProfileButtonElement}
            onClick={() => setProfileSettingPopupIsOpen((x) => !x)}
            className="h-8 p-2 rounded hover:bg-gray-100 inline-flex items-center gap-4 text-custom-primary-text text-sm"
          >
            <UsernameWithAvatar
              username={authData.name}
              avatarPath="/general/default_image_profile.svg"
            />
            <Icons.ChevronDown className="w-6 h-6 stroke-custom-primary-text" />
          </button>
          {profileSettingPopupIsOpen ? (
            <div
              ref={setProfileSettingPopupElement}
              className="z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow flex flex-col items-stretch"
              style={styles.popper}
              {...attributes.popper}
            >
              <button className="p-3 hover:bg-gray-100 inline-flex items-center gap-3">
                <Icons.Users className="w-6 h-6 stroke-custom-secondary-text flex-shrink-0" />
                <span className="text-sm text-custom-primary-text">
                  Ganti Foto Profil
                </span>
              </button>
              <button className="p-3 hover:bg-gray-100 inline-flex items-center gap-3">
                <Icons.Key className="w-6 h-6 stroke-custom-secondary-text flex-shrink-0" />
                <span className="text-sm text-custom-primary-text">
                  Ubah Password
                </span>
              </button>
              <button
                onClick={() => {
                  logout();
                  window.location.pathname = "/login";
                }}
                className="p-3 hover:bg-gray-100 inline-flex items-center gap-3"
              >
                <Icons.LogOut className="w-6 h-6 stroke-custom-secondary-text flex-shrink-0" />
                <span className="text-sm text-custom-primary-text">
                  Log Out
                </span>
              </button>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </header>
  );
}
export default PageHeader;
