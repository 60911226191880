import { NavLink } from "react-router-dom";
import { ReactComponent as LogomarkWithText } from "../assets/logo/logomark-with-text.svg";
import SidebarGroup from "./SidebarGroup";
import SidebarItem from "./SidebarItem";
import Icons from "./Icons";

function Sidebar({ sidebarItems }) {
  sidebarItems =  [
    // {
    //   isGroup: false,
    //   path: "/dashboard",
    //   title: "Dashboard",
    //   icon: <Icons.Dashboard className="w-4 h-4" />,
    // },
    // {
    //   isGroup: true,
    //   title: "Moderasi Konten",
    //   icon: <Icons.Edit className="w-4 h-4" />,
    //   childrenItem: [
    //     {
    //       isGroup: false,
    //       path: "/moderasi-konten/keluhan",
    //       title: "Keluhan",
    //     },
    //     {
    //       isGroup: false,
    //       path: "/moderasi-konten/tanggapan",
    //       title: "Tanggapan",
    //     },{
    //       isGroup: false,
    //       path: "/moderasi-konten/petisi",
    //       title: "Petisi",
    //     },
    //   ],
    // },
    // {
    //   isGroup: true,
    //   title: "Verifikasi Data Diri",
    //   icon: <Icons.IdCard className="w-4 h-4" />,
    //   childrenItem: [
    //     {
    //       isGroup: false,
    //       path: "/verifikasi-data-diri/pengguna-individu",
    //       title: "Pengguna Individu",
    //     },
    //     {
    //       isGroup: false,
    //       path: "/verifikasi-data-diri/pengguna-enterprise",
    //       title: "Pengguna Enterprise",
    //     },
    //   ],
    // },
    // {
    //   isGroup: true,
    //   title: "Laporan",
    //   icon: <Icons.Report className="w-4 h-4" />,
    //   childrenItem: [
    //     {
    //       isGroup: false,
    //       path: "/laporan/keluhan",
    //       title: "Keluhan",
    //     },
    //     {
    //       isGroup: false,
    //       path: "/laporan/komentar",
    //       title: "Komentar",
    //     },
    //     {
    //       isGroup: false,
    //       path: "/laporan/petisi",
    //       title: "Petisi",
    //     },
    //   ],
    // },
    // {
    //   isGroup: true,
    //   title: "Kontak",
    //   icon: <Icons.Users className="w-4 h-4" />,
    //   childrenItem: [
    //     {
    //       isGroup: false,
    //       path: "/pengguna/individu",
    //       title: "Jurnalis",
    //     },
    //     {
    //       isGroup: false,
    //       path: "/pengguna/enterprise",
    //       title: "Media",
    //     },
    //   ],
    // },
    {
      isGroup: false,
      path: "/jurnalis",
      icon: <Icons.Users className="w-4 h-4" />,
      title: "Jurnalis",
    },
    {
      isGroup: false,
      path: "/perusahaan",
      icon: <Icons.Company className="w-4 h-4" />,
      title: "Mitra Kerjasama",
    },
  ];
  const authData = JSON.parse(window.localStorage.getItem("authData"));
  if (!sidebarItems) {
    return null;
  }
  if ( authData.role === "admin-root") {
    // sidebarItems.splice( 4, 0, 
    //   {
    //     isGroup: true,
    //     title: "Konten",
    //     icon: <Icons.Send className="w-4 h-4" />,
    //     childrenItem: [
    //       {
    //         isGroup: false,
    //         path: "/konten/keluhan",
    //         title: "Keluhan",
    //       },
    //       {
    //         isGroup: false,
    //         path: "/konten/komentar",
    //         title: "Komentar",
    //       },
    //       {
    //         isGroup: false,
    //         path: "/konten/tanggapan",
    //         title: "Tanggapan",
    //       },
    //       {
    //         isGroup: false,
    //         path: "/konten/petisi",
    //         title: "Petisi",
    //       },
    //     ],
    //   })
    sidebarItems.push({
      isGroup: false,
      path: "/admin",
      icon: <Icons.Users className="w-4 h-4" />,
      title: "Admin",
    })
    // sidebarItems.push({
    //   isGroup: false,
    //   path: "/berita",
    //   icon: <Icons.Company className="w-4 h-4" />,
    //   title: "Berita",
    // })
  } else {
    // sidebarItems.splice( 4, 0, 
    //   {
    //     isGroup: true,
    //     title: "Konten",
    //     icon: <Icons.Send className="w-4 h-4" />,
    //     childrenItem: [
    //       {
    //         isGroup: false,
    //         path: "/konten/keluhan",
    //         title: "Keluhan",
    //       },
    //       {
    //         isGroup: false,
    //         path: "/konten/komentar",
    //         title: "Komentar",
    //       },
    //       {
    //         isGroup: false,
    //         path: "/konten/tanggapan",
    //         title: "Tanggapan",
    //       },
    //       {
    //         isGroup: false,
    //         path: "/konten/petisi",
    //         title: "Petisi",
    //       },
    //     ],
    //   })
  }
  return (
    <div className="w-[full] h-screen py-4 col-span-2 bg-custom-background sticky top-0 flex flex-col items-center justify-start overflow-y-auto"
      style={{
        background: `linear-gradient(
          to bottom,
          rgba(239, 70, 40, 0.5) 10.18%,
          rgba(183, 30, 66, 0.5) 18.18%,
          rgba(98, 70, 127, 0.5) 42.63%,
          rgba(98, 70, 127, 0.5) 100%
        )`,
      }}>
      <NavLink
        to="/"
        className="mb-10 rounded-md hover:bg-white hover:bg-opacity-30"
      >
        {/* <LogomarkWithText className="filter saturate-0" /> */}
        {/* <h2 className="text-2xl text-custom-primary font-bold">
          Intra-Q Media
        </h2> */}
         <img src={"/intra-q-media-logo.png"} alt="Intra Q Media" style={{
          width: "auto",
          height: "100px",
         }}/>
      </NavLink>
      <div 
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "#e0e0e0",
          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
          margin: "0 0 16px 0"
        }}
      ></div>
      <ul className="w-full flex flex-col items-stretch">
        {sidebarItems?.map((item, i) =>
          item.isDivider ? (
            <>
              <div className="my-2 border"></div>
            </>
          ) : item.isGroup ? (
            <SidebarGroup {...item} key = {i} />
          ) : (
            <SidebarItem {...item} key = {i}/>
          )
        )}
      </ul>
    </div>
  );
}
export default Sidebar;
