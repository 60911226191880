import axios from "axios";
import { API_ROUTES, status } from "../constansts";

async function getFeedback(params) {
  let result = [];

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_FEEDBACK,
      method: "get",
      auth: {
        username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
        password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
      },
      headers: {
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache",

      },
      params,
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}
async function publishFeedback({content_feedback_id}){
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.SET_FEEDBACKSTATUS,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      params: {
        content_feedback_id,
      },
      data: {
        status: status.PUBLISHED,
        statusReason: "Tanggapan telah disetujui",
      },
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function rejectFeedback({content_feedback_id, statusReason}){
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.SET_FEEDBACKSTATUS,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      params: {
        content_feedback_id,
      },
      data: {
        status: status.REJECTED,
        statusReason: statusReason,
      },
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}
async function deleteFeedback({ content_feedback_id, statusReason }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.DELETE_FEEDBACK,
      method: "delete",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      params: {
        content_feedback_id,
      },
      data: {
        statusReason: statusReason,
      },

    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

export { getFeedback,deleteFeedback ,publishFeedback, rejectFeedback};
