import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Icons from "../../../components/Icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import TextField from "../../../components/TextField";
import useError from "../../../hooks/useError";
import { getCategories } from "../../../services/categories";
import { getNews, updateNews } from "../../../services/newsContent";

import moment from "moment";
import "moment/locale/id";

function KontenBeritaEditModal({
  modalStatusChange,
  initialSelectedCategoryId,
  contentNewsId,
  modalEdit,
  refreshLaporanData,
}) {
  const [isLoading, setIsLoading] = useState();
  const { error, setError, isError } = useError();
  const [categoriesData, setCategoriesData] = useState();
  const [setCategoriesIsLoading] = useState(false);
  const authData = JSON.parse(window.localStorage.getItem("authData"));
  const [selectedCategoryId] = useState(initialSelectedCategoryId);
  const [contentNewsData, setContentNewsData] = useState();
  const toggle = () => {
    modalStatusChange();
    modalEdit();
  };
  const getContentNewsData = async () => {
    try {
      setIsLoading(true);
      setContentNewsData(await getNews({ content_news_id: contentNewsId }));
    } catch (error) {
      setError({
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const getCategoriesData = async () => {
    try {
      setError({ status: "ok" });
      setCategoriesIsLoading(true);
      setCategoriesData(await getCategories());
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setCategoriesIsLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      content_news_id: "",
      body: "",
    },
    onSubmit: async (data) => {
      try {
        setError({ status: "ok" });
        setIsLoading(true);
        const contentNewsId = contentNewsData?.query?.content_news_id;
        const newsToUpdate = { ...data };
        if (!newsToUpdate.body) {
          const currentNews = await getNews({ content_news_id: contentNewsId });
          newsToUpdate.body = currentNews.body;
        }
        const updateNewsData = (
          await updateNews({
            content_news_id: contentNewsData?.query?.content_news_id,
            body: newsToUpdate.body,
          })
        ).data;
        toggle();
        refreshLaporanData();
      }catch (error) {
        setError({
          code: error.response.status,
          message: error.response.data.message,
          status: "error",
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    getCategoriesData();
    getContentNewsData();
  }, []);
  return (
    <ModalBackdrop>
      <article className="w-[553px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Edit Berita
          </h2>
          <Button.Text onClick={() => toggle()} className="ml-auto p-2">
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        <div className="flex flex-col gap-6">
          <div className="grid grid-cols-1 text-xs">
            <div className="flex flex-col gap-1 w-80">
              <TextField
                type="text"
                name="title"
                labelText="Judul Berita"
                placeholder="Masukkan Judul..."
                onChange={formik.handleChange}
                isError={formik.errors.title}
                defaultValue={contentNewsData?.list[0].title}
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-1 text-xs">
            <div className="flex flex-col gap-1 w-80">
              <TextField
                type="text"
                name="company"
                labelText="Company"
                defaultValue={contentNewsData?.list[0].company?.name ?? "-"}
                disabled
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-1 text-xs">
            <div className="flex flex-col w-80">
              <span className="text-xs text-custom-secondary-text mb-2">
                {" "}
                Konten
                <span className="ml-1 text-red-500 select-none">*</span>
                <></>
              </span>
              <textarea
                class="
                  form-control
                  block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                "
                rows="3"
                id="body"
                name="body"
                placeholder=" Masukan konten..."
                onChange={formik.handleChange}
                isError={formik.errors.body}
                defaultValue={contentNewsData?.list[0].body}
                required
              ></textarea>
            </div>
          </div>
          <div className="grid grid-cols-1 text-xs">
            <div className="flex flex-col gap-1 w-80">
              <TextField
                type="text"
                name="content_category_id"
                id="content_category_id"
                labelText="Kategori"
                defaultValue={contentNewsData?.list[0].contentCategory.name}
                disabled
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-1 text-xs">
            <div className="flex flex-col ">
              <span className="text-xs text-custom-secondary-text mb-2">
                {" "}
                Created At
                <span className="ml-1 text-red-500 select-none">*</span>
                <></>
              </span>
              <TextField
                className="w-80"
                type="text"
                name="createdat"
                onChange={formik.handleChange}
                isError={formik.errors.publish}
                defaultValue={moment(contentNewsData?.list[0].createdAt).format(
                  "DD-MM-YYYY, HH:MM"
                )}
                disabled
              />
            </div>
          </div>
          <ul className="flex flex-col gap-1.5">
            {Object.entries(formik.errors)?.map((error, i) => (
              <li className="text-red-700 text-xs" key={i}>
                {error[1]}
              </li>
            ))}
            {isError && (
              <li className="text-red-700 text-xs">
                {error?.message ?? "Terjadi kesalahan"}
              </li>
            )}
          </ul>
        </div>

        <footer className="mt-6 py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
          {!isLoading ? (
            <>
              <Button.Secondary onClick={() => toggle()}>
                Batal
              </Button.Secondary>
              <Button.Primary type="submit" onClick={formik.handleSubmit}>
                Simpan Berita
              </Button.Primary>
            </>
          ) : (
            <div className="w-full flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
        </footer>
      </article>
    </ModalBackdrop>
  );
}
export default KontenBeritaEditModal;
