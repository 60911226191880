import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import ErrorMessage from "../../../components/ErrorMessage";
import Icons from "../../../components/Icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import MultiLineString from "../../../components/MultiLineString";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useError from "../../../hooks/useError";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";
import { getFeedback } from "../../../services/feedbackContent";

function ModerasiKontenTanggapanModerasiTanggapanModal({modalStatusChange, feedbackId, modalPublikasi, modalTolak}) {
  const [tanggapanData, setTanggapanData] = useState();
  const [isLoading, setIsLoading] = useState();
  const { error, setError, isError } = useError();
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const [isOpened, setIsOpened] = useState();

  const toggle =() =>{
    modalStatusChange(!isOpened)
  }

  const getTanggapanData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setTanggapanData(
        await getFeedback({ content_feedback_id: feedbackId })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    getTanggapanData();
  }, [searchParams]);

  return (
    <>
      <ModalBackdrop>
        <article className="w-[865px] p-8 rounded-md bg-white flex flex-col gap-4">
          <header className="flex items-center gap-4">
            <h2 className="text-2xl text-custom-primary font-bold">
              Moderasi Tanggapan
            </h2>
            <a
              href="https://intramedia.site"
              target="_blank"
              className="p-2 rounded-md hover:bg-gray-100 inline-flex"
            >
              Buka Keluhan di Intra Q Media
              <Icons.NewTab className="w-6 h-6 stroke-custom-primary" />
            </a>
            <Button.Text onClick={toggle} className="ml-auto p-2">
              <Icons.X className="w-6 h-6 stroke-custom-primary" />
            </Button.Text>
          </header>
          {!isLoading && !isError ? (
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <p className="mb-1.5 text-xs text-custom-secondary-text">
                  Isi Tanggapan
                </p>
                <article className="text-sm text-custom-primary-text">
                  <MultiLineString text={tanggapanData?.list[0]?.body} />
                </article>
              </div>
              <div className="flex flex-col gap-4">
                <div className="">
                  <p className="mb-1.5 text-xs text-custom-secondary-text">
                    Keluhan yang ditanggapi
                  </p>
                  <p className="text-sm text-custom-primary-text">
                    {tanggapanData?.list[0]?.contentComplaint.title}
                  </p>
                </div>
                <div className="">
                  <p className="mb-1.5 text-xs text-custom-secondary-text">
                    Pembuat Tanggapan
                  </p>
                  <UsernameWithAvatar
                    className="text-xs"
                    avatarPath={
                      tanggapanData?.list[0]?.user.avatar ?? "-"
                    }
                    username={tanggapanData?.list[0]?.user.name ?? "-"}
                  />
                </div>
                <div className="">
                  <p className="mb-1.5 text-xs text-custom-secondary-text">
                    Perusahaan
                  </p>
                 <UsernameWithAvatar username = {tanggapanData?.list[0]?.user.company ?? "-"}/>
                </div>
                <div className="">
                  <p className="mb-1.5 text-xs text-custom-secondary-text">
                    Waktu Pembuatan
                  </p>
                  <p className="text-sm text-custom-primary-text">
                    {moment(tanggapanData?.list[0]?.createdAt).format(
                    "DD-MM-YYYY, HH:MM"
                  )}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {isLoading ? (
            <div className="py-16 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          ) : (
            <></>
          )}
          {isError ? <ErrorMessage error={error} /> : <></>}
          <footer className="py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
            <Button.Primary
              onClick={() => modalPublikasi(feedbackId)}
            >
              Publikasikan Tanggapan
            </Button.Primary>
            <Button.Secondary
              onClick={() => modalTolak(feedbackId)}
            >
              Tolak Tanggapan
            </Button.Secondary>
          </footer>
        </article>
      </ModalBackdrop>
    </>
  );
}
export default ModerasiKontenTanggapanModerasiTanggapanModal;
