import { Link } from "react-router-dom";
import moment from "moment";
import Table, { Row } from "../../../components/Table";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";

function KontenKomentarTable({ commentContentData , modalStatusChange}) {
  const [searchParams] = useReactiveSearchParams();

  return (
    <Table
      currentPage={searchParams.page ?? 1}
      totalPages={commentContentData?.totalPage}
      headingRow={[
        {
          unsortable: true,
          name: "Pembuat Komentar",
        },
        {
          unsortable: true,
          name: "Isi Komentar",
        },
        {
          unsortable: true,
          name: "Keluhan",
        },
        {
          unsortable: true,
          name: "Waktu",
        },
        {
          unsortable: true,
          name: "Aksi",
        },
      ]}
    >
      {commentContentData?.list?.map((commentContent, i) => (
        <Row
          key={i}
          rowCells={[
            // ID
            // Pembuat Komentar
            <UsernameWithAvatar
              username={commentContent?.user.name}
              avatarPath={commentContent?.user.avatar}
            />,
            // Isi Komentar
            commentContent?.message ?? "-",
            // Keluhan
            commentContent?.commentedContent.title ?? "-",
            // Waktu
            moment(commentContent?.createdAt).format("DD-MM-YYYY, HH:mm"),
            // Aksi
            <div className="divide-x-2 divide-gray-400 font-semibold text-custom-primary">
               <div className="px-2 first:pl-0 cursor-pointer hover:underline" onClick={() => modalStatusChange(commentContent?._id)}>
                      Lihat Detail
                  </div>
            </div>,
          ]}
        />
      ))}
    </Table>
  );
}
export default KontenKomentarTable;
