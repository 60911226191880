import { useEffect, useState } from "react";
import { getFileUrl } from "../services/files";

function useFiles(filePath) {
  const [fileIsLoading, setFileIsLoading] = useState();
  const [fileIsError, setFileIsError] = useState();
  const [fileUrl, setFileUrl] = useState();

  const fetchFileUrl = async () => {
    try {
      setFileIsLoading(true);
      setFileUrl(await getFileUrl({ filePath }));
    } catch (error) {
      setFileIsError(true);
    } finally {
      setFileIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFileUrl();
  }, []);

  return {
    fileIsLoading,
    fileIsError,
    filePath,
    fileUrl,
  };
}

export { useFiles };
