import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Icons from "../../../components/Icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useError from "../../../hooks/useError";
import { setComplaintPublish } from "../../../services/complaintContent";

function ModerasiKontenKeluhanDetailPublikasiKeluhanModal({
  keluhanData,
  refreshKeluhanData,
}) {
  const [isLoading, setIsLoading] = useState();
  const { error, setError, isError } = useError();
  const navigate = useNavigate();
  const { keluhanId } = useParams();

  const handleSetComplaintPublish = () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setComplaintPublish({ content_complaint_id: keluhanId });
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
      refreshKeluhanData();
      navigate("/moderasi-konten/keluhan");
    }
  };

  return (
    <ModalBackdrop>
      <article className="w-[540px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Publikasi Keluhan
          </h2>
          <Button.Text onClick={() => navigate("..")} className="ml-auto p-2">
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        <div>
          <p className="mb-4 text-sm text-custom-primary-text">
            Apakah Anda yakin ingin mempublikasikan keluhan ini?
          </p>
          <article className="p-4 rounded-md bg-custom-background-lighter first:pt-0 flex flex-col gap-4">
            <p className="text-custom-primary-text text-sm">
              {keluhanData?.list[0]?.title}
            </p>
            <div className="grid grid-cols-3 gap-2 text-xs">
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Pembuat Tanggapan</p>
                <UsernameWithAvatar
                  username={keluhanData?.list[0]?.user?.name}
                  avatarPath={keluhanData?.list[0]?.user?.avatar}
                />
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Perusahaan</p>
                <UsernameWithAvatar
                  avatarPlaceholder={
                    <div className="w-6 h-6 bg-gray-100 flex-shrink-0 rounded-full object-cover inline-flex items-center justify-center">
                      <Icons.Company className="w-3 h-3 stroke-custom-primary-text" />
                    </div>
                  }
                  username={keluhanData?.list[0]?.company.name}
                />
              </div>
            </div>
          </article>
        </div>
        {isLoading && (
          <div className="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
        {!isLoading && (
          <div className="grid grid-cols-2 gap-4">
            <Button.Secondary onClick={() => navigate("..")}>
              Batal
            </Button.Secondary>
            <Button.Primary onClick={handleSetComplaintPublish}>
              Publikasi
            </Button.Primary>
          </div>
        )}
      </article>
    </ModalBackdrop>
  );
}
export default ModerasiKontenKeluhanDetailPublikasiKeluhanModal;
