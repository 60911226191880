import ButtonGroup from "../../../components/ButtonGroup";
import Icons from "../../../components/Icons";
import TextField from "../../../components/TextField";
import _ from "lodash";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";
import Button from "../../../components/Button";
import KontenKomentarFilterPopup from "./KontenKomentarFilterPopup";

function KontenKomentarToolbar() {
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  
  return (
    
      <div className="py-2 flex items-center gap-4">
        <ButtonGroup className="mr-auto">
          <ButtonGroup.Item
            onClick={() => setSearchParams({ ...searchParams, size: 10 })}
            isActive={
              // Defaults to 10
              searchParams.size
                ? Number.parseInt(searchParams.size) === 10
                : true
            }
          >
            View 10
          </ButtonGroup.Item>
          <ButtonGroup.Item
            onClick={() => setSearchParams({ ...searchParams, page:1, size: 20 })}
            isActive={Number.parseInt(searchParams.size) === 20}
          >
            View 20
          </ButtonGroup.Item>
          <ButtonGroup.Item
            onClick={() => setSearchParams({ ...searchParams, page:1, size: 30 })}
            isActive={Number.parseInt(searchParams.size) === 30}
          >
            View 30
          </ButtonGroup.Item>
        </ButtonGroup>
        <KontenKomentarFilterPopup />
        <TextField
        placeholder="Cari berdasarkan isi komentar"
        onKeyPress={(event) => {
          if (event.key == "Enter") {
            setSearchParams(
              event.target.value !== ""
                ? { ...searchParams, keyword: event.target.value }
                : { ..._.omit(searchParams, ["keyword"]) }
            );
          }
        }}
        value={searchParams.reason}
        icon={<Icons.Search className="w-4 h-4 stroke-custom-primary-text" />}
      />{" "}
    </div>
  );
}
export default KontenKomentarToolbar;
