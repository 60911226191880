import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import GridLayout from "../../components/GridLayout";
import ErrorMessage from "../../components/ErrorMessage";
import Icons from "../../components/Icons";
import Main from "../../components/Main";
import PageHeader from "../../components/PageHeader";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import useError from "../../hooks/useError";
import { getFeedback } from "../../services/feedbackContent";
import KontenTanggapanToolbar from "./components/KontenTanggapanToolbar";
import useReactiveSearchParams from "../../hooks/useReactiveSearchParams";
import LoadingSpinner from "../../components/LoadingSpinner";
import KontenTanggapanTable from "./components/KontenTanggapanTable";
import KontenTanggapanDetailModal from "./components/KontenTanggapanDetailModal";
import Helmet from "react-helmet";
import KontenTanggapanHapusModal from "./components/KontenTanggapanHapusModal";
import { status } from "../../constansts";

function KontenTanggapan() {
  const [isLoading, setIsLoading] = useState();
  const { error, isError, setError } = useError();
  const [feedbackContentData, setfeedbackContentData] = useState();
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  const [isDetailOpened, setIsDetailOpened] = useState();
  const [isHapusOpened, setIsHapusOpened] = useState();
  const [contentFeedbackId, setFeedbackId] = useState();

  const toggle = (feedback) => {
    setIsDetailOpened(isDetailOpened => !isDetailOpened);
    setFeedbackId(feedback);
  }

  const togglehapus = (feedback) => {
    setIsDetailOpened(false);
    setIsHapusOpened(isHapusOpened => !isHapusOpened);
    setFeedbackId(feedback);
  }


  const getFeedbackContentData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setfeedbackContentData(
        await getFeedback({ status: status.PUBLISHED, page: 1, size: 10, ...searchParams })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFeedbackContentData();
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Konten Tanggapan | Backoffice Intra Q Media</title>
      </Helmet>
      <GridLayout>
        <Sidebar />
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.Send className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Konten Tanggapan"
          />
          <KontenTanggapanToolbar />
          {isLoading ? (
            <div className="h-24 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          ) : (
            <></>
          )}
          {!isLoading && !isError ? (
            <KontenTanggapanTable feedbackContentData={feedbackContentData} modalStatusChange={toggle} />
          ) : (
            <></>
          )}
          {isError ? (
            error.code == 404 ? (
              <div className="p-24 flex items-center justify-center">
                <p className="flex gap-4 text-gray-300">
                  <span>
                    <Icons.Database className="stroke-gray-300" />
                  </span>
                  <span>Data Tidak Ditemukan</span>
                </p>
              </div>
            ) : (
              <ErrorMessage error={error} />
            )
          ) : (
            <></>
          )}
          {!isLoading && isDetailOpened && (
            <KontenTanggapanDetailModal
              refreshLaporanData={getFeedbackContentData} modalStatusChange={toggle} contentFeedbackId={contentFeedbackId} modalHapus={togglehapus} />
          )}
          {!isLoading && isHapusOpened && (
            <KontenTanggapanHapusModal
              refreshLaporanData={getFeedbackContentData} modalStatusChange={toggle} contentFeedbackId={contentFeedbackId} modalHapus={togglehapus} />
          )}
        </Main>
      </GridLayout>
    </>
  );
}
export default KontenTanggapan;
