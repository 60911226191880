import { Link } from "react-router-dom";
import Table, { Row } from "../../../components/Table";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";

/**
 * Table section of screen `Perusahaan`
 * @typedef {Object} Company
 * @property {string} _id
 * @property {string} name
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {string} avatar
 * @property {Object} contact
 * @property {string} contact.email 
 * @property {string} contact.phoneNumber
 * @property {string} contact.website
 * 
 * @param {Object} props
 * @param {Object} props.companyData
 * @param {Company[]} props.companyData.list
 */
function PerusahaanTable({ companyData, modalStatusChange }) {
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  return (
    <Table
      headingRow={[
        { name: "Nama Perusahaan", unsortable: true },
        { name: "Email Perusahaan", unsortable: true },
        { name: "No Telp", unsortable: true },
        { name: "Jumlah PIC", unsortable: true },
        { name: "Aksi", unsortable: true },
      ]}
      currentPage={searchParams.page ?? 1}
      totalPages={companyData?.totalPage}
    >
      {companyData?.list?.map((company, i) => {
        return (
          <Row
            key={i}
            rowCells={[
              <UsernameWithAvatar
                username={company.name}
                avatarPath={company.avatar} />,
              company?.contact?.email ?? "-",
              company?.contact?.phoneNumber ?? "-",
              company?.picList?.length ?? 0,
              <>
              <div className="divide-x-2 divide-gray-400 font-semibold text-custom-primary">
              <div className="px-2 first:pl-0 cursor-pointer hover:underline" onClick={() => modalStatusChange(company._id)}>
                      Lihat Detail
                  </div>
              </div>
              </>
              
            ]}
          />
        );
      }
      )}
      </Table>

      )
      }
export default PerusahaanTable;
