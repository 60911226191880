import moment from "moment";
import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import ErrorMessage from "../../../components/ErrorMessage";
import Icons from "../../../components/Icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import MultiLineString from "../../../components/MultiLineString";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import { status } from "../../../constansts";
import useError from "../../../hooks/useError";
import { getCompany,updatePicCompany } from "../../../services/companies";
import { getComplaint } from "../../../services/complaintContent";
import { getNews } from "../../../services/newsContent";
import { useFormik } from "formik";



function PerusahaanDetailModal({ modalStatusChange, modalHapus, modalsubscription,perusahaanId,modalnewpic,refreshPerusahaanData }) {
  const [isLoading, setIsLoading] = useState();
  const [companyData, setCompanyData] = useState();
  const [companyComplaintData, setCompanyComplaintData] = useState();
  const [companyNewsData, setCompanyNewsData] = useState();
  const { error, isError, setError } = useError();
  const [isOpened] = useState();
  let [responded, percentage, issued,news] = [0, 0, 0,0];
  function toggle() {
    modalStatusChange(!isOpened);
  }
  const { totalData: totalpublishCount } = getNews({ params: { company_id: perusahaanId } })
  const getCompanyData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setCompanyData(
        (await getCompany({ withSubs: true, withPics: true, company_id: perusahaanId }))
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);

    }
  };
  const getCompanyComplaintData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setCompanyComplaintData(
        (await getComplaint({ company_id: perusahaanId }))
      )
    } catch (error) {
      if (error.response.data.message === "Konten Keluhan tidak ditemukan!") {
        setCompanyComplaintData([])
      } else {
        setError({
          code: error.response.status,
          message: error.response.data.message,
          status: "error",
        });
      }
    } finally {
      setIsLoading(false);

    }
  };
  const getCompanyNewsData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setCompanyNewsData(
        (await getNews({ company_id: perusahaanId }))
      )
    } catch (error) {
      if (error.response.data.message === "Konten Berita tidak ditemukan!") {
        setCompanyNewsData([])
      } else {
        setError({
          code: error.response.status,
          message: error.response.data.message,
          status: "error",
        });
      }
    } finally {
      setIsLoading(false);

    }
  };
  useEffect(() => {
    getCompanyData();
    getCompanyComplaintData();
    getCompanyNewsData();
  }, []);
  news = companyNewsData?.list?.length ?? 0;
  responded = companyComplaintData?.list?.filter((item) => item.status === status.RESPONDED)?.length ?? 0;
  issued = companyComplaintData?.list?.filter((item) => item.status === status.RESPONDED || item.status === status.PUBLISHED)?.length ?? 0;
  percentage = isNaN(responded / issued) * 100 ? 0 : parseFloat((responded / issued) * 100).toFixed(2);
  return (
    <ModalBackdrop>
      <article className="w-[865px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Detail Perusahaan
          </h2>
          <a
            href="https://intramedia.site"
            target="_blank"
            rel="noreferrer"
            className="p-2 rounded-md hover:bg-gray-100 inline-flex"
          >
            Buka Web Mitra
            <Icons.NewTab className="w-6 h-6 stroke-custom-primary" />
          </a>
          <Button.Text onClick={toggle} className="ml-auto p-2">
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        {!isLoading && !isError && (
          <div className="flex flex-col gap-6">
            {/* <h3 className="text-lg text-custom-primary-text font-bold">
              Konten Intra Q Media
            </h3>
            <div className="grid grid-cols-3 text-xs">
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Jumlah Tanggapan</p>
                <p className="text-custom-primary-text line-clamp-1">{responded}</p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">
                  Persentase Terjawab
                </p>
                <p className="text-custom-primary-text ">
                  {percentage}% ({responded} dari {issued} keluhan ditanggapi)
                </p>
              </div>
            </div> */}

            <h3 className="text-lg text-custom-primary-text font-bold">
              Informasi Perusahaan
            </h3>
            <div className="grid grid-cols-4">
              <div className="flex flex-col gap-6 col-span-3">
                <div className="grid grid-cols-3 gap-2 text-xs">
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">
                      Nama Perusahaan
                    </p>
                    <UsernameWithAvatar username={companyData?.list[0].name} avatarPath={companyData?.list[0].avatar} />
                  </div>
                  <div className="flex flex-col gap-1 hidden">
                    <p className="text-custom-secondary-text">ID</p>
                    <p className="text-custom-primary-text line-clamp-1">
                      {companyData?.list[0]._id}
                    </p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">
                      Email Perusahaan
                    </p>
                    <p className="text-custom-primary-text line-clamp-1">
                      {companyData?.list[0].contact?.email ?? "-"}
                    </p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">Pembuatan</p>
                    <p className="text-custom-primary-text line-clamp-1">
                      {moment(companyData?.list[0].createdAt).format("YYYY-MM-DD, HH:MM")}

                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-2 text-xs">
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">
                      Nomor Telp Perusahaan
                    </p>
                    <p className="text-custom-primary-text line-clamp-1">
                      {companyData?.list[0].contact?.phoneNumber ?? "-"}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-2 text-xs">
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">Jumlah PIC</p>
                    {companyData?.list[0].picList?.length ?? 0}
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">
                      Website Perusahaan
                    </p>
                    <p className="text-custom-primary-text line-clamp-1">
                      {companyData?.list[0].contact?.website ?? "-"}
                    </p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">
                      Terakhir Diubah
                    </p>
                    <p className="text-custom-primary-text line-clamp-1">
                      {moment(companyData?.list[0].updatedAt).format("YYYY-MM-DD, HH:MM")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-1 text-xs">
                <p className="text-custom-secondary-text">Daftar PIC</p>
                <ul className="flex flex-col gap-1">
                  {companyData?.list[0].picList?.map((picUser) => (
                    <li>
                      <p className="text-custom-primary-text line-clamp-1">
                        <UsernameWithAvatar username={picUser?.email ?? "-"} avatarPath={picUser?.avatar ?? "-"} />
                      </p>
                    </li>
                  )) ?? "-"}
                </ul>
              </div>
            </div>
            <div className="flex flex-col gap-1 text-xs">
              <p className="text-custom-secondary-text">Deskripsi Perusahaan</p>
              <div className="text-custom-primary-text">
                <MultiLineString text={companyData?.list[0].description ?? "-"} />
              </div>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
        {isError && (
          error.code === 404 ? (
            <div className="p-24 flex items-center justify-center">
              <div className="flex gap-4 text-gray-300">
                <span>
                  <Icons.Database className="stroke-gray-300" />
                </span>
                <span>Data Tidak Ditemukan</span>
              </div>
            </div>,
            <footer className="py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
              <Button.Primary onClick={toggle}>
                Kembali
              </Button.Primary>
            </footer>
          ) : (
            <ErrorMessage error={error} />
          )
        )
        }
        {!isLoading && !isError && (
          <footer className="mt-6 py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
            <Button.Primary onClick={toggle}>
              Kembali
            </Button.Primary>
            <Button.Secondary onClick={() => modalHapus(companyData.list[0]._id)}>Hapus Perusahaan</Button.Secondary>
          </footer>
        )}
      </article>
    </ModalBackdrop>
  );
}
export default PerusahaanDetailModal;
