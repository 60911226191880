import { ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import GridLayout from "../../components/GridLayout";
import Icons from "../../components/Icons";
import LoadingSpinner from "../../components/LoadingSpinner";
import Main from "../../components/Main";
import PageHeader from "../../components/PageHeader";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import useError from "../../hooks/useError";
import useReactiveSearchParams from "../../hooks/useReactiveSearchParams";
import { getJournalist } from "../../services/journalists";
import JurnalisBaruModal from "./components/JurnalisBaruModal";
import JurnalisDetailModal from "./components/JurnalisDetailModal";
import JurnalisTable from "./components/JurnalisTable";
import JurnalisToolbar from "./components/JurnalisToolbar";
import Helmet from "react-helmet";
import JurnalisHapusModal from "./components/JurnalisHapusModal";
import JurnalisImportBaruModal from "./components/JurnalisImportBaruModal";

function Jurnalis() {
  const [isLoading, setIsLoading] = useState();
  const [journalistData, setJournalistData] = useState();
  const { error, setError, isError } = useError();
  const [searchParams] = useReactiveSearchParams();
  const [isDetailOpened, setIsDetailOpened] = useState();
  const [isBaruOpened, setIsBaruOpened] = useState();
  const [isImportOpened, setIsImportOpened] = useState();
  const [isHapusOpened, setIsHapusOpened] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [jurnalisId, setjurnalisId] = useState();

  const toggle = (journalist, action) => {
    if (action === "details") {
      setIsDetailOpened((wasOpened) => !wasOpened);
    } else if (action === "edit") {
      setIsBaruOpened((wasOpened) => !wasOpened);
      setIsEdit(journalist !== null);
      setEditData(journalist);
    } else if (!action) {
      setIsDetailOpened(false);
      setIsBaruOpened(false);
      setIsImportOpened(false);
    }
    setjurnalisId(journalist);
  };
  const togglehapus = (journalist) => {
    setIsDetailOpened(false);
    setIsHapusOpened((wasOpened) => !wasOpened);
    setjurnalisId(journalist);
  };

  const togglebaru = (journalist = null) => {
    setIsBaruOpened((wasOpened) => !wasOpened);
    setIsEdit(journalist !== null);
    setEditData(journalist);
  };

  const togglebaruimport = () => {
    setIsImportOpened((wasOpened) => !wasOpened);
  };

  const modalStatusChange = (type) => {
    if (type === "import") {
      togglebaruimport();
    } else if (type === "create") {
      togglebaru();
    }
  };

  const getJournalistData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setJournalistData(
        await getJournalist({
          page: 1,
          size: 10,
          ...searchParams,
        })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getJournalistData();
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Jurnalis | Backoffice Intra Q Media</title>
      </Helmet>
      <GridLayout>
        <Sidebar />
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.Users className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Jurnalis"
          />
          <JurnalisToolbar modalStatusChange={modalStatusChange} />
          {isLoading && (
            <div className="h-24 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
          {isError ? (
            error.code === 404 ? (
              <div className="p-24 flex items-center justify-center">
                <p className="flex gap-4 text-gray-300">
                  <span>
                    <Icons.Database className="stroke-gray-300" />
                  </span>
                  <span>Data Tidak Ditemukan</span>
                </p>
              </div>
            ) : (
              <ErrorMessage error={error} />
            )
          ) : (
            <></>
          )}
          {!isLoading && !isError && (
            <JurnalisTable
              journalistData={journalistData}
              modalStatusChange={toggle}
            />
          )}
        </Main>
        {!isLoading && isDetailOpened && (
          <JurnalisDetailModal
            refreshJurnalisData={getJournalistData}
            modalStatusChange={toggle}
            modalHapus={togglehapus}
            jurnalisId={jurnalisId}
          />
        )}
        {!isLoading && isHapusOpened && (
          <JurnalisHapusModal
            refreshJurnalisData={getJournalistData}
            modalStatusChange={toggle}
            jurnalisId={jurnalisId}
            modalHapus={togglehapus}
          />
        )}
        {!isLoading && isBaruOpened && (
          <JurnalisBaruModal
            refreshJurnalisData={getJournalistData}
            modalStatusChange={togglebaru}
            editData={editData}
            isEdit={isEdit}
          />
        )}
        {!isLoading && isImportOpened && (
          <JurnalisImportBaruModal
            refreshJurnalisData={getJournalistData}
            modalStatusChange={togglebaruimport}
            editData={editData}
            isEdit={isEdit}
          />
        )}
      </GridLayout>
    </>
  );
}
export default Jurnalis;
