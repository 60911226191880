import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import ErrorMessage from "../../components/ErrorMessage";
import GridLayout from "../../components/GridLayout";
import Icons from "../../components/Icons";
import LoadingSpinner from "../../components/LoadingSpinner";
import Main from "../../components/Main";
import PageHeader from "../../components/PageHeader";
import PageTitle from "../../components/PageTitle";
import useReactiveSearchParams from "../../hooks/useReactiveSearchParams";
import Sidebar from "../../components/Sidebar";
import useError from "../../hooks/useError";
import { getNeedVerification } from "../../services/kyc";
import VerifikasiDataDiriPenggunaIndividuTable from "./components/VerifikasiDataDiriPenggunaIndividuTable";
import VerifikasiDataDiriPenggunaIndividuTolakVerifikasiModal from "./components/VerifikasiDataDiriPenggunaIndividuTolakVerifikasiModal";
import VerifikasiDataDiriPenggunaIndividuToolbar from "./components/VerifikasiDataDiriPenggunaIndividuToolbar";
import VerifikasiDataDiriPenggunaIndividuVerifikasiModal from "./components/VerifikasiDataDiriPenggunaIndividuVerifikasiModal";
import Helmet from "react-helmet";

function VerifikasiDataDiriPenggunaIndividu(props) {
  const [isLoading, setIsLoading] = useState();
  const [individualUserData, setIndividualUserData] = useState({});
  const { error, isError, setError } = useError();
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  const getIndividualUserData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setIndividualUserData(
        await getNeedVerification({ 
          role: "user-individual", ...searchParams })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getIndividualUserData();
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Pengguna Individu | Verifikasi Data Diri | Backoffice Intra Q Media</title>
      </Helmet>
      <GridLayout>
        <Sidebar />
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.IdCard className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Verifikasi Data Diri Pengguna Individu"
          />
          <VerifikasiDataDiriPenggunaIndividuToolbar />
          {isLoading && (
            <div className="h-24 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
          {isError ? (
            error.code == 404 ? (
              <div className="p-24 flex items-center justify-center">
                <p className="flex gap-4 text-gray-300">
                  <span>
                    <Icons.Database className="stroke-gray-300" />
                  </span>
                  <span>Belum ada data</span>
                </p>
              </div>
            ) : (
              <ErrorMessage error={error} />
            )
          ) : (
            <></>
          )}
          {!isLoading && !isError && (
            <VerifikasiDataDiriPenggunaIndividuTable
              individualUserData={individualUserData}
            />
          )}
        </Main>
      </GridLayout>
      {!isLoading && !isError && (
        <Routes>
          <Route
            path=":penggunaId/verifikasi"
            element={
              <VerifikasiDataDiriPenggunaIndividuVerifikasiModal
                individualUserData={individualUserData}
                refreshIndividualUserData={getIndividualUserData}
              />
            }
          />
          <Route
            path=":penggunaId/tolak"
            element={
              <VerifikasiDataDiriPenggunaIndividuTolakVerifikasiModal
                individualUserData={individualUserData}
                refreshIndividualUserData={getIndividualUserData}
              />
            }
          />
        </Routes>
      )}
    </>
  );
}
export default VerifikasiDataDiriPenggunaIndividu;
