import { useState } from "react";
import { matchPath } from "react-router-dom";
import { ReactComponent as ChevronDown } from "../assets/icons/chevron-down.svg";
import SidebarItem from "./SidebarItem";

export default function SidebarGroup({
  title = "",
  icon = <></>,
  childrenItem,
}) {
  const [isActive, setIsActive] = useState(
    childrenItem.filter((item) =>
      matchPath({ path: item.path, end: false }, window.location.pathname)
    ).length > 0
  );
  return (
    <>
      <button
        onClick={() => setIsActive((x) => !x)}
        className={`w-full px-2 py-3 ${
          isActive
            ? "bg-custom-sidebar-group hover:bg-custom-sidebar-group"
            : "bg-transparent hover:bg-gray-200"
        } text-custom-primary-text stroke-custom-primary-text text-sm text-left inline-flex items-center justify-start gap-4 leading-none`}
      >
        <div className="w-4 h-4">{icon}</div>
        <span className="w-full truncate h-full">{title}</span>
        <div className="w-4 h-4">
          {
            <ChevronDown
              className={`w-4 h-4 ${isActive ? "transform rotate-180" : ""}`}
            />
          }
        </div>
      </button>
      {isActive && (
        <ul className="w-full bg-custom-sidebar-group flex flex-col items-stretch">
          {childrenItem?.map((item, i) => (
            <>
              <SidebarItem {...item} key = {i}/>
            </>
          ))}
        </ul>
      )}
    </>
  );
}
