import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Icons from "../../../components/Icons";
import ModalBackdrop from "../../../components/ModalBackdrop";
import TextField from "../../../components/TextField";
import { setStatusRejected } from "../../../services/kyc";

function VerifikasiDataDiriPenggunaEnterpriseTolakVerifikasiModal(props) {
  const navigate = useNavigate();
  const [statusReason, setstatusReason] = useState('');
  const { penggunaId } = useParams();
  const formik = useFormik({
    initialValues: {
      statusReason: statusReason,
    },
    onSubmit: ({ statusReason }) => {
      setStatusRejected({ user_id: penggunaId, statusReason });
      navigate("/verifikasi-data-diri/pengguna-enterprise");
    },
  });
  const HandlersetstatusReason = event => {
    setstatusReason(event.target.value);
  }
  return (
    <ModalBackdrop>
      <form
        onSubmit={formik.handleSubmit}
        className="w-[540px] p-8 rounded-md bg-white flex flex-col gap-4"
      >
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Tolak Permohonan Verifikasi
          </h2>
          <Button.Text
            type="button"
            onClick={() => navigate("..")}
            className="ml-auto p-2"
          >
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-custom-primary-text">
            Harap masukkan alasan singkat penolakan permohonan verifikasi. Pesan
            akan dikirimkan ke notifikasi pengguna.
          </p>
          <p className="text-sm text-custom-secondary-text">
            Contoh: “Foto kurang jelas”
          </p>
          <TextField labelText="Alasan" required={true} value={statusReason} onChange={HandlersetstatusReason} />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Button.Secondary
            type="button"
            onClick={() => navigate("/verifikasi-data-diri/pengguna-individu")}
          >
            Batal
          </Button.Secondary>
          <Button.Primary type="submit">Tolak dan Kirim Alasan</Button.Primary>
        </div>
      </form>
    </ModalBackdrop>
  );
}
export default VerifikasiDataDiriPenggunaEnterpriseTolakVerifikasiModal;
