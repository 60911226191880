import { API_ROUTES } from "../constansts";
import axios from "axios";

async function loginAdmin({ username, password }) {
  let result = {};
  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.LOGIN_ADMIN,
      method: "post",
      auth: {
        username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
        password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
      data: {
        username,
        password,
      },
    });
    result = response.data.data;
    window.localStorage.setItem("authData", JSON.stringify(result));
    window.localStorage.setItem("token", JSON.stringify(result.accessToken));
  } catch (error) {
    throw error;
  }

  return result;
}

async function getAdminList({ size = 10, page = 1 }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_ADMIN_LIST,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache",
        Accept: "*/*",
      },
      params: {
        size,
        page,
      },
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function deleteAdmin({ admin_id, statusReason }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.DELETE_ADMIN,
      method: "delete",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache",
      },
      params: { 
        admin_id 
      },
      data: {
        statusReason: statusReason,
      },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}
export { loginAdmin, getAdminList, deleteAdmin };
