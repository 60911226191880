import moment from "moment";
import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import ErrorMessage from "../../../components/ErrorMessage";
import Icons from "../../../components/Icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import useError from "../../../hooks/useError";
import { getAdminList } from "../../../services/admin";


function AdminDetailModal({ propsAdminData,  modalStatusChange, adminId, modalHapus }) {
  const [isLoading, setIsLoading] = useState();
  const [adminData, setAdminData] = useState();
  const { error, setError, isError } = useError();
  
  function toggle() {
    modalStatusChange();
  }

  const handleOnloadAdminData = async () => {
    const filteredAdminData = propsAdminData.list.filter((i) => { return i._id === adminId })
    setAdminData(filteredAdminData[0])
  }

  useEffect(() => {
    handleOnloadAdminData()
  });

  return (
    <ModalBackdrop>
      <article className="w-[865px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Detail Pengguna
          </h2>
          <Button.Text onClick={toggle} className="ml-auto p-2">
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        {!isLoading && !isError && (
          <div className="flex flex-col gap-6">
            <h3 className="text-lg text-custom-primary-text font-bold">
              Data Diri
            </h3>
            <div className="grid grid-cols-4 gap-2 text-xs">
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">ID</p>
                <p className="text-custom-primary-text line-clamp-1">
                  {adminData?._id}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">
                  Tanggal Pendaftaran Akun
                </p>
                <p className="text-custom-primary-text line-clamp-1">
                  {moment(adminData?.createdAt).format("YYYY-MM-DD, HH:MM")}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Terakhir diubah</p>
                <p className="text-custom-primary-text line-clamp-1">
                  {moment(adminData?.updatedAt).format("YYYY-MM-DD, HH:MM")}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-2 text-xs">
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">Nama</p>
                <p className="text-custom-primary-text line-clamp-1">
                  {adminData?.name}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-custom-secondary-text">
                  Tanggal Terakhir Masuk
                </p>
                <p className="text-custom-primary-text line-clamp-1">
                {moment(adminData?.lastLoginAt).format("YYYY-MM-DD, HH:MM")}
                </p>
              </div>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
        {!isLoading && !isError && (
          <footer className="py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
            <Button.Primary onClick={toggle}>
              Kembali
            </Button.Primary>
            <Button.Secondary
            onClick={() => {modalHapus(adminId)}}>
              Hapus Pengguna
            </Button.Secondary>
          </footer>
        )}
        {isError && (
           error.code === 404 ? (
            <div className="p-24 flex items-center justify-center">
              <p className="flex gap-4 text-gray-300">
                <span>
                  <Icons.Database className="stroke-gray-300" />
                </span>
                <span>Data Tidak Ditemukan</span>
              </p>
            </div>,
            <footer className="py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
            <Button.Primary onClick={toggle}>
              Kembali
            </Button.Primary>
          </footer>
          ) : (
            <ErrorMessage error={error} />
          )
        )
        }
      </article>
    </ModalBackdrop>
  );
}
export default AdminDetailModal;
