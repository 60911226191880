import Hr from "../../../components/Hr";
import TextField from "../../../components/TextField";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";
import { useState, useEffect } from "react";
import _ from "lodash";
import Button from "../../../components/Button";
import { usePopper } from "react-popper";
import Icons from "../../../components/Icons";
import { getCategories } from "../../../services/categories";
import useError from "../../../hooks/useError";

function KontenKeluhanFilterPopup() {
  const [setcategoryIdFilter] = useState();
  const [categoryIdFilterIsEnabled, setcategoryIdFilterIsEnabled] = useState();
  const [categoriesIsLoading, setCategoriesIsLoading] = useState(false);
  const { error, setError, isError } = useError();
  const [categoriesData, setCategoriesData] = useState();
  const [startDateFilterIsEnabled, setStartDateFilterIsEnabled] = useState();
  const [endDateFilterIsEnabled, setEndDateFilterIsEnabled] = useState();
  const [companyIdFilterIsEnabled, setCompanyIdFilterIsEnabled] = useState();
  const [userIdFilterIsEnabled, setUserIdFilterIsEnabled] = useState();
  const [filterFormIsError, setFilterFormIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const [filterPopupIsOpen, setFilterPopupIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });
  const getCategoriesData = async () => {
    try {
      setError({ status: "ok" });
      setCategoriesIsLoading(true);
      setCategoriesData(await getCategories());
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setCategoriesIsLoading(false);
    }
  };
  useEffect(() => {
    if (searchParams.startDate) {
      setStartDateFilterIsEnabled(true);
    }
    if (searchParams.endDate) {
      setEndDateFilterIsEnabled(true);
    }
    if (searchParams.company_id) {
      setCompanyIdFilterIsEnabled(true);
    }
    if (searchParams.user_id) {
      setUserIdFilterIsEnabled(true);
    }
    if (searchParams.content_category_id) {
      setcategoryIdFilterIsEnabled(true);
    }
   
    getCategoriesData();
  }, [searchParams]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let newSearchParams = {};

    if (startDateFilterIsEnabled) {
      newSearchParams.startDate = event.target.startDate.value;
    }
    if (endDateFilterIsEnabled) {
      newSearchParams.endDate = event.target.endDate.value;
    }
    if (companyIdFilterIsEnabled) {
      newSearchParams.company_id = event.target.company_id.value;
    }
    if (userIdFilterIsEnabled) {
      newSearchParams.user_id = event.target.user_id.value;
    }
    if (categoryIdFilterIsEnabled) {
      newSearchParams.content_category_id = event.target.content_category_id.value;
    }
    if (
      startDateFilterIsEnabled &&
      endDateFilterIsEnabled &&
      event.target.startDate.value >= event.target.endDate.value
    ) {
      setErrorMessage("Tanggal mulai harus lebih kecil dari tanggal selesai");
      return setFilterFormIsError(true);
    }

    return setSearchParams(newSearchParams);
  };

  return (
    <>
      <div className="" ref={setReferenceElement}>
        {Object.entries(searchParams).length > 0 ? (
          <Button.Primary
            icon={<Icons.Filter className="w-4 h-4 stroke-white" />}
            label={Object.entries(searchParams).length}
            onClick={() => setFilterPopupIsOpen((x) => !x)}
          >
            Filter
          </Button.Primary>
        ) : (
          <Button.Secondary
            icon={
              <Icons.Filter className="w-4 h-4 stroke-custom-primary-text" />
            }
            onClick={() => setFilterPopupIsOpen((x) => !x)}
          >
            Filter
          </Button.Secondary>
        )}
      </div>
      {filterPopupIsOpen ? (
        <form
          className="w-56 rounded-lg bg-white border shadow p-4 mb-4 z-10 text-sm text-custom-primary-text flex flex-col gap-2"
          style={styles.popper}
          ref={setPopperElement}
          {...attributes.popper}
          onSubmit={(event) => handleSubmit(event)}
        >
          <label className="inline-flex items-center justify-start gap-2">
            <input
              type="checkbox"
              name="companyIdFilterIsEnabled"
              checked={companyIdFilterIsEnabled}
              onClick={() => setCompanyIdFilterIsEnabled((x) => !x)}
            />
            Tujuan
          </label>
          <TextField
            type="text"
            name="company_id"
            labelText="ID Perusahaan"
            defaultValue={searchParams.company_id}
            disabled={!companyIdFilterIsEnabled}
          />

          <Hr />

          <label className="inline-flex items-center justify-start gap-2">
            <input
              type="checkbox"
              name="userIdFilterIsEnabled"
              checked={userIdFilterIsEnabled}
              onClick={() => setUserIdFilterIsEnabled((x) => !x)}
            />
            Pembuat Keluhan
          </label>
          <TextField
            type="text"
            name="user_id"
            labelText="ID user"
            defaultValue={searchParams.user_id}
            disabled={!userIdFilterIsEnabled}
          />

          <Hr />
          <div>
            <p className="font-bold mb-2">Kategori</p>
            <input
              type="checkbox"
              name="categoryIdFilterIsEnabled"
              checked={categoryIdFilterIsEnabled}
              onClick={() => setcategoryIdFilterIsEnabled((x) => !x)}
            />
            <select className="ml-1 w-36"id="content_category_id" defaultValue="Select Category"
                    onChange={(e) => setcategoryIdFilter(e.target.value)}
                    disabled={!categoryIdFilterIsEnabled}
                    >        
                <option>-- Pilih Salah Satu --</option>
                  {categoriesData?.map((category) => (
                    <option value={category._id}>
                      {category.name}
                    </option>
                  ))}
            </select>
          </div>
          <hr/>
          <p className="font-bold">Waktu</p>

          <label className="inline-flex items-center justify-start gap-2">
            <input
              type="checkbox"
              name="startDateFilterIsEnabled"
              checked={startDateFilterIsEnabled}
              onClick={() => setStartDateFilterIsEnabled((x) => !x)}
            />
            Dari
          </label>
          <TextField
            type="date"
            name="startDate"
            labelText="Dari Tanggal"
            defaultValue={searchParams.startDate}
            disabled={!startDateFilterIsEnabled}
          />
          <label className="inline-flex items-center justify-start gap-2">
            <input
              type="checkbox"
              name="endDateFilterIsEnabled"
              checked={endDateFilterIsEnabled}
              onClick={() => setEndDateFilterIsEnabled((x) => !x)}
            />
            Hingga
          </label>
          <TextField
            type="date"
            name="endDate"
            labelText="Hingga Tanggal"
            defaultValue={searchParams.endDate}
            disabled={!endDateFilterIsEnabled}
          />

          <Hr />

          {filterFormIsError ? (
            <p className="mb-2 text-red-600 text-xs">{errorMessage}</p>
          ) : (
            <></>
          )}

          <Button.Primary>Terapkan</Button.Primary>
          <Button.Text type="button" onClick={() => setSearchParams({})}>
            Hapus Filter
          </Button.Text>
        </form>
      ) : (
        <></>
      )}
    </>
  );
}

export default KontenKeluhanFilterPopup;
