import axios from "axios";
import { API_ROUTES } from "../constansts";

/**
 * @typedef {object} CompanyResponse
 * @property {object} data
 * @property {Company[]} data.list
 * @property {number} data.totalData
 * @property {number} data.totalPage
 * @property {object} data.query
 * @property {string} data.query.withPics
 * @property {string} message
 * @property {number} code
 */

/**
 * @typedef {object} Company
 * @property {string} name
 * @property {string} description
 * @property {object} contact
 * @property {string} contact.email
 * @property {string} contact.phoneNumber
 * @property {string} contact.website
 * @property {string} contact._id
 * @property {string} avatar
 * @property {string} createdAt
 * @property {string} updatedAt
 */

/**
 * get companies
 * @async
 * @param {Object} params
 * @param {boolean} [params.withPics=true]
 * @param {string} params.company_id
 * @param {number} [params.page=1]
 * @param {number} [params.size=25]
 * @returns {Promise<CompanyResponse>}
 */

async function getCompany(params) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_COMPANY,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache"
      },
      params,
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

/**
 * create company
 * @async
 * @param {object} params
 * @param {string} params.name
 * @param {string} params.avatar
 * @param {object} params.contact
 * @param {string} params.contact.email
 * @param {string} params.contact.phoneNumber
 * @param {string} params.contact.website
 * 
 * @typedef {object} CreateCompanyResponse
 * @property {boolean} success
 * @property {Company} data
 * @property {string} message
 * @property {number} code
 * 
 * @returns {Promise<CreateCompanyResponse>}
 */

async function createCompany({ name, contact }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.CREATE_COMPANY,
      method: "post",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache"
      },
      data: {
        name,
        contact,
      },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}

/**
 * update company data 
 * @async
 * @param {object} params
 * @param {string} params.name
 * @param {string} params.avatar
 * @param {object} params.contact
 * @param {string} params.contact.email
 * @param {string} params.contact.phoneNumber
 * @param {string} params.contact.website
 * 
 * @typedef {object} UpdateCompanyResponse
 * @property {boolean} success
 * @property {Company} data
 * @property {string} message
 * @property {number} code
 * 
 * @returns {Promise<CreateCompanyResponse>}
 */

async function updateCompany({ company_id, avatar, contact }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.UPDATE_COMPANY,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache"
      },
      params: {
        company_id
      },
      data: {
        avatar,
        contact,
      },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function deleteCompany({company_id, statusReason}) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.DELETE_COMPANY,
      method: "delete",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache"
      },
      params: {
        company_id,
      },
      data: {
        statusReason: statusReason,
      }
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}
async function createSubsCompany({  
  company_id,
  transaction_date,
  account_number,
  sender_name,
  sender_bank,
  subscription_duration,
  subtotal,
  subscription_type,
  payment_proof,
}) 
{
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.SUBS_COMPANY,
      method: "post",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache"
      },
      data: {
        company_id,
        transaction_date,
        account_number,
        sender_name,
        sender_bank,
        subscription_duration,
        subtotal,
        subscription_type,
        payment_proof,

      },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}
async function updatePicCompany({  
  company_id,
  add_user_allowed,
}) 
{
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.UPDATE_SUBS_COMPANY,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache"
      },
      params: {
        company_id,
      },
      data: {
        add_user_allowed,
      },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}
async function updateSubsCompany({ 
  company_id,
  date,
  account_number,
  sender_name,
  sender_bank,
  subscription_duration,
  subtotal,
  subscription_type,
  payment_proof,
 }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.UPDATE_COMPANY,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
        "cache-control" : "ignore-cache"
      },
      params: {
        company_id
      },
      data: {
          date,
          account_number,
          sender_name,
          sender_bank,
          subscription_duration,
          subtotal,
          subscription_type,
          payment_proof,
      },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}
export { getCompany, createCompany,updateSubsCompany, updateCompany, deleteCompany, createSubsCompany,updatePicCompany };
