import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import ErrorMessage from "../../../components/ErrorMessage";
import Icons from "../../../components/Icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useError from "../../../hooks/useError";
import { DeleteReport, getReportedContent } from "../../../services/reportedContent";

function LaporanKeluhanHapusModal({ refreshLaporanData, modalStatusChange, contentReportId, modalHapus }) {
  const { error, isError, setError } = useError();
  const [isLoading, setIsLoading] = useState();
  const [complaintReportData, setComplaintReportData] = useState();
  const toggle = () => {
    modalStatusChange()
    modalHapus()
  }
  const getComplaintReportData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setComplaintReportData(
        await getReportedContent({ content_report_id: contentReportId })
      );
    } catch (error) {
      setError({
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteReport = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      await DeleteReport({ content_report_id: contentReportId });
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
      refreshLaporanData();
      toggle();
    }
  };

  useEffect(() => {
    getComplaintReportData();
  }, []);

  return (
    <ModalBackdrop>
      <article className="w-[540px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Hapus Keluhan
          </h2>
          <Button.Text onClick={toggle} className="ml-auto p-2">
            <span className="sr-only">Tutup</span>
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        {isLoading && (
          <div className="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
        {!isLoading && !isError ? (
          <>
            <div>
              <p className="mb-4 text-sm text-custom-secondary-text">
                Anda akan menghapus keluhan ini.{" "}
                <span className="font-bold text-custom-primary-text">
                  PERHATIAN: Keluhan yang sudah dihapus tidak dapat dikembalikan
                  lagi.
                </span>
              </p>

              <article className="mb-4 p-4 rounded-md bg-custom-background-lighter first:pt-0 flex flex-col gap-4">
                <div className="text-xs">
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">Judul Keluhan</p>
                    <p className="text-custom-secondary-text">
                      {complaintReportData?.list[0].reportedContent
                        .contentComplaint.title ?? "-"}
                    </p>
                  </div>
                </div>
                <div className="text-xs">
                  <div className="flex flex-col gap-1">
                    <p className="text-custom-secondary-text">
                      Pembuat Keluhan
                    </p>
                    <p className="text-custom-primary-text">
                      <UsernameWithAvatar
                        avatarPath={
                          complaintReportData?.list[0].reportedContent?.user
                            .avatar ?? "-"
                        }
                        username={
                          complaintReportData?.list[0].reportedContent.user.name
                          ?? "-"
                        }
                      />
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-2 text-xs">
                  <div className="flex flex-col gap-1 col-span-2">
                    <p className="text-custom-secondary-text">Alasan Pelaporan</p>
                    <p className="text-custom-primary-text line-clamp-1">
                      {complaintReportData?.list[0].reason ?? "-"}
                    </p>
                  </div>
                  <div className="flex flex-col gap-1 col-span-1">
                    <p className="text-custom-secondary-text">Pelapor</p>
                    <UsernameWithAvatar
                        avatarPath={
                          complaintReportData?.list[0].user
                            .avatar ?? "-"
                        }
                        username={
                          complaintReportData?.list[0].user.name
                          ?? "-"
                        }
                      />
                  </div>
                </div>
              </article>
            </div>
            <footer className="grid grid-cols-2 gap-4">
              <Button.Secondary onClick={toggle}>
                Batal
              </Button.Secondary>
              <Button.Primary onClick={handleDeleteReport}>
                Hapus Laporan Keluhan
              </Button.Primary>
            </footer>
          </>
        ) : (
          <></>
        )}
        {isError ? <ErrorMessage error={error} /> : <></>}
      </article>
    </ModalBackdrop>
  );
}
export default LaporanKeluhanHapusModal;
