import axios from "axios";
import { API_ROUTES } from "../constansts";

async function getNewsPopular(params) {
  let result = [];

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_NEWS_POPULAR,
      method: "get",
      auth: {
        username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
        password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
      },
      headers: {
        "Content-Type": "application/json",
      },
      params,
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function getNews(params) {
  let result = [];

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.GET_NEWS,
      method: "get",
      auth: {
        username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
        password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
      },
      headers: {
        "Content-Type": "application/json",
        "cache-control": "ignore-cache",
      },
      params,
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function changeNewsCategory({ content_news_id, content_category_id }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.CHANGE_NEWS_CATEGORY,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      data: { content_category_id },
      params: {
        content_news_id,
      },
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

/**
 * delete News
 * @async
 * @param {*} params
 * @param {string} params.content_news_id
 * @returns
 */
async function deleteNews({ content_news_id, statusReason }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.DELETE_NEWS,
      method: "delete",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      params: {
        content_news_id,
      },
      data: {
        statusReason: statusReason,
      },
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}
async function updateNews({ body, content_news_id }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.UPDATE_NEWS,
      method: "put",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      params: {
        content_news_id,
      },
      data: {
        body,
      },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}
async function createNewsContent({ params }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.CREATE_NEWS,
      method: "post",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      data: {
        ...params,
      },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}
async function createNews(params) {
  let result = {};
  const { body, company_id, content_category_id, tag, title } = params;

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: API_ROUTES.CREATE_NEWS,
      method: "post",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Content-Type": "application/json",
      },
      data: {
        body,
        company: { company_id },
        content_category_id,
        coverImage:
          "https://humi-loadassets-stage.penakita.id/content-news/undefined-61e627e04c/cover.png",
        tag,
        title,
      },
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}
export {
  getNewsPopular,
  getNews,
  changeNewsCategory,
  deleteNews,
  createNews,
  updateNews,
  createNewsContent,
};
