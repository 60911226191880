import _ from "lodash";
import { useState, useEffect } from "react";
import Button from "../../../components/Button";
import ButtonGroup from "../../../components/ButtonGroup";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";
import Icons from "../../../components/Icons";
import TextField from "../../../components/TextField";
import KontenKeluhanFilterPopup from "./KontenKeluhanFilterPopup";

function KontenKeluhanToolbar(props) {
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  return (
    <div className="py-2 flex items-center gap-4">
      <ButtonGroup className="mr-auto">
        <ButtonGroup.Item
          onClick={() => setSearchParams({ ...searchParams, page:1, size: 10 })}
          isActive={
            // Defaults to 10
            searchParams.size ? Number.parseInt(searchParams.size) === 10 : true
          }
        >
          View 10
        </ButtonGroup.Item>
        <ButtonGroup.Item
          onClick={() => setSearchParams({ ...searchParams, page:1, size: 20 })}
          isActive={Number.parseInt(searchParams.size) === 20}
        >
          View 20
        </ButtonGroup.Item>
        <ButtonGroup.Item

          onClick={() => setSearchParams({ ...searchParams, page:1, size: 30 })}
          isActive={Number.parseInt(searchParams.size) === 30}
        >
          View 30
        </ButtonGroup.Item>
      </ButtonGroup>
      <KontenKeluhanFilterPopup />
      <TextField
        placeholder="Cari berdasarkan judul keluhan"
        onKeyPress={(event) => {
          if (event.key == "Enter") {
            setSearchParams(
              event.target.value !== ""
                ? { ...searchParams, title: event.target.value }
                : { ..._.omit(searchParams, ["title"]) }
            );
          }
        }}
        defaultValue={searchParams.reason}
        icon={<Icons.Search className="w-4 h-4 stroke-custom-primary-text" />}
      />
    </div>
  );
}
export default KontenKeluhanToolbar;
