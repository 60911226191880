import { Link } from "react-router-dom";
import moment from "moment";
import Table, { Row } from "../../../components/Table";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";

function ModerasiKontenKeluhanTable({ keluhanData }) {
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  return (
    <Table
      totalPages={keluhanData?.totalPage}
      currentPage={searchParams.page ?? 1}
      headingRow={[
        {
          name: "Pembuat Keluhan",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "pembuat-keluhan",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "pembuat-keluhan"
              ? searchParams.order
              : "unsorted",
        },
        {
          name: "Judul Keluhan",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "judul-keluhan",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "judul-keluhan"
              ? searchParams.order
              : "unsorted",
        },
        {
          name: "Tujuan",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "tujuan",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "tujuan" ? searchParams.order : "unsorted",
        },
        {
          name: "Waktu",
          handleSort: () =>
            setSearchParams({
              ...searchParams,
              sortBy: "waktu",
              order: searchParams.order === "desc" ? "asc" : "desc",
            }),
          currentSortMethod:
            searchParams.sortBy === "waktu" ? searchParams.order : "unsorted",
        },
        {
          name: "Aksi",
          unsortable: true,
        },
      ]}
    >
      {keluhanData?.list?.map((keluhan, i) => (
        <Row
          key={i}
          rowCells={[
            <UsernameWithAvatar
              avatarPath={keluhan.user.avatar}
              username={keluhan.user.name}
            />,
            keluhan.title,
            <UsernameWithAvatar username={keluhan.company.name} />,
            moment(keluhan.createdAt).format("DD-MM-YYYY, HH:MM"),
            <>
              <div className="divide-x-2 divide-gray-400 font-semibold text-custom-primary">
                <Link to={keluhan._id} className="pr-2 hover:underline">
                  Moderasi
                </Link>
              </div>
            </>,
          ]}
        />
      ))}{" "}
    </Table>
  );
}
export default ModerasiKontenKeluhanTable;
