import { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import Button from "../../../components/Button";
import Hr from "../../../components/Hr";
import Icons from "../../../components/Icons";
import TextField from "../../../components/TextField";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";

function VerifikasiDataDiriPenggunaIndividuFilterPopup() {
  const [startDateFilterIsEnabled, setStartDateFilterIsEnabled] = useState();
  const [endDateFilterIsEnabled, setEndDateFilterIsEnabled] = useState();
  const [nameFilterIsEnabled, setNameFilterIsEnabled] = useState();
  const [filterFormIsError, setFilterFormIsError] = useState(false);
  const [filterFormErrorMessage, setFilterFormErrorMessage] = useState("");
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const [filterPopupIsOpen, setFilterPopupIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  useEffect(() => {
    if (searchParams.startDate) {
      setStartDateFilterIsEnabled(true);
    }
    if (searchParams.endDate) {
      setEndDateFilterIsEnabled(true);
    }
    if (searchParams.name) {
      setNameFilterIsEnabled(true);
    }
  }, [searchParams.startDate, searchParams.endDate]);

  return (
    <>
      <div className="" ref={setReferenceElement}>
        {Object.entries(searchParams).length > 0 ? (
          <Button.Primary
            icon={<Icons.Filter className="w-4 h-4 stroke-white" />}
            label={Object.entries(searchParams).length}
            onClick={() => setFilterPopupIsOpen((x) => !x)}
          >
            Filter
          </Button.Primary>
        ) : (
          <Button.Secondary
            icon={
              <Icons.Filter className="w-4 h-4 stroke-custom-primary-text" />
            }
            onClick={() => setFilterPopupIsOpen((x) => !x)}
          >
            Filter
          </Button.Secondary>
        )}
      </div>
      {filterPopupIsOpen ? (
        <form
          data-testid="filter-popup"
          className="w-56 rounded-lg bg-white border shadow p-4 mb-4 z-10 text-sm text-custom-primary-text flex flex-col gap-2"
          style={styles.popper}
          ref={setPopperElement}
          {...attributes.popper}
          onSubmit={(event) => {
            event.preventDefault();

            let newSearchParams = {};

            if (startDateFilterIsEnabled) {
              newSearchParams.startDate = event.target.startDate.value;
            }
            if (endDateFilterIsEnabled) {
              newSearchParams.endDate = event.target.endDate.value;
            }
            if (nameFilterIsEnabled) {
              newSearchParams.name = event.target.name.value;
            }

            if (
              startDateFilterIsEnabled &&
              endDateFilterIsEnabled &&
              event.target.startDate.value >= event.target.endDate.value
            ) {
              setFilterFormErrorMessage(
                "Tanggal mulai harus lebih kecil dari tanggal selesai"
              );
              return setFilterFormIsError(true);
            }

            return setSearchParams(newSearchParams);
          }}
        >
          {/* Field Nama */}
          <label className="inline-flex items-center justify-start gap-2">
            <input
              type="checkbox"
              name="nameFilterIsEnabled"
              checked={nameFilterIsEnabled}
              onClick={() => setNameFilterIsEnabled((x) => !x)}
            />
            Nama
          </label>
          <TextField
            type="text"
            name="name"
            labelText="Filter Nama"
            defaultValue={searchParams.name}
            disabled={!nameFilterIsEnabled}
          />

          <Hr />

          <p className="font-bold">Waktu Pembuatan</p>

          <label className="inline-flex items-center justify-start gap-2">
            <input
              type="checkbox"
              name="startDateFilterIsEnabled"
              checked={startDateFilterIsEnabled}
              onClick={() => setStartDateFilterIsEnabled((x) => !x)}
            />
            Dari
          </label>
          <TextField
            type="date"
            name="startDate"
            labelText="Filter Tanggal Mulai"
            defaultValue={searchParams.startDate}
            disabled={!startDateFilterIsEnabled}
          />
          <label className="inline-flex items-center justify-start gap-2">
            <input
              type="checkbox"
              name="endDateFilterIsEnabled"
              checked={endDateFilterIsEnabled}
              onClick={() => setEndDateFilterIsEnabled((x) => !x)}
            />
            Hingga
          </label>
          <TextField
            type="date"
            name="endDate"
            labelText="Filter Tanggal Akhir"
            defaultValue={searchParams.endDate}
            disabled={!endDateFilterIsEnabled}
          />

          <Hr />

          {filterFormIsError ? (
            <p className="mb-2 text-red-600 text-xs">
              {filterFormErrorMessage}
            </p>
          ) : (
            <></>
          )}

          <Button.Primary>Terapkan Filter</Button.Primary>
          <Button.Text type="button" onClick={() => setSearchParams({})}>
            Hapus Filter
          </Button.Text>
        </form>
      ) : (
        <></>
      )}
    </>
  );
}
export default VerifikasiDataDiriPenggunaIndividuFilterPopup;
