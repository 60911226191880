import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { loginAdmin } from "../../services/admin";
import { AuthContext } from "../../contexts/AuthContext";
import useReactiveSearchParams from "../../hooks/useReactiveSearchParams";
import Helmet from "react-helmet";

function Login() {
  const [searchParams] = useReactiveSearchParams();
  const [loginIsLoading, setLoginIsLoading] = useState(false);
  const { isAuth, setIsAuth } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: async (values) => {
      try {
        setLoginIsLoading(true);
        await loginAdmin({ ...values });
        setIsAuth(true); 
      const redirectPath = searchParams.redirect || "/";
        window.location.replace(redirectPath);
      } catch (error) {
        console.error("Login failed:", error);
        alert(error.response?.data?.message || "Login failed");
      } finally {
        setLoginIsLoading(false);
      }
    },
  });

  useEffect(() => {
    if (isAuth) {
      const redirectPath = searchParams.redirect || "/";
      window.location.replace(redirectPath); 
    }
  }, [isAuth, searchParams]);

  return (
    <div className="w-screen h-screen bg-custom-background flex flex-col items-center justify-center">
      <Helmet>
        <title>Login | Backoffice Intra Q Media</title>
      </Helmet>
      <div className="w-72 p-4 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 lg:p-8">
        <form className="space-y-6" onSubmit={formik.handleSubmit}>
          <img src={"/intra-q-media-logo.png"} alt="Intra Q Media" style={{
            width: "auto",
            height: "100px",
          }} className="mx-auto"/>
          <div>
            <label
              htmlFor="username"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Username
            </label>
            <input
              type="text"
              name="username"
              id="username"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Masukkan username"
              onChange={formik.handleChange}
              value={formik.values.username}
              required
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Masukkan password"
              onChange={formik.handleChange}
              value={formik.values.password}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full text-white bg-custom-primary hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            disabled={loginIsLoading}
          >
            {loginIsLoading ? "Logging in..." : "Login"}
          </button>
        </form>
      </div>
      <div className="flex mt-20">
        <div className="w-72 p-4 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 lg:p-8 flex flex-col items-center justify-center">
          <img src={"/accurate-logo.svg"} alt="Accurate" style={{
            width: "auto",
            height: "100px",
          }} className="mx-auto"/>
          <p className="text-custom-primary font-bold">
            Our Financial System
          </p>
        </div>
        <div className="w-72 p-4 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 lg:p-8 flex flex-col items-center justify-center">
          <img src={"/talenta-logo.svg"} alt="Talenta" style={{
            width: "auto",
            height: "100px",
          }} className="mx-auto"/>
          <p className="text-custom-primary font-bold">
            Our HR System
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
