import { ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import GridLayout from "../../components/GridLayout";
import Icons from "../../components/Icons";
import LoadingSpinner from "../../components/LoadingSpinner";
import Main from "../../components/Main";
import PageHeader from "../../components/PageHeader";
import PageTitle from "../../components/PageTitle";
import Sidebar from "../../components/Sidebar";
import useError from "../../hooks/useError";
import useReactiveSearchParams from "../../hooks/useReactiveSearchParams";
import { getCompany } from "../../services/companies";
import PerusahaanBaruModal from "./components/PerusahaanBaruModal";
import PerusahaanDetailModal from "./components/PerusahaanDetailModal";
import PerusahaanTable from "./components/PerusahaanTable";
import PerusahaanToolbar from "./components/PerusahaanToolbar";
import Helmet from "react-helmet";
import PerusahaanHapusModal from "./components/PerusahaanHapusModal";
import PerusahaanSubscriptonBaruModal from "./components/PerusahaanSubscriptonBaruModal";
import PerusahaanTambahPicModal from "./components/PerusahaanTambahPicModal";

function Perusahaan() {
  const [isLoading, setIsLoading] = useState();
  const [companyData, setCompanyData] = useState();
  const { error, setError, isError } = useError();
  const [searchParams] = useReactiveSearchParams();
  const [isDetailOpened, setIsDetailOpened] = useState();
  const [isBaruOpened, setIsBaruOpened] = useState();
  const [isPicBaruOpened, setIsPicBaruOpened] = useState();
  const [isHapusOpened, setIsHapusOpened] = useState();
  const [isSubsOpened, setIsSubsOpened] = useState();
  const [perusahaanId, setperusahaanId] = useState();

  const toggle = (company) => {
    setIsDetailOpened((wasOpened) => !wasOpened);
    setperusahaanId(company);
  };
  const togglehapus = (company) => {
    setIsDetailOpened(false);
    setIsHapusOpened((wasOpened) => !wasOpened);
    setperusahaanId(company);
  };

  const togglesubs = (company) => {
    setIsDetailOpened(false);
    setIsSubsOpened((wasOpened) => !wasOpened);
    setperusahaanId(company);
  };
  const togglenewpic = (company) => {
    setIsDetailOpened(false);
    setIsPicBaruOpened((wasOpened) => !wasOpened);
    setperusahaanId(company);
  };
  const togglebaru = () => {
    setIsBaruOpened((wasOpened) => !wasOpened);
  };
  const getCompanyData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setCompanyData(
        await getCompany({
          withSubs: true,
          withPics: true,
          page: 1,
          size: 10,
          ...searchParams,
        })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCompanyData();
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Perusahaan | Backoffice Intra Q Media</title>
      </Helmet>
      <GridLayout>
        <Sidebar />
        <Main>
          <PageHeader />
          <PageTitle
            icon={
              <Icons.Company className="w-8 h-8 stroke-custom-secondary-text" />
            }
            title="Perusahaan"
          />
          <PerusahaanToolbar modalStatusChange={togglebaru} />
          {isLoading && (
            <div className="h-24 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
          {isError ? (
            error.code === 404 ? (
              <div className="p-24 flex items-center justify-center">
                <p className="flex gap-4 text-gray-300">
                  <span>
                    <Icons.Database className="stroke-gray-300" />
                  </span>
                  <span>Data Tidak Ditemukan</span>
                </p>
              </div>
            ) : (
              <ErrorMessage error={error} />
            )
          ) : (
            <></>
          )}
          {!isLoading && !isError && (
            <PerusahaanTable
              companyData={companyData}
              modalStatusChange={toggle}
            />
          )}
        </Main>
        {!isLoading && isDetailOpened && (
          <PerusahaanDetailModal
            refreshPerusahaanData={getCompanyData}
            modalStatusChange={toggle}
            modalHapus={togglehapus}
            modalsubscription={togglesubs}
            perusahaanId={perusahaanId}
            modalnewpic={togglenewpic}
          />
        )}
        {!isLoading && isHapusOpened && (
          <PerusahaanHapusModal
            refreshPerusahaanData={getCompanyData}
            modalStatusChange={toggle}
            perusahaanId={perusahaanId}
            modalHapus={togglehapus}
          />
        )}
        {!isLoading && isBaruOpened && (
          <PerusahaanBaruModal
            refreshPerusahaanData={getCompanyData}
            modalStatusChange={togglebaru}
          />
        )}
        {!isLoading && isSubsOpened && (
          <PerusahaanSubscriptonBaruModal
            refreshPerusahaanData={getCompanyData}
            modalStatusChange={toggle}
            perusahaanId={perusahaanId}
            modalsubscription={togglesubs}
          />
        )}
        {!isLoading && isPicBaruOpened && (
          <PerusahaanTambahPicModal
            refreshPerusahaanData={getCompanyData}
            modalStatusChange={toggle}
            perusahaanId={perusahaanId}
            modalnewpic={togglenewpic}
          />
        )}
      </GridLayout>
    </>
  );
}
export default Perusahaan;
