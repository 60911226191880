import { useEffect, useState, createContext } from "react";
import useError from "../hooks/useError";
import { getMyProfile } from "../services/usersDataProfile";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [isAuth, setIsAuth] = useState(false);
  const {
    error: authError,
    isError: authIsError,
    setError: setAuthError,
  } = useError();
  const [authIsLoading, setAuthIsLoading] = useState(true);

  const getMyProfileData = async () => {
    try {
      setAuthIsLoading(true);

      await getMyProfile(); // Checks if token is still valid, if an error happen while fetching, it will throw an error

      const authData = window.localStorage.getItem("authData");
      if (authData) setIsAuth(true);
    } catch (error) {
      setIsAuth(false);
      window.localStorage.removeItem("authData");
      window.localStorage.removeItem("token");
      setAuthError({
        code: error.response?.status || 500,
        message: error.response?.data?.message || "An error occurred",
        status: "error",
      });
    } finally {
      setAuthIsLoading(false);
    }
  };

  const logout = () => {
    window.localStorage.removeItem("authData");
    window.localStorage.removeItem("token");
    setIsAuth(false);
  };

  useEffect(() => {
    getMyProfileData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        setIsAuth,
        authIsLoading,
        logout,
        authError,
        authIsError,
        setAuthError,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
