import { useEffect } from "react";
import Portal from "./Portal";

function ModalBackdrop({ children }) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  return (
      <div className="w-full h-screen py-16 fixed top-0 right-0 bg-black bg-opacity-20 flex flex-col items-center justify-start overflow-y-auto">
        {children}
      </div>
  );
}
export default ModalBackdrop;
