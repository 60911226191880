import moment from "moment";
import { Link } from "react-router-dom";
import Table, { Row } from "../../../components/Table";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import { status } from "../../../constansts";
import useReactiveSearchParams from "../../../hooks/useReactiveSearchParams";

function LaporanKomentarTable({ commentReportsData , modalStatusChange}) {
  const [searchParams] = useReactiveSearchParams();

  return (
    <Table
      totalPages={commentReportsData?.totalPage}
      currentPage={searchParams.page ?? 1}
      headingRow={[
        {
          name: "Pembuat Laporan",
          unsortable: true,
        },
        {
          name: "Isi Komentar",
          unsortable: true,
        },
        {
          name: "Alasan",
          unsortable: true,
        },{
          name: "Status",
          unsortable: true,
        },
        {
          name: "Waktu",
          unsortable: true,
        },
        {
          name: "Aksi",
          unsortable: true,
        },
      ]}
    >
      {commentReportsData?.list?.map((commentReport, i) => {
        return (
          <Row
            key={i}
            rowCells={[
              <UsernameWithAvatar
                username={commentReport.user.name}
                avatarPath={commentReport.user.avatar} />,
              commentReport?.reportedContent?.contentComment.message,
              commentReport?.reason,
              <div className="inline-flex items-center gap-4">
                  {commentReport?.status === status.VERIFIED && (
                    <>
                      <span className="w-3 h-3 rounded-full bg-custom-green flex-shrink-0"></span>
                      <span className="text-sm text-custom-primary-text">
                        Disetujui
                      </span>
                    </>
                  ) }
                   {commentReport?.status === status.UNVERIFIED && (
                    <>
                      <span className="w-3 h-3 rounded-full bg-yellow-400 flex-shrink-0"></span>
                      <span className="text-sm text-custom-primary-text">
                        Moderasi
                      </span>
                    </>
                  )}{commentReport?.status === status.REJECTED && (
                    <>
                      <span className="w-3 h-3 rounded-full bg-custom-red flex-shrink-0"></span>
                      <span className="text-sm text-custom-primary-text">
                        Ditolak
                      </span>
                    </>
                  )}
                </div>,
              moment(commentReport?.createdAt).format("MM-DD-YYYY, HH:mm"),
              <>
                <div className="divide-x-2 divide-gray-400 font-semibold text-custom-primary">
                <div className="px-2 first:pl-0 cursor-pointer hover:underline" onClick={() => modalStatusChange(commentReport._id)}>
                      Lihat Detail
                  </div>
                </div>
              </>,
            ]} />
        );
      })}
    </Table>
  );
}
export default LaporanKomentarTable;
