import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import Icons from "../../../components/Icons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ModalBackdrop from "../../../components/ModalBackdrop";
import TextField from "../../../components/TextField";
import useError from "../../../hooks/useError";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { createCompany, createSubsCompany, getCompany, updateCompany, updatePicCompany, updateSubsCompany } from "../../../services/companies";
import { getFileUrl, uploadSubscriptionProof } from "../../../services/files";
import { subscriptions_duration, subscriptions_type, subscription_type } from "../../../constansts";

function PerusahaanTambahPicModal({ modalStatusChange, modalsubscription, refreshPerusahaanData, perusahaanId,modalnewpic }) {
  const [isLoading, setIsLoading] = useState();
  const { error, setError, isError } = useError();
  const [files, setFiles] = useState([]);
  const [fileurl, setFileUrl] = useState();
  const [companyData, setCompanyData] = useState();

  const toggle = () => {
    modalStatusChange()
    modalnewpic()
  }
  const getCompanyData = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setCompanyData(
        (await getCompany({ withSubs: true, withPics: true, company_id: perusahaanId }))
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);

    }
  };
 
  useEffect(() => {
    getCompanyData();
  }, []);
  const formik = useFormik({
    initialValues: {
      company_id: "",
      add_user_allowed:"",
    //   subscription_type: "",
    //   sender_bank: "",
    //   subscription_duration: "",
    },

    onSubmit: async (data) => {
        try {
            setError({ status: "ok" });
            setIsLoading(true);
            const updatePic = (
              await updatePicCompany({
                company_id: companyData?.list[0]._id,
                add_user_allowed: data.add_user_allowed,

              })
            ).data;
            toggle();
            refreshPerusahaanData();
          } catch (error) {
            console.error(error);
            if (error.message === "Foto profil perusahaan dibutuhkan") {
              setError({
                message: error.message,
                status: "error",
              });
            } else {
              setError({
                code: error.response.status,
                message: error.response.data.message,
                status: "error",
              });
            }
          } finally {
            setIsLoading(false);
          }
        },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <ModalBackdrop>
      <article className="w-[400px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Tambah PIC 
          </h2>
          <Button.Text onClick={() => toggle()} className="ml-auto p-2">
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        <div className="flex flex-col gap-6">
          <h3 className="text-lg text-custom-primary-text font-bold flex items-center">
            Informasi Perusahaan
            <span className="ml-3 px-2 py-1 rounded-md bg-custom-background-lighter text-xs font-normal">
              <span className="text-red-500">*</span> Wajib diisi
            </span>
          </h3>
          <div className="grid grid-cols text-xs">
            <div className="flex flex-col gap-1">
              <TextField
                type="text"
                name="company_id"
                id="company_id"
                labelText="ID Perusahaan"
                defaultValue={companyData?.list[0]._id}
                disabled
                required
              />
            </div>
            </div> 
            {/* <div className="grid grid-cols text-xs">
            <div className="flex flex-col gap-1">
              <TextField
                type="text"
                name="subscription_type"
                id="subscription_type"
                labelText="subscription type"
                defaultValue={companyData?.list[0].subscription[0]?.subscription_type}
                disabled
                required
              />
            </div>
            </div>  */}
            {/* <div className="grid grid-cols text-xs">
            <div className="flex flex-col gap-1">
              <TextField
                type="text"
                name="subscription_duration"
                id="subscription_duration"
                labelText="subscription duration"
                defaultValue={companyData?.list[0].subscription[0]?.duration}
                disabled
                required
              />
            </div>
            </div>  */}
            <div className="grid grid-cols text-xs">
            <div className="flex flex-col gap-1">
              <TextField
                labelText="Jumlah PIC Baru"
                name="add_user_allowed"
                id="add_user_allowed"
                placeholder="Masukkan Jumlah..."
                onChange={formik.handleChange}
                defaultValue={formik.values.add_user_allowed}
                required
              />
            </div>
          </div>
        
          <ul className="flex flex-col gap-1.5">
            {Object.entries(formik.errors)?.map((error, i) => (
              <li className="text-red-700 text-xs" key={i}>
                {error[1]}
              </li>
            ))}
            {isError && (
              <li className="text-red-700 text-xs">
                {error?.message ?? "Terjadi kesalahan"}
              </li>
            )}
          </ul>
          <p className="text-custom-secondary-text text-xs">
            Informasi di atas sewaktu-waktu dapat diubah oleh PIC perusahaan.
            Pengisian formulir ini hanya sebagai data awal saja.
          </p>
        </div>
        <footer className="mt-6 py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
          {!isLoading ? (
            <>
              <Button.Secondary onClick={() => toggle()}>
                Batal
              </Button.Secondary>
              <Button.Primary type="submit" onClick={formik.handleSubmit}>
                Tambah Pic
              </Button.Primary>
            </>
          ) : (
            <div className="w-full flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
        </footer>
      </article>
    </ModalBackdrop>
  );
}
export default PerusahaanTambahPicModal;
