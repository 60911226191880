import Box from "../../../components/Box";
import Icons from "../../../components/Icons";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import TextField from "../../../components/TextField";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import { useNavigate } from "react-router-dom";

function ModerasiKontenKeluhanDetailDialog({ keluhanData }) {
  const navigate = useNavigate();

  return (
    <Box className="self-start sticky top-4">
      <h2 className="mb-2 text-custom-primary font-bold">Moderasi Keluhan</h2>
      <div className="mb-2 flex flex-col gap-2">
        <div>
          <p className="mb-1.5 text-xs text-custom-secondary-text">
            Keluhan dari
          </p>
          <UsernameWithAvatar
            avatarPath={keluhanData?.list[0]?.user.avatar}
            username={keluhanData?.list[0]?.user.name}
            className="text-sm"
          />
          <p className="mb-1.5 text-xs text-custom-secondary-text">
            Keluhan untuk
          </p>
          <UsernameWithAvatar
            avatarPlaceholder={
              <div className="w-6 h-6 bg-gray-100 flex-shrink-0 rounded-full object-cover inline-flex items-center justify-center">
                <Icons.Company className="w-3 h-3 stroke-custom-primary-text" />
              </div>
            }
            username={keluhanData?.list[0]?.company.name}
            className="text-sm"
          />
        </div>
        <div>
          {false && (
            <>
              <span className="inline-flex items-center gap-2 text-sm text-custom-primary-text">
                Dutch East India Company
              </span>
              <p className="mt-2 text-sm text-custom-primary-text">
                Perusahaan yang dimasukkan pengguna tidak terdaftar di database.
                Silahkan sambungkan ke perusahaan yang ada di database, atau
                buka halaman{" "}
                <Link
                  to="/perusahaan"
                  className="text-custom-primary font-bold hover:underline"
                >
                  Perusahaan
                </Link>{" "}
                untuk membuat perusahaan baru.
              </p>
              <p className="mt-2 mb-1 text-xs text-custom-primary-text">
                Cari Perusahaan di Database
              </p>
              <TextField
                className="w-full"
                placeholder="Cari Perusahaan"
                icon={
                  <Icons.Search className="w-4 h-4 flex-shrink-0 stroke-custom-primary-text" />
                }
              />
            </>
          )}
        </div>
      </div>
      <div>
        <p className="mb-2 text-custom-primary-text text-sm">
          Apakah keluhan ini layak untuk dipublikasikan?
        </p>
        <div className="flex flex-col items-stretch gap-2">
          <Button.Primary onClick={() => navigate("publikasi")}>
            Ya
          </Button.Primary>
          {false &&
            [
              "Foto cover belum di-upload.",
              "Perusahaan belum disambungkan ke database.",
            ].map((error) => (
              <>
                <p className="text-sm text-red-500">{error}</p>
              </>
            ))}
          <Button.Secondary onClick={() => navigate("tolak")}>
            Tidak
          </Button.Secondary>
        </div>
      </div>
    </Box>
  );
}
export default ModerasiKontenKeluhanDetailDialog;
