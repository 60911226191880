import { useEffect, useState } from "react";
import useError from "../../../hooks/useError";
import ModalBackdrop from "../../../components/ModalBackdrop";
import Icons from "../../../components/Icons";
import UsernameWithAvatar from "../../../components/UsernameWithAvatar";
import moment from "moment";
import LoadingSpinner from "../../../components/LoadingSpinner";
import Button from "../../../components/Button";
import { getComplaint } from "../../../services/complaintContent";

function KontenKeluhanDetailModal({modalStatusChange, contentComplaintId, modalHapus}) {
  const [isLoading, setIsLoading] = useState();
  const { error, isError, setError } = useError();
  const [contentComplaintData, setContentComplaintData] = useState();
  const [isOpened] = useState();

  const toggle =() =>{
    modalStatusChange(!isOpened)
  }

  const getContentComplaintData = async () => {
    try {
      setIsLoading(true);
      setContentComplaintData(
        await getComplaint({ content_complaint_id: contentComplaintId })
      );
    } catch (error) {
      setError({
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getContentComplaintData();
  }, []);

  return (
    <ModalBackdrop>
      <article className="w-[865px] p-8 rounded-md bg-white flex flex-col gap-4">
        <header className="flex items-center gap-4">
          <h2 className="text-2xl text-custom-primary font-bold">
            Detail Konten Keluhan
          </h2>
          {isLoading ? (
            <></>
          ) : (
            <a
              href={`https://dev.intramedia.site/keluhan/${contentComplaintData?.list[0].slug ?? ""}`}
              target="_blank"
              className="p-2 rounded-md hover:bg-gray-100 inline-flex"
            >
              Buka di Intra Q Media
              <Icons.NewTab className="w-6 h-6 stroke-custom-primary" />
            </a>
          )}
          <Button.Text onClick={toggle} className="ml-auto p-2">
            <span className="sr-only">Tutup</span>
            <Icons.X className="w-6 h-6 stroke-custom-primary" />
          </Button.Text>
        </header>
        {!isLoading && !isError && (
          <>
            {/* Row 1 */}
            <div className="grid grid-cols-4 gap-2 text-xs">
              <div className="flex flex-col gap-1 col-span-2">
                <p className="text-custom-secondary-text">Judul Keluhan</p>
                <p className="text-custom-primary-text line-clamp-1">
                  {contentComplaintData?.list[0].title ?? "-"}
                </p>
              </div>
              <div className="flex flex-col gap-1 col-span-1">
                <p className="text-custom-secondary-text">ID</p>
                <p className="text-custom-primary-text">
                  {contentComplaintData?.list[0]._id ?? "-"}
                </p>
              </div>
              <div className="flex flex-col gap-1 col-span-1">
                <p className="text-custom-secondary-text">Kategori</p>
                <p className="text-custom-primary-text">
                  {contentComplaintData?.list[0].contentCategory.name ?? "-"}
                </p>
              </div>
            </div>

            {/* Row 2 */}
            <div className="grid grid-cols-4 gap-2 text-xs">
              <div className="flex flex-col gap-1 col-span-2 ">
                <p className="text-custom-secondary-text">Pembuat Keluhan</p>
                <p className="text-custom-primary-text">
                  <UsernameWithAvatar
                    avatarPath={
                      contentComplaintData?.list[0].user.avatar
                    }
                    username={
                      contentComplaintData?.list[0].user.name ??
                      "-"
                    }
                  />
                </p>
              </div>
              <div className="flex flex-col gap-1 col-span-1">
                <p className="text-custom-secondary-text">Waktu Pembuatan</p>
                <p className="text-custom-primary-text">
                  {moment(contentComplaintData?.list[0].createdAt).format(
                    "DD-MM-YYYY, HH:MM"
                  )}
                </p>
              </div>
              <div className="flex flex-col gap-1 col-span-1">
                <p className="text-custom-secondary-text">Perusahaan Tertuju</p>
                <p className="text-custom-primary-text">
                <UsernameWithAvatar
                    username={
                      contentComplaintData?.list[0].company.name ??
                      "-"
                    }
                    />              
                </p>
              </div>
            </div>
          </>
        )}
        {isLoading && (
          <div className="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        )}
        {!isLoading && !isError && (
          <footer className="mt-6 py-4 border-t bg-white sticky -bottom-16 flex flex-row-reverse gap-4">
            <Button.Primary
              onClick={() => {modalHapus(contentComplaintData?.list[0]._id)}}
            >
              Hapus Keluhan
            </Button.Primary>
            <Button.Secondary onClick={toggle}>
              Kembali
            </Button.Secondary>
          </footer>
        )}
      </article>
    </ModalBackdrop>
  );
}
export default KontenKeluhanDetailModal;
